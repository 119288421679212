import { useCallback, useId, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { omit } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Spin } from '@pledge-earth/web-components';
import {
  BannerMessage,
  Text,
  Dialog,
  DialogHeader,
  DialogTitle,
  OverlayCloseButton,
  DialogBody,
  DialogFooter,
  Button,
} from '@pledge-earth/product-language';

import type { PortfolioDraftInput } from '../../services/graphql/generated';
import {
  CurrencyEnum,
  useCreatePortfolioMutation,
  useGetClientsIdNameQuery,
  useUpsertPortfolioDraftMutation,
} from '../../services/graphql/generated';
import { EntityAdded } from '../../components/AddEntity/EntityAdded';
import { showErrorToast, showSuccessToast } from '../../utils/toast';

import './PortfolioEdit.scss';
import { PortfolioEditAllocations } from './portfolioEdit/PortfolioEditAllocations';
import { PortfolioEditSettings } from './portfolioEdit/PortfolioEditSettings';
import { PortfolioEditDetails } from './portfolioEdit/PortfolioEditDetails';
import { PortfolioEditSummary } from './portfolioEdit/PortfolioEditSummary';

// This should conform to the order of pages you want to show
// eslint-disable-next-line @typescript-eslint/naming-convention -- eslint onboarding
enum PORTFOLIO_FORM_STATES {
  ALLOCATIONS = 'ALLOCATIONS',
  ENTITLEMENTS_AND_ACCOUNTING = 'ENTITLEMENTS_AND_ACCOUNTING',
  DETAILS = 'DETAILS',
  SUMMARY = 'SUMMARY',
}

const emptyPortfolioState = {
  portfolio_draft: {
    details: {
      name: null,
      portfolio_version: null,
      description: null,
      accounting_code: null,
      currency: CurrencyEnum.Gbp,
      key_facts: ['', '', '', ''],
    },
    allocations: [],
    acl: null,
  },
};

export function PortfolioEdit({
  closeModal,
  onSaved,
  portfolio = emptyPortfolioState as PortfolioDraftInput,
}: {
  closeModal: () => void;
  portfolio?: PortfolioDraftInput;
  onSaved?: () => void;
}) {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [showSaveDraftSuccess, setShowSaveDraftSuccess] = useState(false);
  const [showSaveCreateSuccess, setShowSaveCreateSuccess] = useState(false);
  const [formProgressionState, setFormProgressionState] =
    useState<PORTFOLIO_FORM_STATES>(PORTFOLIO_FORM_STATES.ALLOCATIONS);
  const [portfolioState, setPortfolioState] =
    useState<PortfolioDraftInput>(portfolio);
  const formId = useId();

  const [
    upsertPortfolioMutation,
    { loading: draftLoading, error: draftError },
  ] = useUpsertPortfolioDraftMutation();

  const [
    createPortfolioMutation,
    { loading: createLoading, error: createError },
  ] = useCreatePortfolioMutation();

  const { data: dataClients, loading: loadingClients } =
    useGetClientsIdNameQuery();

  const updateState = useCallback(
    (newPortfolioState: PortfolioDraftInput) => {
      setPortfolioState(newPortfolioState);
    },
    [setPortfolioState],
  );

  const sanitizedPortfolioState = useMemo(() => {
    const { id } = portfolioState;
    const { acl, details, allocations } = portfolioState.portfolio_draft;
    const sanitizedAllocations = allocations?.map((allocation) => {
      const sanitizedAllocation = omit(allocation, '__typename');
      return sanitizedAllocation;
    });
    const sanitizedDetails = omit(details, '__typename');
    const sanitizedPortfolio = {
      id,
      portfolio_draft: {
        details: sanitizedDetails,
        allocations: sanitizedAllocations,
        acl,
      },
    };

    return sanitizedPortfolio;
  }, [portfolioState]);

  const saveAsDraft = useCallback(async () => {
    if (!portfolioState) {
      showErrorToast({
        description: 'Portfolio draft could not be saved',
      });
      return;
    }

    await upsertPortfolioMutation({
      variables: {
        data: sanitizedPortfolioState,
      },
    });

    if (onSaved) {
      showSuccessToast({
        description: 'Portfolio draft has been saved',
      });
      onSaved();
    } else {
      setShowSaveDraftSuccess(true);
    }
  }, [
    onSaved,
    portfolioState,
    upsertPortfolioMutation,
    sanitizedPortfolioState,
  ]);

  const createPortfolio = useCallback(async () => {
    if (!portfolioState) {
      showErrorToast({
        description: 'Portfolio draft could not be saved',
      });
      return;
    }

    await createPortfolioMutation({
      variables: {
        data: sanitizedPortfolioState,
      },
    });

    if (onSaved) {
      showSuccessToast({
        description: 'Portfolio has been created',
      });
      onSaved();
    } else {
      setShowSaveCreateSuccess(true);
    }
  }, [
    onSaved,
    portfolioState,
    createPortfolioMutation,
    sanitizedPortfolioState,
  ]);

  const onContinue = () => {
    const states = Object.values(PORTFOLIO_FORM_STATES);
    const currentIndex = states.findIndex(
      (state) => state === formProgressionState,
    );
    if (currentIndex < states.length) {
      setFormProgressionState(states[currentIndex + 1]);
    }
  };

  const onBack = () => {
    const states = Object.values(PORTFOLIO_FORM_STATES);
    const currentIndex = states.findIndex(
      (state) => state === formProgressionState,
    );
    if (currentIndex > 0) {
      setFormProgressionState(states[currentIndex - 1]);
    }
  };

  const redirectToPortfoliosTablePage = () => {
    navigate('/offsetting/portfolios');
    closeModal();
  };

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const clientName = useMemo(
    () =>
      dataClients?.clients.items.find(
        (client) => client.id === portfolioState?.portfolio_draft?.acl?.[0],
      )?.legal_name,
    [dataClients?.clients, portfolioState],
  );

  const isLoading = createLoading || draftLoading;

  const getPortfolioFormPage = () => {
    switch (formProgressionState) {
      case PORTFOLIO_FORM_STATES.ALLOCATIONS:
        return (
          <PortfolioEditAllocations
            updateState={updateState}
            portfolio={portfolioState}
          />
        );
      case PORTFOLIO_FORM_STATES.ENTITLEMENTS_AND_ACCOUNTING:
        return (
          <PortfolioEditSettings
            formId={formId}
            updateState={updateState}
            clientList={dataClients?.clients.items}
            loadingClients={loadingClients}
            portfolio={portfolioState}
          />
        );
      case PORTFOLIO_FORM_STATES.DETAILS:
        return (
          <PortfolioEditDetails
            formId={formId}
            updateState={updateState}
            clientName={clientName}
            portfolio={portfolioState}
          />
        );
      case PORTFOLIO_FORM_STATES.SUMMARY:
        return (
          <PortfolioEditSummary
            clientName={clientName}
            portfolio={portfolioState}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog>
      <DialogHeader>
        <DialogTitle>
          <FormattedMessage id="portfolio.edit.title" />
        </DialogTitle>
        <OverlayCloseButton label={formatMessage({ id: 'close' })} />
      </DialogHeader>

      <DialogBody>
        {draftError || createError ? (
          <BannerMessage variant="critical">
            <Text>
              <FormattedMessage id="portfolio.edit.fail" />
              {createError ? createError?.graphQLErrors[0].message : null}
              {draftError ? draftError?.graphQLErrors[0].message : null}
            </Text>
          </BannerMessage>
        ) : null}
        {showSaveDraftSuccess ? (
          <EntityAdded
            closeModal={handleClose}
            entityAddedTitle="Portfolio draft saved"
            actionButtonCTAText="View in portfolios table"
            actionButtonCTA={redirectToPortfoliosTablePage}
          />
        ) : null}
        {showSaveCreateSuccess ? (
          <EntityAdded
            closeModal={handleClose}
            entityAddedTitle="Portfolio created"
            actionButtonCTAText="View portfolios table"
            actionButtonCTA={redirectToPortfoliosTablePage}
          />
        ) : null}
        {!showSaveDraftSuccess && !showSaveCreateSuccess && (
          <Spin spinning={isLoading}>{getPortfolioFormPage()}</Spin>
        )}
      </DialogBody>

      {!showSaveDraftSuccess && !showSaveCreateSuccess && (
        <DialogFooter>
          <Button onPress={onBack}>
            <FormattedMessage id="portfolio.edit.buttons.back" />
          </Button>

          <Button onPress={saveAsDraft} variant="default" className="ml-auto">
            <FormattedMessage id="portfolio.edit.buttons.save-as-draft" />
          </Button>
          {formProgressionState === PORTFOLIO_FORM_STATES.SUMMARY ? (
            <Button
              onPress={createPortfolio}
              variant="primary"
              type="submit"
              form={formId}
            >
              <FormattedMessage id="portfolio.edit.buttons.create-portfolio" />
            </Button>
          ) : (
            <Button
              onPress={onContinue}
              variant="primary"
              type="submit"
              form={formId}
            >
              <FormattedMessage id="portfolio.edit.buttons.continue" />
            </Button>
          )}
        </DialogFooter>
      )}
    </Dialog>
  );
}
