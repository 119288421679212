import omit from 'lodash/omit';
import pick from 'lodash/pick';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { useCallback, useEffect, useId, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  Form,
  // eslint-disable-next-line no-restricted-imports -- blocked by PPL combobox
  Select,
  Spin,
} from '@pledge-earth/web-components';
import {
  Button,
  Checkbox,
  Heading,
  Text,
  BannerMessage,
  TextAreaField,
  TextField,
  SelectField,
  OptionListItem,
  Size,
  Dialog,
  DialogHeader,
  DialogTitle,
  OverlayCloseButton,
  DialogBody,
  DialogFooter,
  CheckboxGroup,
  Label,
} from '@pledge-earth/product-language';

import { ImageUploadDisplay } from '../../UploadFile/ImageUpload';
import { DragImageUpload } from '../../UploadFile/DragUpload';
import type {
  UpsertProjectMutationVariables,
  S3ObjectInput,
  GetProjectQuery,
} from '../../../services/graphql/generated';
import {
  S3FolderEnum,
  useGetCountriesQuery,
  SustainableDevelopmentGoalsEnum,
  useGetRegistriesQuery,
  useGetProjectDevelopersNameIdQuery,
  useUpsertProjectMutation,
  useGetProjectTypesQuery,
  useGetProjectOwnersQuery,
  useGetProjectCertificationsQuery,
} from '../../../services/graphql/generated';
import { EntityAdded } from '../EntityAdded';
import { mapSdgsToReadableSdgs } from '../../../pages/projects/details/sdgs';
import { showErrorToast, showSuccessToast } from '../../../utils/toast';

interface FormInputData {
  name: string;
  description: string;
  website: string;
  location: string;
  project_type_id: string;
  mechanism: ('removal' | 'avoidance')[];
  country_id: string;
  developer_id: string;
  owner_id: string;
  registry_id: string;
  registry_project_url: string;
  registry_project_id: string;
  contact: string;
  latitude: string;
  longitude: string;
  project_certification_ids: string[];
  sdgs: SustainableDevelopmentGoalsEnum[];
  carbon_plan_id: string;
}

function parseProjectIntoFormValues(
  project?: GetProjectQuery['project'],
): Partial<FormInputData> | undefined {
  if (!project) {
    return undefined;
  }

  return {
    ...pick(omitBy(project, isNil), [
      'id',
      'description',
      'avoidance',
      'removal',
      'name',
      'sdgs',
      'registry_project_url',
      'registry_project_id',
      'contact',
      'website',
      'media',
      'location',
    ]),
    project_type_id: project.project_type.id,
    registry_id: project.registry?.id,
    owner_id: project.owner?.id,
    developer_id: project.developer?.id,
    country_id: project.country.id,
    project_certification_ids: project.certifications?.map(
      (certification) => certification.id,
    ),
    latitude: project.coordinates?.coordinates[0]?.toString(),
    longitude: project.coordinates?.coordinates[1]?.toString(),
  };
}

export function AddProject({
  closeModal,
  projectDetails,
  onSave,
}: {
  closeModal: () => void;
  projectDetails?: GetProjectQuery['project'];
  onSave?: () => void;
}): JSX.Element {
  const navigate = useNavigate();
  const [mediaArray, setMediaArray] = useState<S3ObjectInput[]>(
    projectDetails?.media ?? [],
  );
  const [projectAdded, setProjectAdded] = useState<boolean>(false);
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [createdProjectId, setCreatedProjectId] = useState<string>();
  const { formatMessage } = useIntl();
  const [
    upsertProjectMutation,
    {
      loading: upsertProjectLoading,
      data: upsertProjectSuccess,
      error: upsertProjectFailure,
    },
  ] = useUpsertProjectMutation();

  const { data: dataCountries, loading: loadingCountries } =
    useGetCountriesQuery();
  const { data: dataRegistries, loading: loadingRegistries } =
    useGetRegistriesQuery();
  const { data: dataProjectDevelopers, loading: loadingProjectDevelopers } =
    useGetProjectDevelopersNameIdQuery();
  const { data: dataProjectTypes, loading: loadingProjectTypes } =
    useGetProjectTypesQuery();
  const { data: dataProjectOwners, loading: loadingProjectOwners } =
    useGetProjectOwnersQuery();
  const {
    data: dataProjectCertifications,
    loading: loadingProjectCertifications,
  } = useGetProjectCertificationsQuery();

  const isLoadingDropdowns =
    loadingCountries ||
    loadingRegistries ||
    loadingProjectDevelopers ||
    loadingProjectTypes ||
    loadingProjectOwners ||
    loadingProjectCertifications;

  useEffect(() => {
    if (upsertProjectSuccess) {
      setProjectAdded(true);
      if (onSave !== undefined) {
        onSave();
      }
      showSuccessToast({
        description: 'Project has been updated',
      });
    } else if (upsertProjectFailure) {
      showErrorToast({
        description: `Project has not been updated ...${upsertProjectFailure.graphQLErrors[0].message}`,
      });
    }
  }, [upsertProjectSuccess, upsertProjectFailure, onSave]);

  const formId = useId();
  const [addProjectForm] = Form.useForm();

  const handleImageUpload = useCallback(
    (file) => {
      if (file) {
        setMediaArray(
          mediaArray.concat({
            location: file.location,
            eTag: file.eTag,
            bucket: file.bucket,
            key: file.key,
          }),
        );
      } else {
        setMediaArray([]);
      }
    },
    [setMediaArray, mediaArray],
  );

  const handleImageRemove = useCallback(
    (file) => {
      if (file) {
        const filtered = mediaArray.filter((obj) => obj.key !== file.uid);
        setMediaArray(filtered);
      }
    },
    [mediaArray],
  );

  const redirectToProjectDetailsPage = useCallback(() => {
    if (createdProjectId) {
      navigate(`/offsetting/projects/${createdProjectId}`);
    }
    closeModal();
  }, [createdProjectId, closeModal, navigate]);
  const projectCertificationDropdownOptions = useMemo(
    () =>
      dataProjectCertifications?.project_certifications.map((certification) => (
        <Select.Option value={certification.id} key={certification.id}>
          {certification.name}
        </Select.Option>
      )),
    [dataProjectCertifications?.project_certifications],
  );

  const sdgsDropdownOptions = useMemo(
    () =>
      Object.entries(SustainableDevelopmentGoalsEnum).map(([key, value]) => (
        <Select.Option
          key={key}
          value={value}
          label={mapSdgsToReadableSdgs(value)}
        >
          {mapSdgsToReadableSdgs(value)}
        </Select.Option>
      )),
    [],
  );

  useEffect(() => {
    addProjectForm.resetFields();
  }, [addProjectForm]);

  const submitAddProject = useCallback(
    async (inputData: FormInputData) => {
      const payload: UpsertProjectMutationVariables['data'] = {
        id: projectDetails?.id ?? undefined,
        name: inputData.name,
        description: inputData.description,
        website: inputData.website,
        project_type_id: inputData.project_type_id,
        removal: inputData.mechanism.includes('removal'),
        avoidance: inputData.mechanism.includes('avoidance'),
        country_id: inputData.country_id,
        developer_id: inputData.developer_id,
        owner_id: inputData.owner_id,
        registry_id: inputData.registry_id,
        registry_project_id: inputData.registry_project_id,
        registry_project_url: inputData.registry_project_url,
        project_certification_ids: inputData.project_certification_ids,
        sdgs: inputData.sdgs,
        contact: inputData.contact,
        location: inputData.location,
        coordinates: {
          coordinates: [
            parseFloat(inputData.longitude),
            parseFloat(inputData.latitude),
          ],
        },
      };
      payload.carbon_plan_id = inputData.carbon_plan_id
        ?.split('?id=')
        ?.reverse()[0];
      payload.media = mediaArray.length
        ? mediaArray.map((obj) => omit(obj, '__typename'))
        : null;
      const result = await upsertProjectMutation({
        variables: {
          data: payload,
        },
      });
      const projectId = result.data?.upsert_project.public_id;
      setCreatedProjectId(projectId);
    },
    [upsertProjectMutation, mediaArray, projectDetails],
  );

  const onFinishFailed = useCallback(() => {
    setShowErrorBanner(true);
  }, []);

  return (
    <Dialog>
      <DialogHeader>
        <DialogTitle>
          {projectDetails ? (
            <FormattedMessage id="edit-project.title" />
          ) : (
            <FormattedMessage id="add-project.title" />
          )}
        </DialogTitle>
        <OverlayCloseButton label={formatMessage({ id: 'close' })} />
      </DialogHeader>

      <DialogBody>
        {projectAdded ? (
          <EntityAdded
            closeModal={closeModal}
            entityAddedTitle={formatMessage({
              id: 'add-project.project_saved',
            })}
            actionButtonCTAText={formatMessage({
              id: 'add-project.view-project',
            })}
            actionButtonCTA={redirectToProjectDetailsPage}
          />
        ) : (
          <>
            {showErrorBanner ? (
              <BannerMessage variant="critical">
                <Text>
                  <FormattedMessage id="add-project.form_not_complete.title" />
                </Text>
              </BannerMessage>
            ) : null}

            <Spin spinning={isLoadingDropdowns}>
              <Form
                id={formId}
                layout="vertical"
                requiredMark={false}
                form={addProjectForm}
                size="large"
                onFinish={submitAddProject}
                onFinishFailed={onFinishFailed}
                initialValues={parseProjectIntoFormValues(projectDetails)}
              >
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: 'add-project.name.message.required',
                      }),
                    },
                  ]}
                >
                  <TextField
                    label={<FormattedMessage id="add-project.name" />}
                    size={Size.Loose}
                    placeholder={formatMessage({
                      id: 'add-project.name.placeholder',
                    })}
                  />
                </Form.Item>
                <Form.Item
                  name="project_type_id"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: 'add-project.project_type.message.required',
                      }),
                    },
                  ]}
                >
                  <SelectField
                    size={Size.Loose}
                    label={<FormattedMessage id="add-project.project_type" />}
                    placeholder={formatMessage({
                      id: 'add-project.project_type.placeholder',
                    })}
                    items={dataProjectTypes?.project_types}
                  >
                    {(project) => (
                      <OptionListItem id={project.id}>
                        {project.name}
                      </OptionListItem>
                    )}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: 'add-project.description.message.required',
                      }),
                    },
                  ]}
                >
                  <TextAreaField
                    label={<FormattedMessage id="add-project.description" />}
                    placeholder={formatMessage({
                      id: 'add-project.description.placeholder',
                    })}
                  />
                </Form.Item>
                <Form.Item name="mechanism">
                  <CheckboxGroup
                    label={<FormattedMessage id="add-project.mechanism" />}
                  >
                    <Checkbox value="removal">
                      <FormattedMessage id="add-project.removal" />
                    </Checkbox>
                    <Checkbox value="avoidance">
                      <FormattedMessage id="add-project.avoidance" />
                    </Checkbox>
                  </CheckboxGroup>
                </Form.Item>
                <Form.Item
                  name="developer_id"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: 'add-project.developer.message.required',
                      }),
                    },
                  ]}
                >
                  <SelectField
                    size={Size.Loose}
                    label={<FormattedMessage id="add-project.developer" />}
                    placeholder={formatMessage({
                      id: 'add-project.developer.placeholder',
                    })}
                    items={dataProjectDevelopers?.project_developers}
                  >
                    {(developer) => (
                      <OptionListItem id={developer.id}>
                        {developer.name}
                      </OptionListItem>
                    )}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="owner_id"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: 'add-project.owner.message.required',
                      }),
                    },
                  ]}
                >
                  <SelectField
                    size={Size.Loose}
                    label={<FormattedMessage id="add-project.owner" />}
                    placeholder={formatMessage({
                      id: 'add-project.owner.placeholder',
                    })}
                    items={dataProjectOwners?.project_owners}
                  >
                    {(owner) => (
                      <OptionListItem id={owner.id}>
                        {owner.name}
                      </OptionListItem>
                    )}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="registry_id"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: 'add-project.registry.message.required',
                      }),
                    },
                  ]}
                >
                  <SelectField
                    size={Size.Loose}
                    label={<FormattedMessage id="add-project.registry" />}
                    placeholder={formatMessage({
                      id: 'add-project.registry.placeholder',
                    })}
                    items={dataRegistries?.registries}
                  >
                    {(registry) => (
                      <OptionListItem id={registry.id}>
                        {registry.name}
                      </OptionListItem>
                    )}
                  </SelectField>
                </Form.Item>

                <Heading level="h5">
                  <FormattedMessage id="add-project.additional-information" />
                </Heading>
                <Form.Item
                  name="project_certification_ids"
                  label={
                    <Label elementType="span">
                      <FormattedMessage id="add-project.project_certifications" />
                    </Label>
                  }
                >
                  <Select
                    size="large"
                    placeholder={formatMessage({
                      id: 'add-project.project_certifications.placeholder',
                    })}
                    mode="multiple"
                  >
                    {projectCertificationDropdownOptions}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="sdgs"
                  label={
                    <Label elementType="span">
                      <FormattedMessage id="add-project.sdgs" />
                    </Label>
                  }
                >
                  <Select
                    size="large"
                    placeholder={formatMessage({
                      id: 'add-project.sdgs.placeholder',
                    })}
                    mode="multiple"
                    filterOption={(input, option) =>
                      option?.value
                        .toLowerCase()
                        .includes(input.toLowerCase()) ||
                      option?.label.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {sdgsDropdownOptions}
                  </Select>
                </Form.Item>
                <Form.Item name="carbon_plan_id">
                  <TextField
                    label={<FormattedMessage id="add-project.carbon-plan-id" />}
                    description={
                      <div>
                        To find the carbon plan ID, go to the&nbsp;
                        <a
                          href="https://carbonplan.org/research/cdr-database"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          CDR database
                        </a>
                        , find your project and click share and then paste the
                        url above
                      </div>
                    }
                    size={Size.Loose}
                    placeholder={formatMessage({
                      id: 'add-project.carbon-plan-id.placeholder',
                    })}
                  />
                </Form.Item>
                <Form.Item name="registry_project_id">
                  <TextField
                    label={
                      <FormattedMessage id="add-project.registry-project-id" />
                    }
                    size={Size.Loose}
                    placeholder={formatMessage({
                      id: 'add-project.registry-project-id.placeholder',
                    })}
                  />
                </Form.Item>
                <Form.Item name="registry_project_url">
                  <TextField
                    label={
                      <FormattedMessage id="add-project.registry-project-url" />
                    }
                    size={Size.Loose}
                    placeholder={formatMessage({
                      id: 'add-project.registry-project-url.placeholder',
                    })}
                  />
                </Form.Item>
                <Form.Item
                  name="website"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: 'add-project.website.message.required',
                      }),
                    },
                  ]}
                >
                  <TextField
                    label={<FormattedMessage id="add-project.website" />}
                    size={Size.Loose}
                    placeholder={formatMessage({
                      id: 'add-project.website.placeholder',
                    })}
                  />
                </Form.Item>
                <Form.Item name="contact">
                  <TextField
                    label={<FormattedMessage id="add-project.contact" />}
                    size={Size.Loose}
                    placeholder={formatMessage({
                      id: 'add-project.contact.placeholder',
                    })}
                  />
                </Form.Item>

                <Heading level="h5">
                  <FormattedMessage id="add-project.whereabouts.title" />
                </Heading>

                <Form.Item
                  name="country_id"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: 'add-project.country.message.required',
                      }),
                    },
                  ]}
                >
                  <SelectField
                    size={Size.Loose}
                    label={<FormattedMessage id="add-project.country" />}
                    placeholder={formatMessage({
                      id: 'add-project.country.placeholder',
                    })}
                    items={dataCountries?.countries}
                  >
                    {(country) => (
                      <OptionListItem id={country.id}>
                        {country.code} - {country.name}
                      </OptionListItem>
                    )}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="location"
                  rules={[
                    {
                      required: true,
                      message: formatMessage({
                        id: 'add-project.location.message.required',
                      }),
                    },
                  ]}
                >
                  <TextField
                    label={<FormattedMessage id="add-project.location" />}
                    size={Size.Loose}
                    placeholder={formatMessage({
                      id: 'add-project.location.placeholder',
                    })}
                  />
                </Form.Item>
                <div className="flex gap-4 w-full">
                  <Form.Item
                    name="latitude"
                    rules={[
                      {
                        required: true,
                        message: formatMessage({
                          id: 'add-project.latitude.message.required',
                        }),
                      },
                    ]}
                  >
                    <TextField
                      label={<FormattedMessage id="add-project.latitude" />}
                      size={Size.Loose}
                      type="number"
                      placeholder={formatMessage({
                        id: 'add-project.latitude.placeholder',
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    name="longitude"
                    rules={[
                      {
                        required: true,
                        message: formatMessage({
                          id: 'add-project.longitude.message.required',
                        }),
                      },
                    ]}
                  >
                    <TextField
                      label={<FormattedMessage id="add-project.longitude" />}
                      size={Size.Loose}
                      type="number"
                      placeholder={formatMessage({
                        id: 'add-project.longitude.placeholder',
                      })}
                    />
                  </Form.Item>
                </div>

                <Heading level="h5">
                  <FormattedMessage id="add-project.media" />
                </Heading>
                <Form.Item name="logo1">
                  <DragImageUpload
                    display={ImageUploadDisplay.SQUARE}
                    onUploadImage={handleImageUpload}
                    onRemoveImage={handleImageRemove}
                    folder={S3FolderEnum.ProjectDeveloper}
                    initialFileList={projectDetails?.media}
                  />
                </Form.Item>
              </Form>
            </Spin>
          </>
        )}
      </DialogBody>

      {!projectAdded && (
        <DialogFooter>
          <Button onPress={closeModal}>
            <FormattedMessage id="add-project.cancel_button" />
          </Button>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            isLoading={upsertProjectLoading}
          >
            <FormattedMessage id="add-project.add_button" />
          </Button>
        </DialogFooter>
      )}
    </Dialog>
  );
}
