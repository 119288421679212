import { FormattedMessage } from 'react-intl';
import {
  DescriptionDetails,
  DescriptionList,
  DescriptionListItem,
  DescriptionTerm,
  Text,
} from '@pledge-earth/product-language';
import { ProgressBar } from '@pledge-earth/web-components';

import { SideBarSVG } from '../../SideBarSVG/SideBarSVG';
import { PortfolioTaxonomyTag } from '../OffsettingTags/PortfolioTaxonomyTag';

import './PortfolioDistribution.scss';

interface PortfolioDistributionProps {
  removal: number;
  avoidance: number;
  hideDescription?: boolean;
}

export function PortfolioDistribution({
  removal,
  avoidance,
  hideDescription,
}: PortfolioDistributionProps) {
  return (
    <div className="PortfolioDistribution">
      {!hideDescription && (
        <Text className="PortfolioDistribution__description">
          <FormattedMessage id="offsetting.portfolioDetails.thisPortfolioHas" />{' '}
          <PortfolioTaxonomyTag removalPercentage={removal} />
          <FormattedMessage id="offsetting.portfolioDetails.projects" />
        </Text>
      )}
      <DescriptionList columns={2}>
        <DescriptionListItem className="relative">
          <SideBarSVG solid={true} />
          <DescriptionTerm className="flex pl-2">
            <FormattedMessage id="offsetting.portfolio.removals" />
          </DescriptionTerm>
          <DescriptionDetails className="flex pl-2">
            <Text className="font-bold">{removal}%</Text>
          </DescriptionDetails>
        </DescriptionListItem>
        <DescriptionListItem className="relative">
          <DescriptionTerm className="flex justify-end pr-2">
            <FormattedMessage id="offsetting.portfolio.avoidance" />
          </DescriptionTerm>
          <DescriptionDetails className="flex justify-end pr-2">
            <Text className="font-bold">{avoidance}%</Text>
          </DescriptionDetails>
          <SideBarSVG className="right-0" />
        </DescriptionListItem>
      </DescriptionList>
      <div className="PortfolioDistribution__distribution__bars">
        <div
          style={{
            width: `${removal === 0 ? '2px' : `${removal}%`}`,
          }}
        >
          <ProgressBar percent={100} />
        </div>
        <div
          style={{
            width: `${avoidance === 0 ? '2px' : `${avoidance}%`}`,
          }}
        >
          <ProgressBar striped={true} percent={100} />
        </div>
      </div>
    </div>
  );
}
