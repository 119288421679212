import { FormattedMessage } from 'react-intl';
import {
  ActionMenu,
  ActionMenuItem,
  Avatar,
  ChevronDownIcon,
  IconButton,
} from '@pledge-earth/product-language';

import { logout as logoutAction } from '../../../store/user/reducers';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export function UserMenu() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);

  return (
    <ActionMenu
      triggerElement={
        <IconButton label="User menu" variant="plain" className="w-auto">
          <Avatar>{user.name.charAt(0)?.toUpperCase()}</Avatar>
          <ChevronDownIcon />
        </IconButton>
      }
    >
      <ActionMenuItem key="logout" onAction={() => dispatch(logoutAction())}>
        <FormattedMessage id="topBar.profileMenu.logout" />
      </ActionMenuItem>
    </ActionMenu>
  );
}
