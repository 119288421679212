import { LoadingIcon } from '@pledge-earth/product-language';
import { Spin } from '@pledge-earth/web-components';
import * as Sentry from '@sentry/react';
import { Suspense, lazy } from 'react';
import type { UIMatch } from 'react-router-dom';
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import { AuthLayout, BaseLayout, MainLayout } from './layouts';
import { getIntl } from './locales/intl';

const Dashboard = lazy(() => import('./pages/dashboard'));
const Clients = lazy(() => import('./pages/clients'));
const ClientsAdd = lazy(() => import('./pages/clients/add'));
const Client = lazy(() => import('./pages/clients/client'));
const Portfolios = lazy(() => import('./pages/portfolios'));
const PortfolioDetails = lazy(
  () => import('./pages/portfolios/PortfolioDetails'),
);
const ProjectDevelopers = lazy(() => import('./pages/projectDevelopers'));
const ProjectDevelopersDetails = lazy(
  () => import('./pages/projectDevelopers/details'),
);
const Projects = lazy(() => import('./pages/projects'));
const ProjectDetails = lazy(() => import('./pages/projects/details'));
const ProjectOwners = lazy(() => import('./pages/projectOwners'));
const ProjectOwnerDetails = lazy(
  () => import('./pages/projectOwners/ProjectOwnerDetails'),
);
const ProjectTypes = lazy(() => import('./pages/projectTypes'));
const ProjectTypeDetails = lazy(
  () => import('./pages/projectTypes/ProjectTypeDetails'),
);
const ProjectCertifications = lazy(
  () => import('./pages/projectCertifications'),
);
const ProjectCertificationDetails = lazy(
  () => import('./pages/projectCertifications/ProjectCertificationDetails'),
);
const Inventory = lazy(() => import('./pages/inventory'));
const InventoryAdd = lazy(() => import('./pages/inventory/add'));
const InventoryRemove = lazy(() => import('./pages/inventory/remove'));
const InventoryReconciliation = lazy(
  () => import('./pages/inventoryReconciliation'),
);
const RetirementsConfirmedTable = lazy(
  () => import('./pages/retirements/retirementsConfirmedTable'),
);
const RetirementsRequestedTable = lazy(
  () => import('./pages/retirements/retirementsRequestedTable'),
);
const Registries = lazy(() => import('./pages/registries'));
const RegistryDetails = lazy(
  () => import('./pages/registries/RegistryDetails'),
);
const Tools = lazy(() => import('./pages/tools'));
const Orders = lazy(() => import('./pages/orders'));
const AuthLogin = lazy(() => import('./pages/auth/login'));
const Auth404 = lazy(() => import('./pages/auth/404'));
const Auth500 = lazy(() => import('./pages/auth/500'));
const DataCollectionLinkImport = lazy(
  () => import('./pages/dataCollection/LinkImport'),
);
const DataCollectionSubmitRequestData = lazy(
  () => import('./pages/dataCollection/SubmitRequestData'),
);

const sentryCreateBrowserRouter =
  process.env.NODE_ENV === 'production'
    ? Sentry.wrapCreateBrowserRouter(createBrowserRouter)
    : createBrowserRouter;

const { formatMessage } = getIntl();

export const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: (
      <BaseLayout>
        <MainLayout>
          <Suspense
            fallback={
              <div className="flex w-full h-80 justify-center items-center">
                <Spin
                  indicator={<LoadingIcon className="animate-spin" />}
                  size="large"
                  delay={100}
                />
              </div>
            }
          >
            <Outlet />
          </Suspense>
        </MainLayout>
      </BaseLayout>
    ),
    children: [
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'clients',
        children: [
          {
            index: true,
            element: <Clients />,
          },
          {
            path: ':publicId',
            element: <Client />,
          },
          {
            path: 'add',
            element: <ClientsAdd />,
          },
        ],
      },
      {
        path: 'offsetting/portfolios',
        handle: { crumb: () => formatMessage({ id: 'portfolios.title' }) },
        children: [
          {
            index: true,
            element: <Portfolios />,
          },
          {
            path: ':portfolioId',
            element: <PortfolioDetails />,
            handle: { crumb: ({ params }: UIMatch) => params.portfolioId },
          },
        ],
      },
      {
        path: 'offsetting/project-developers',
        handle: {
          crumb: () => formatMessage({ id: 'projectDevelopers.title' }),
        },
        children: [
          {
            index: true,
            element: <ProjectDevelopers />,
          },
          {
            path: ':projectDeveloperId',
            element: <ProjectDevelopersDetails />,
            handle: {
              crumb: ({ params }: UIMatch) => params.projectDeveloperId,
            },
          },
        ],
      },
      {
        path: 'offsetting/projects',
        handle: { crumb: () => formatMessage({ id: 'projects.title' }) },
        children: [
          {
            index: true,
            element: <Projects />,
          },
          {
            path: ':projectId/:tabName?',
            element: <ProjectDetails />,
            handle: { crumb: ({ params }: UIMatch) => params.projectId },
          },
        ],
      },
      {
        path: 'offsetting/project-owners',
        handle: { crumb: () => formatMessage({ id: 'project-owners.title' }) },
        children: [
          {
            index: true,
            element: <ProjectOwners />,
          },
          {
            path: ':projectOwnerId',
            element: <ProjectOwnerDetails />,
            handle: { crumb: ({ params }: UIMatch) => params.projectOwnerId },
          },
        ],
      },
      {
        path: 'offsetting/project-types',
        handle: { crumb: () => formatMessage({ id: 'project-types.title' }) },
        children: [
          {
            index: true,
            element: <ProjectTypes />,
          },
          {
            path: ':projectTypeId',
            element: <ProjectTypeDetails />,
            handle: { crumb: ({ params }: UIMatch) => params.projectTypeId },
          },
        ],
      },
      {
        path: 'offsetting/project-certifications',
        handle: {
          crumb: () => formatMessage({ id: 'project-certifications.title' }),
        },
        children: [
          {
            index: true,
            element: <ProjectCertifications />,
          },
          {
            path: ':projectCertificationId',
            element: <ProjectCertificationDetails />,
            handle: {
              crumb: ({ params }: UIMatch) => params.projectCertificationId,
            },
          },
        ],
      },
      {
        path: 'offsetting/inventory',
        children: [
          {
            index: true,
            element: <Inventory />,
          },
          {
            path: 'add',
            element: <InventoryAdd />,
          },
          {
            path: 'remove',
            element: <InventoryRemove />,
          },
        ],
      },
      {
        path: 'offsetting/inventoryReconciliation',
        element: <InventoryReconciliation />,
      },
      {
        path: 'offsetting/retirements',
        children: [
          {
            index: true,
            element: <RetirementsConfirmedTable />,
          },
          {
            path: 'pending',
            element: <RetirementsRequestedTable />,
          },
        ],
      },
      {
        path: 'offsetting/registries',
        handle: { crumb: () => formatMessage({ id: 'registries.title' }) },
        children: [
          {
            index: true,
            element: <Registries />,
          },
          {
            path: ':registryId',
            element: <RegistryDetails />,
            handle: { crumb: ({ params }: UIMatch) => params.registryId },
          },
        ],
      },
      {
        path: 'data-collection/link-import',
        element: <DataCollectionLinkImport />,
      },
      {
        path: 'data-collection/submit-request-data',
        element: <DataCollectionSubmitRequestData />,
      },
      {
        path: 'tools',
        element: <Tools />,
      },
      {
        path: 'orders',
        element: <Orders />,
      },
    ],
  },
  {
    path: '/auth',
    element: (
      <BaseLayout isAuthLayout={true}>
        <AuthLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </AuthLayout>
      </BaseLayout>
    ),
    children: [
      {
        path: 'login',
        element: <AuthLogin />,
      },
      {
        path: '404',
        element: <Auth404 />,
      },
      {
        path: '500',
        element: <Auth500 />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/auth/404" replace={true} />,
  },
]);

export function Router() {
  return <RouterProvider router={router} />;
}
