import { all, put, call } from 'redux-saga/effects';

import { getMenuData } from '../../services/menu';

import { menuDataRetrieved } from './reducers';

// eslint-disable-next-line @typescript-eslint/naming-convention -- eslint onboarding
function* GET_DATA() {
  const menuData = yield call(getMenuData);
  yield put(menuDataRetrieved(menuData));
}

export function* rootSaga() {
  yield all([
    GET_DATA(), // run once on app load to fetch menu data
  ]);
}
