import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Divider, Spin } from '@pledge-earth/web-components';
import {
  Heading,
  Text,
  BannerMessage,
  Size,
} from '@pledge-earth/product-language';

import type { PortfolioDraftInput } from '../../../services/graphql/generated';
import {
  CurrencyEnum,
  useGetAvailableInventoryQuery,
} from '../../../services/graphql/generated';
import { PortfolioDistribution } from '../../../components/Offsetting/PortfolioDistribution/PortfolioDistribution';
import { ComboBoxSearch } from '../../../components/Input/ComboBoxSearch';
import { PortfolioSummaryBreakdown } from '../PortfolioSummaryBreakdown';

import { ProjectInventoryCard } from './ProjectInventoryCard';
import { calculatePortfolioDraftSummaryFields } from './portfolioEditHelpers';
import './PortfolioEditAllocations.scss';

export function PortfolioEditAllocations({
  updateState,
  portfolio,
}: {
  updateState: (newPortfolioState: PortfolioDraftInput) => void;
  portfolio: PortfolioDraftInput;
}) {
  const { formatMessage } = useIntl();

  const { data: dataInventory, loading: loadingInventory } =
    useGetAvailableInventoryQuery();
  const [selectedProjectInventories, setSelectedProjectInventories] = useState<
    { inventory_id: string; assigned_weight: number }[]
  >([]);
  const [addNewProject, setAddNewProject] = useState<boolean>(false);
  const [invalidProjects, setInvalidProjects] = useState<
    { project_name: string; assigned_weight: number }[]
  >([]);

  useEffect(() => {
    const projectInventories: {
      inventory_id: string;
      assigned_weight: number;
    }[] = [];
    if (!dataInventory?.available_inventory) {
      return;
    }
    portfolio.portfolio_draft?.allocations?.forEach((allocation) => {
      const matchingInventory = dataInventory?.available_inventory.find(
        (inventory) =>
          inventory.project.id === allocation.project_id &&
          inventory.vintage === allocation.vintage,
      );
      if (
        matchingInventory &&
        (matchingInventory.weight_kg - matchingInventory.allocated_weight_kg) /
          1000 >=
          allocation.weight_tonnes
      ) {
        projectInventories.push({
          inventory_id: matchingInventory.id,
          assigned_weight: allocation.weight_tonnes,
        });
      } else {
        const updatedAllocations =
          portfolio.portfolio_draft?.allocations?.filter(
            (allocationInState) => allocationInState !== allocation,
          );
        const updatedPortfolioState = {
          id: portfolio.id,
          portfolio_draft: {
            ...portfolio.portfolio_draft,
            allocations: updatedAllocations,
          },
        };
        updateState(updatedPortfolioState);
        const updatedInvalidProjects = [
          ...invalidProjects,
          {
            project_name: 'project_name',
            assigned_weight: allocation.weight_tonnes,
          },
        ];
        setInvalidProjects(updatedInvalidProjects);
      }
    });
    setSelectedProjectInventories(projectInventories ?? []);
  }, [
    dataInventory?.available_inventory,
    portfolio,
    invalidProjects,
    setInvalidProjects,
    setSelectedProjectInventories,
    updateState,
  ]);

  const availableInventoryDropdownOptions = useMemo(
    () =>
      dataInventory?.available_inventory.filter(
        (available_inventory) =>
          !selectedProjectInventories.find(
            (selectedProjectInventory) =>
              available_inventory.id === selectedProjectInventory.inventory_id,
          ),
      ),
    [dataInventory?.available_inventory, selectedProjectInventories],
  );

  const onInventorySelect = (inventory_id) => {
    const matchingInventory = dataInventory?.available_inventory.find(
      (inventory) => inventory.id === inventory_id,
    );
    if (!matchingInventory) {
      return;
    }
    const currentAllocations = portfolio.portfolio_draft.allocations ?? [];
    const updatedAllocations = [
      ...currentAllocations,
      {
        project_id: matchingInventory.project.id,
        project_name: matchingInventory.project.name,
        registry_id: matchingInventory.registry.id,
        registry: matchingInventory.registry.name,
        vintage: matchingInventory.vintage,
        weight_tonnes: 0,
        price_per_kg_in_gbp: matchingInventory.price_per_kg_in_gbp,
        avoidance: matchingInventory.project.avoidance,
        removal: matchingInventory.project.removal,
        registry_fee_per_tonne:
          matchingInventory.project.registry
            ?.registry_retirement_fee_per_tonne ?? 0,
      },
    ];
    const updatedPortfolioState = {
      id: portfolio.id,
      portfolio_draft: {
        ...portfolio.portfolio_draft,
        allocations: updatedAllocations,
      },
    };
    updateState(updatedPortfolioState);
    setAddNewProject(false);
  };

  const removeSelectedInventory = useCallback(
    (project_id, vintage) => {
      const matchingAllocationInState =
        portfolio.portfolio_draft.allocations?.find(
          (allocation) =>
            allocation.project_id === project_id &&
            allocation.vintage === vintage,
        );

      const currentAllocations = portfolio.portfolio_draft.allocations ?? [];
      const updatedAllocations = currentAllocations.filter(
        (allocation) => matchingAllocationInState !== allocation,
      );
      const updatedPortfolioState = {
        id: portfolio.id,
        portfolio_draft: {
          ...portfolio.portfolio_draft,
          allocations: updatedAllocations,
        },
      };
      updateState(updatedPortfolioState);
    },
    [portfolio, updateState],
  );

  const updateAssignedWeight = useCallback(
    (project_id, vintage, weight_tonnes) => {
      const matchingAllocationInStateIndex =
        portfolio.portfolio_draft.allocations?.findIndex(
          (allocation) =>
            allocation.project_id === project_id &&
            allocation.vintage === vintage,
        ) ?? -1;
      if (
        portfolio.portfolio_draft.allocations &&
        matchingAllocationInStateIndex >= 0
      ) {
        const { allocations } = portfolio.portfolio_draft;
        allocations[matchingAllocationInStateIndex].weight_tonnes = Math.floor(
          parseFloat(weight_tonnes),
        );
        const updatedPortfolioState = {
          id: portfolio.id,
          portfolio_draft: {
            ...portfolio.portfolio_draft,
            allocations,
          },
        };
        updateState(updatedPortfolioState);
      }
    },
    [portfolio, updateState],
  );

  const matchInventoryAndRenderInventoryCards = useCallback(
    (selectedProjectInventory) => {
      const matchingInventory = dataInventory?.available_inventory?.find(
        (inventory) => inventory.id === selectedProjectInventory.inventory_id,
      );
      if (matchingInventory) {
        return (
          <ProjectInventoryCard
            inventory={matchingInventory}
            assigned_weight={selectedProjectInventory.assigned_weight}
            update_assigned_weight={updateAssignedWeight}
            remove_allocation={removeSelectedInventory}
            key={matchingInventory.id}
          />
        );
      }
      return null;
    },
    [
      dataInventory?.available_inventory,
      removeSelectedInventory,
      updateAssignedWeight,
    ],
  );

  const renderRemovedInventoryWarning = useMemo(() => {
    const removedProjectsBulletPoints = invalidProjects.map(
      (invalidProject) => (
        <li>
          {invalidProject.project_name}: {invalidProject.assigned_weight} t
        </li>
      ),
    );
    return (
      <div>
        <FormattedMessage id="portfolio.edit.the-following-projects" />
        <ul>{removedProjectsBulletPoints}</ul>
      </div>
    );
  }, [invalidProjects]);

  const summaryFields = calculatePortfolioDraftSummaryFields(portfolio);

  return (
    <div className="PortfolioEditAllocations">
      <Heading level="h5">Project allocation</Heading>
      <Spin spinning={loadingInventory}>
        {invalidProjects.length > 0 && (
          <BannerMessage
            variant="critical"
            header={formatMessage({
              id: 'portfolio.edit.inventory-not-available',
            })}
          >
            <Text>{renderRemovedInventoryWarning}</Text>
          </BannerMessage>
        )}
        {selectedProjectInventories.map((selectedProjectInventory) =>
          matchInventoryAndRenderInventoryCards(selectedProjectInventory),
        )}
        {!selectedProjectInventories.length || addNewProject ? (
          <ComboBoxSearch
            size={Size.Loose}
            isLoading={loadingInventory}
            onSelect={onInventorySelect}
            items={
              availableInventoryDropdownOptions
                ? availableInventoryDropdownOptions.map((item) => ({
                    value: `${item.project.name} - ${item.vintage}`,
                    id: item.id,
                  }))
                : []
            }
            placeholder={formatMessage({
              id: 'portfolio.edit.allocations.add-new-project',
            })}
            label={<FormattedMessage id="portfolio.edit.add-projects" />}
          />
        ) : (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions -- eslint onboarding
          <div
            onClick={() => setAddNewProject(true)}
            className="PortfolioEditAllocations__add-another"
          >
            <FormattedMessage id="portfolio.edit.add-another-project" />
          </div>
        )}
        <Divider className="PortfolioEditAllocations__inventory-summary-divider" />
        <Heading level="h5">
          <FormattedMessage id="portfolio.edit.summary" />
        </Heading>
        <div className="PortfolioEditAllocations__price-summary">
          <div className="PortfolioEditAllocations__price-summary__distribution">
            <PortfolioDistribution
              removal={summaryFields.removal}
              avoidance={summaryFields.avoidance}
              hideDescription={true}
            />
          </div>
          <Divider />
          <div className="PortfolioEditAllocations__price-summary__breakdown">
            <PortfolioSummaryBreakdown
              currency={CurrencyEnum.Gbp}
              pricePerTonne={summaryFields.pricePerTonne}
              allocatedTonnes={summaryFields.allocatedTonnes}
              totalProjects={portfolio.portfolio_draft.allocations?.length ?? 0}
            />
          </div>
        </div>
      </Spin>
    </div>
  );
}
