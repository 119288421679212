import { all } from 'redux-saga/effects';

import { rootSaga as user } from './user/sagas';
import { rootSaga as menu } from './menu/sagas';
// eslint-disable-next-line import/no-cycle -- eslint onboarding
import { rootSaga as settings } from './settings/sagas';

export function* rootSaga() {
  yield all([user(), menu(), settings()]);
}
