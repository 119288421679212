import store from 'store';
import axios from 'axios';
import { message } from '@pledge-earth/web-components';

export async function getUser() {
  if (store.get('accessToken')) {
    return axios({
      url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
      method: 'post',
      data: {
        query: `
                query getMe {
                  user {
                    sub
                    username
                    email_verified
                    name
                    email
                    }
                  }
                `,
      },
      headers: {
        'Content-Type': 'application/json',

        Authorization: `Bearer ${store.get('accessToken')}`,
      },
    })
      .then((result) => result.data.data)
      .catch((err) => {
        if (err.response?.status !== 401) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- eslint onboarding
          message.error(err.response?.data?.message);
          throw err;
        }
      });
  }

  return undefined;
}
