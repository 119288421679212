import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTimeISO: { input: any; output: any };
  Upload: { input: any; output: any };
};

export enum AccountStateEnum {
  Authorized = 'AUTHORIZED',
  CreateProfile = 'CREATE_PROFILE',
  Invited = 'INVITED',
  Provisioned = 'PROVISIONED',
  Registered = 'REGISTERED',
  Unauthorized = 'UNAUTHORIZED',
}

export type AddInventoryInput = {
  currency: Scalars['String']['input'];
  price_per_tonne: Scalars['Float']['input'];
  price_per_tonne_in_gbp?: InputMaybe<Scalars['Float']['input']>;
  project_id: Scalars['String']['input'];
  registry?: InputMaybe<Scalars['String']['input']>;
  registry_id?: InputMaybe<Scalars['String']['input']>;
  rrp_max?: InputMaybe<Scalars['Float']['input']>;
  rrp_min?: InputMaybe<Scalars['Float']['input']>;
  vintage: Scalars['String']['input'];
  weight_tonnes: Scalars['Float']['input'];
};

export type ApiKey = {
  __typename?: 'ApiKey';
  api_key: Scalars['String']['output'];
  created_date: Scalars['DateTimeISO']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ApiLog = {
  __typename?: 'ApiLog';
  api_key: Scalars['String']['output'];
  date: Scalars['DateTimeISO']['output'];
  endpoint: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  request: Scalars['String']['output'];
  response: Scalars['String']['output'];
  response_code: Scalars['Float']['output'];
};

export type CarbonplanAssessment = {
  __typename?: 'CarbonplanAssessment';
  created_date: Scalars['DateTimeISO']['output'];
  data_stringify?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  updated_date: Scalars['DateTimeISO']['output'];
};

export type Client = {
  __typename?: 'Client';
  address_1?: Maybe<Scalars['String']['output']>;
  address_2?: Maybe<Scalars['String']['output']>;
  billing_email_address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  client_function?: Maybe<ClientFunctionEnum>;
  country?: Maybe<Country>;
  currency?: Maybe<CurrencyEnum>;
  domains: Array<ClientDomain>;
  entitlements: Array<ClientSubscriptionEntitlement>;
  id: Scalars['ID']['output'];
  legal_name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  public_id: Scalars['String']['output'];
  region?: Maybe<Scalars['String']['output']>;
  tax_id_type?: Maybe<TaxIdTypeEnum>;
  tax_id_value?: Maybe<Scalars['String']['output']>;
  users: Array<ClientUser>;
  website?: Maybe<Scalars['String']['output']>;
  zip_code?: Maybe<Scalars['String']['output']>;
};

export type ClientDomain = {
  __typename?: 'ClientDomain';
  client: Client;
  created_date: Scalars['DateTimeISO']['output'];
  domain: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updated_date: Scalars['DateTimeISO']['output'];
};

export enum ClientFunctionEnum {
  Carrier = 'CARRIER',
  FreightForwarder = 'FREIGHT_FORWARDER',
  IndependentSoftwareVendor = 'INDEPENDENT_SOFTWARE_VENDOR',
  Lsp = 'LSP',
  Other = 'OTHER',
  Shipper = 'SHIPPER',
}

export type ClientIntegration = {
  __typename?: 'ClientIntegration';
  configuration_stringified?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  public_id: Scalars['ID']['output'];
  secondary_system?: Maybe<ClientIntegrationSecondarySystemEnum>;
  status: ClientIntegrationStatusEnum;
  status_update_date: Scalars['DateTimeISO']['output'];
  status_update_requested_by: ClientUser;
  system: ClientIntegrationSystemEnum;
  test: Scalars['Boolean']['output'];
  type?: Maybe<ClientIntegrationConfigurationTypeEnum>;
};

export enum ClientIntegrationConfigurationTypeEnum {
  HttpApi = 'HTTP_API',
}

export type ClientIntegrationInput = {
  configuration_stringified?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  secondary_system?: InputMaybe<ClientIntegrationSecondarySystemEnum>;
  status: ClientIntegrationStatusEnum;
  status_update_requested_by_client_user_id: Scalars['ID']['input'];
  system: ClientIntegrationSystemEnum;
  test: Scalars['Boolean']['input'];
  type?: InputMaybe<ClientIntegrationConfigurationTypeEnum>;
};

export enum ClientIntegrationSecondarySystemEnum {
  ChainioCargowise = 'CHAINIO_CARGOWISE',
  ChainioDescartes = 'CHAINIO_DESCARTES',
  ChainioFlexport = 'CHAINIO_FLEXPORT',
  ChainioMagaya = 'CHAINIO_MAGAYA',
  ChainioOther = 'CHAINIO_OTHER',
}

export enum ClientIntegrationStatusEnum {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
}

export enum ClientIntegrationSystemEnum {
  Cargowise = 'CARGOWISE',
  CargowiseProxy = 'CARGOWISE_PROXY',
  Chainio = 'CHAINIO',
  EmailImport = 'EMAIL_IMPORT',
  IleapHost = 'ILEAP_HOST',
  ServerToServerFileExchange = 'SERVER_TO_SERVER_FILE_EXCHANGE',
}

export type ClientRole = {
  __typename?: 'ClientRole';
  id: Scalars['ID']['output'];
  name: ClientRoleEnum;
};

export enum ClientRoleEnum {
  Admin = 'ADMIN',
  Developer = 'DEVELOPER',
  Operations = 'OPERATIONS',
  Owner = 'OWNER',
  Viewer = 'VIEWER',
}

export type ClientSubscriptionEntitlement = {
  __typename?: 'ClientSubscriptionEntitlement';
  feature_id: Scalars['String']['output'];
  feature_type: Scalars['String']['output'];
  is_enabled: Scalars['Boolean']['output'];
  value: Scalars['String']['output'];
};

export type ClientUser = {
  __typename?: 'ClientUser';
  account_state: AccountStateEnum;
  created_date: Scalars['DateTimeISO']['output'];
  email: Scalars['String']['output'];
  first_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  last_name?: Maybe<Scalars['String']['output']>;
  role: ClientRole;
  updated_date: Scalars['DateTimeISO']['output'];
};

export type ConfirmRetirementInput = {
  retirement_id: Scalars['String']['input'];
  retirement_url: Scalars['String']['input'];
  serial_numbers: Scalars['String']['input'];
};

export type Country = {
  __typename?: 'Country';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CountryInput = {
  code: Scalars['String']['input'];
};

export type CreateClientInput = {
  address_1?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  billing_email_address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country: CountryInput;
  currency?: InputMaybe<CurrencyEnum>;
  domain: Scalars['String']['input'];
  legal_name: Scalars['String']['input'];
  owner_email: Scalars['String']['input'];
  owner_first_name: Scalars['String']['input'];
  owner_last_name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  tax_id_type: TaxIdTypeEnum;
  tax_id_value: Scalars['String']['input'];
  website?: InputMaybe<Scalars['String']['input']>;
  zip_code?: InputMaybe<Scalars['String']['input']>;
};

export enum CurrencyEnum {
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD',
}

export enum DataCollectionRequestEventEnum {
  Accepted = 'ACCEPTED',
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  DataStaged = 'DATA_STAGED',
  Declined = 'DECLINED',
  Rejected = 'REJECTED',
  Requested = 'REQUESTED',
  Submitted = 'SUBMITTED',
}

export type FilterValues = {
  __typename?: 'FilterValues';
  count: Scalars['Float']['output'];
  group_value?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

export type Geography = {
  __typename?: 'Geography';
  coordinates: Array<Scalars['Float']['output']>;
  type: Scalars['String']['output'];
};

export type GetApiLogs = {
  __typename?: 'GetApiLogs';
  items: Array<ApiLog>;
  last_evaluated_key?: Maybe<Scalars['String']['output']>;
};

export type GetClients = {
  __typename?: 'GetClients';
  count: Scalars['Int']['output'];
  items: Array<Client>;
};

export type GetInventory = {
  __typename?: 'GetInventory';
  items: Array<Inventory>;
  total_count: Scalars['Int']['output'];
};

export type GetInventoryEvents = {
  __typename?: 'GetInventoryEvents';
  items: Array<InventoryEvent>;
  total_count: Scalars['Int']['output'];
};

export type GetOrders = {
  __typename?: 'GetOrders';
  items: Array<Order>;
  pagination_limit: Scalars['Int']['output'];
  total_co2e: Scalars['Float']['output'];
  total_count: Scalars['Int']['output'];
};

export type GetOrdersCo2ERange = {
  __typename?: 'GetOrdersCo2ERange';
  histogram: Array<HistogramBucket>;
  max?: Maybe<Scalars['Float']['output']>;
  min?: Maybe<Scalars['Float']['output']>;
};

export type GetPortfolio = {
  __typename?: 'GetPortfolio';
  keyFacts?: Maybe<PortfolioKeyFact>;
  portfolio: Portfolio;
  projects: Array<Project>;
};

export type GetPortfolios = {
  __typename?: 'GetPortfolios';
  items: Array<PortfolioDataMapper>;
  total_count: Scalars['Int']['output'];
};

export type GetProjects = {
  __typename?: 'GetProjects';
  items: Array<Project>;
  total_count: Scalars['Int']['output'];
};

export type GetRetirementsConfirmed = {
  __typename?: 'GetRetirementsConfirmed';
  items: Array<RetirementConfirmed>;
  total_count: Scalars['Int']['output'];
};

export type HistogramBucket = {
  __typename?: 'HistogramBucket';
  doc_count: Scalars['Float']['output'];
  key: Scalars['Float']['output'];
};

export type Inventory = {
  __typename?: 'Inventory';
  allocated_weight_kg: Scalars['Float']['output'];
  currency: CurrencyEnum;
  id: Scalars['ID']['output'];
  price_per_kg: Scalars['Float']['output'];
  price_per_kg_in_gbp: Scalars['Float']['output'];
  project: Project;
  project_id: Scalars['String']['output'];
  registry: Registry;
  registry_id: Scalars['String']['output'];
  retired_weight_kg: Scalars['Float']['output'];
  vintage: Scalars['String']['output'];
  weight_kg: Scalars['Float']['output'];
};

export type InventoryEvent = {
  __typename?: 'InventoryEvent';
  allocated_weight_kg?: Maybe<Scalars['Float']['output']>;
  created_date: Scalars['DateTimeISO']['output'];
  currency?: Maybe<CurrencyEnum>;
  id: Scalars['ID']['output'];
  meta_data?: Maybe<Array<Metadata>>;
  notes?: Maybe<Scalars['String']['output']>;
  pledge_meta_data?: Maybe<Array<Metadata>>;
  portfolio?: Maybe<Portfolio>;
  portfolio_id?: Maybe<Scalars['String']['output']>;
  price_per_kg?: Maybe<Scalars['Float']['output']>;
  price_per_kg_in_gbp?: Maybe<Scalars['Float']['output']>;
  project?: Maybe<Project>;
  project_id?: Maybe<Scalars['String']['output']>;
  projects?: Maybe<Array<ProjectsAllocatedList>>;
  reason?: Maybe<Scalars['String']['output']>;
  registry?: Maybe<Registry>;
  registry_id?: Maybe<Scalars['String']['output']>;
  retired_weight_kg?: Maybe<Scalars['Float']['output']>;
  rrp_max?: Maybe<Scalars['Float']['output']>;
  rrp_min?: Maybe<Scalars['Float']['output']>;
  source: Scalars['String']['output'];
  type: InventoryEventTypeEnum;
  vintage?: Maybe<Scalars['String']['output']>;
  weight_kg?: Maybe<Scalars['Float']['output']>;
};

export enum InventoryEventTypeEnum {
  InventoryAdded = 'INVENTORY_ADDED',
  InventoryAllocated = 'INVENTORY_ALLOCATED',
  InventoryRemoved = 'INVENTORY_REMOVED',
  InventoryRetired = 'INVENTORY_RETIRED',
}

export enum InventoryEventsFilterPropertiesEnum {
  EventTypes = 'EVENT_TYPES',
  EventVintages = 'EVENT_VINTAGES',
  ProjectPublicIds = 'PROJECT_PUBLIC_IDS',
}

export enum InventoryEventsSortedColumnEnum {
  EventCreatedDate = 'EVENT_CREATED_DATE',
  EventType = 'EVENT_TYPE',
  EventVintage = 'EVENT_VINTAGE',
}

export enum InventoryFilterPropertiesEnum {
  EventVintages = 'EVENT_VINTAGES',
  ProjectPublicIds = 'PROJECT_PUBLIC_IDS',
  RegistryPublicIds = 'REGISTRY_PUBLIC_IDS',
}

export type InventoryReconciliationResults = {
  __typename?: 'InventoryReconciliationResults';
  created_date: Scalars['DateTimeISO']['output'];
  error_count: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  recon_external_inventory_file_url?: Maybe<Scalars['String']['output']>;
  recon_internal_inventory_file_url?: Maybe<Scalars['String']['output']>;
  recon_results_summary_file_url?: Maybe<Scalars['String']['output']>;
  registry: Registry;
};

export enum InventorySortedColumnEnum {
  ProjectName = 'PROJECT_NAME',
  ProjectVintage = 'PROJECT_VINTAGE',
  RetiredWeight = 'RETIRED_WEIGHT',
  TotalWeight = 'TOTAL_WEIGHT',
  UnallocatedWeight = 'UNALLOCATED_WEIGHT',
}

export type LinkImportToRequestInput = {
  activityClientUserId: Scalars['String']['input'];
  activityNote?: InputMaybe<Scalars['String']['input']>;
  dataCollectionRequestPublicId: Scalars['String']['input'];
  importPublicId: Scalars['String']['input'];
};

export type LocationInput = {
  coordinates: Array<Scalars['Float']['input']>;
};

export type Metadata = {
  __typename?: 'Metadata';
  key: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  add_inventory: Scalars['Boolean']['output'];
  confirm_retirement: Scalars['Boolean']['output'];
  create_client: Client;
  create_portfolio: Scalars['String']['output'];
  delete_client_user: Scalars['String']['output'];
  link_import_to_request: Scalars['Boolean']['output'];
  migrate_clients_to_chargebee: Scalars['Boolean']['output'];
  publish_portfolio: Scalars['Boolean']['output'];
  reconcile_inventory: Scalars['Boolean']['output'];
  remove_inventory: Scalars['Boolean']['output'];
  roll_api_key: ApiKey;
  sync_portfolios_in_chargebee: Scalars['Boolean']['output'];
  transfer_ownership: Scalars['String']['output'];
  transition_data_collection_request: Scalars['Boolean']['output'];
  trigger_netlify_deploy: Scalars['Boolean']['output'];
  unpublish_portfolio: Scalars['Boolean']['output'];
  update_client: Client;
  upload_file: S3Object;
  upsert_client_integration: ClientIntegration;
  upsert_portfolio_draft: PortfolioDraft;
  upsert_project: Project;
  upsert_project_certification: ProjectCertification;
  upsert_project_developer: ProjectDeveloper;
  upsert_project_owner: ProjectOwner;
  upsert_project_type: ProjectType;
  upsert_registry: Registry;
};

export type MutationAdd_InventoryArgs = {
  data: AddInventoryInput;
};

export type MutationConfirm_RetirementArgs = {
  data: ConfirmRetirementInput;
};

export type MutationCreate_ClientArgs = {
  data: CreateClientInput;
};

export type MutationCreate_PortfolioArgs = {
  data: PortfolioDraftInput;
};

export type MutationDelete_Client_UserArgs = {
  id: Scalars['String']['input'];
};

export type MutationLink_Import_To_RequestArgs = {
  data: LinkImportToRequestInput;
};

export type MutationPublish_PortfolioArgs = {
  id: Scalars['String']['input'];
};

export type MutationRemove_InventoryArgs = {
  data: RemoveInventoryInput;
};

export type MutationRoll_Api_KeyArgs = {
  client_id: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type MutationTransfer_OwnershipArgs = {
  new_owner_client_user_id: Scalars['String']['input'];
};

export type MutationTransition_Data_Collection_RequestArgs = {
  data: TransitionDataCollectionRequestInput;
};

export type MutationUnpublish_PortfolioArgs = {
  id: Scalars['String']['input'];
};

export type MutationUpdate_ClientArgs = {
  data: UpdateClientInput;
  id: Scalars['String']['input'];
};

export type MutationUpload_FileArgs = {
  file: Scalars['Upload']['input'];
  folder: S3FolderEnum;
};

export type MutationUpsert_Client_IntegrationArgs = {
  data: ClientIntegrationInput;
};

export type MutationUpsert_Portfolio_DraftArgs = {
  data: PortfolioDraftInput;
};

export type MutationUpsert_ProjectArgs = {
  data: UpsertProjectInput;
};

export type MutationUpsert_Project_CertificationArgs = {
  data: UpsertProjectCertificationInput;
};

export type MutationUpsert_Project_DeveloperArgs = {
  data: UpsertDeveloperInput;
};

export type MutationUpsert_Project_OwnerArgs = {
  data: ProjectOwnerInput;
};

export type MutationUpsert_Project_TypeArgs = {
  data: ProjectTypeInput;
};

export type MutationUpsert_RegistryArgs = {
  data: RegistryInput;
};

export type Order = {
  __typename?: 'Order';
  amount: Scalars['Float']['output'];
  client: Client;
  client_user?: Maybe<ClientUser>;
  created_date: Scalars['DateTimeISO']['output'];
  currency: Scalars['String']['output'];
  portfolio: Portfolio;
  public_id: Scalars['String']['output'];
  status: OrderStatusEnum;
  updated_date: Scalars['DateTimeISO']['output'];
  weight_kg: Scalars['Float']['output'];
};

export enum OrderDirectionEnum {
  Ascend = 'ascend',
  Descend = 'descend',
}

export enum OrderFilterPropertiesEnum {
  ClientLegalName = 'CLIENT_LEGAL_NAME',
  ClientPublicId = 'CLIENT_PUBLIC_ID',
  Co2E = 'CO2E',
  InvoiceNumber = 'INVOICE_NUMBER',
  PortfolioName = 'PORTFOLIO_NAME',
  PortfolioPublicId = 'PORTFOLIO_PUBLIC_ID',
  PublicId = 'PUBLIC_ID',
  Status = 'STATUS',
}

export enum OrderSortedColumnEnum {
  Amount = 'AMOUNT',
  CreatedDate = 'CREATED_DATE',
  WeightKg = 'WEIGHT_KG',
}

export enum OrderStatusEnum {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  Invoiced = 'INVOICED',
  Paid = 'PAID',
  Placed = 'PLACED',
  Processing = 'PROCESSING',
  Quoted = 'QUOTED',
  Rejected = 'REJECTED',
}

export type Portfolio = {
  __typename?: 'Portfolio';
  allocated_weight_kg: Scalars['Float']['output'];
  allocations: Array<PortfolioAllocation>;
  clients?: Maybe<Array<Client>>;
  created_date: Scalars['DateTimeISO']['output'];
  description: Scalars['String']['output'];
  display_name: Scalars['String']['output'];
  distribution?: Maybe<PortfolioDistribution>;
  id: Scalars['String']['output'];
  is_published: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  portfolio_version?: Maybe<Scalars['String']['output']>;
  prices: Array<PortfolioPrice>;
  public_id: Scalars['String']['output'];
  reserved_weight_kg: Scalars['Float']['output'];
  updated_date: Scalars['DateTimeISO']['output'];
};

export type PortfolioAllocation = {
  __typename?: 'PortfolioAllocation';
  created_date: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  percentage: Scalars['Float']['output'];
  portfolio: Portfolio;
  project: Project;
  updated_date: Scalars['DateTimeISO']['output'];
};

export type PortfolioDataMapper = {
  __typename?: 'PortfolioDataMapper';
  allocated_weight_kg?: Maybe<Scalars['Float']['output']>;
  created_date: Scalars['DateTimeISO']['output'];
  display_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  is_draft?: Maybe<Scalars['Boolean']['output']>;
  is_published?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  portfolio_version?: Maybe<Scalars['String']['output']>;
  prices?: Maybe<Array<PortfolioPrice>>;
  public_id?: Maybe<Scalars['String']['output']>;
  reserved_weight_kg?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<PortfolioStatusEnum>;
};

export type PortfolioDetailsAllocationsInputType = {
  avoidance: Scalars['Boolean']['input'];
  price_per_kg_in_gbp: Scalars['Float']['input'];
  project_id: Scalars['String']['input'];
  project_name: Scalars['String']['input'];
  registry: Scalars['String']['input'];
  registry_fee_per_tonne: Scalars['Float']['input'];
  registry_id: Scalars['String']['input'];
  removal: Scalars['Boolean']['input'];
  vintage: Scalars['String']['input'];
  weight_tonnes: Scalars['Float']['input'];
};

export type PortfolioDetailsAllocationsType = {
  __typename?: 'PortfolioDetailsAllocationsType';
  avoidance: Scalars['Boolean']['output'];
  price_per_kg_in_gbp: Scalars['Float']['output'];
  project_id: Scalars['String']['output'];
  project_name: Scalars['String']['output'];
  registry: Scalars['String']['output'];
  registry_fee_per_tonne: Scalars['Float']['output'];
  registry_id: Scalars['String']['output'];
  removal: Scalars['Boolean']['output'];
  vintage: Scalars['String']['output'];
  weight_tonnes: Scalars['Float']['output'];
};

export type PortfolioDistribution = {
  __typename?: 'PortfolioDistribution';
  avoidance: Scalars['Float']['output'];
  removal: Scalars['Float']['output'];
  totalAllocated: Scalars['Float']['output'];
};

export type PortfolioDraft = {
  __typename?: 'PortfolioDraft';
  created_date: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  portfolio_draft: PortfolioDraftJsonType;
  updated_date: Scalars['DateTimeISO']['output'];
};

export type PortfolioDraftDetailsInputType = {
  accounting_code?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<CurrencyEnum>;
  description?: InputMaybe<Scalars['String']['input']>;
  key_facts?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  portfolio_version?: InputMaybe<Scalars['String']['input']>;
};

export type PortfolioDraftDetailsType = {
  __typename?: 'PortfolioDraftDetailsType';
  accounting_code?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<CurrencyEnum>;
  description?: Maybe<Scalars['String']['output']>;
  key_facts?: Maybe<Array<Scalars['String']['output']>>;
  name?: Maybe<Scalars['String']['output']>;
  portfolio_version?: Maybe<Scalars['String']['output']>;
};

export type PortfolioDraftInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  portfolio_draft: PortfolioDraftJsonInputType;
};

export type PortfolioDraftJsonInputType = {
  acl?: InputMaybe<Array<Scalars['String']['input']>>;
  allocations?: InputMaybe<Array<PortfolioDetailsAllocationsInputType>>;
  details?: InputMaybe<PortfolioDraftDetailsInputType>;
};

export type PortfolioDraftJsonType = {
  __typename?: 'PortfolioDraftJsonType';
  acl?: Maybe<Array<Scalars['String']['output']>>;
  allocations?: Maybe<Array<PortfolioDetailsAllocationsType>>;
  details?: Maybe<PortfolioDraftDetailsType>;
};

export type PortfolioKeyFact = {
  __typename?: 'PortfolioKeyFact';
  id: Scalars['String']['output'];
  key_facts: Array<Scalars['String']['output']>;
};

export enum PortfolioOrderColumnEnum {
  CreatedDate = 'CREATED_DATE',
  Name = 'NAME',
}

export type PortfolioPrice = {
  __typename?: 'PortfolioPrice';
  currency: Scalars['String']['output'];
  price_per_kg: Scalars['Float']['output'];
};

export type PortfolioStats = {
  __typename?: 'PortfolioStats';
  total_orders: Scalars['Float']['output'];
};

export enum PortfolioStatusEnum {
  Confirmed = 'CONFIRMED',
  Created = 'CREATED',
}

export type Project = {
  __typename?: 'Project';
  avoidance: Scalars['Boolean']['output'];
  carbonplan_assessment?: Maybe<CarbonplanAssessment>;
  certifications?: Maybe<Array<ProjectCertification>>;
  contact?: Maybe<Scalars['String']['output']>;
  coordinates?: Maybe<Geography>;
  country: Country;
  created_date: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  developer?: Maybe<ProjectDeveloper>;
  id: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
  media?: Maybe<Array<S3Object>>;
  name: Scalars['String']['output'];
  owner?: Maybe<ProjectOwner>;
  project_type: ProjectType;
  public_id: Scalars['String']['output'];
  registry?: Maybe<Registry>;
  registry_project_id?: Maybe<Scalars['String']['output']>;
  registry_project_url?: Maybe<Scalars['String']['output']>;
  removal: Scalars['Boolean']['output'];
  sdgs?: Maybe<Array<SustainableDevelopmentGoalsEnum>>;
  updated_date: Scalars['DateTimeISO']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export type ProjectCertification = {
  __typename?: 'ProjectCertification';
  created_date: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  media?: Maybe<Array<S3Object>>;
  name: Scalars['String']['output'];
  registry_link: Scalars['String']['output'];
  updated_date: Scalars['DateTimeISO']['output'];
};

export type ProjectDeveloper = {
  __typename?: 'ProjectDeveloper';
  created_date: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  media?: Maybe<Array<S3Object>>;
  name: Scalars['String']['output'];
  projects: Array<Project>;
  public_id: Scalars['String']['output'];
  updated_date: Scalars['DateTimeISO']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export enum ProjectFilterPropertiesEnum {
  ProjectPublicIds = 'PROJECT_PUBLIC_IDS',
}

export type ProjectOwner = {
  __typename?: 'ProjectOwner';
  created_date: Scalars['DateTimeISO']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updated_date: Scalars['DateTimeISO']['output'];
};

export type ProjectOwnerInput = {
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export enum ProjectSortedColumnEnum {
  Name = 'NAME',
}

export type ProjectType = {
  __typename?: 'ProjectType';
  category: Scalars['String']['output'];
  created_date: Scalars['DateTimeISO']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updated_date: Scalars['DateTimeISO']['output'];
};

export type ProjectTypeInput = {
  category: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type ProjectsAllocatedList = {
  __typename?: 'ProjectsAllocatedList';
  project_id: Scalars['String']['output'];
  registry: Scalars['String']['output'];
  registry_id: Scalars['String']['output'];
  vintage: Scalars['String']['output'];
  weight_kg: Scalars['Float']['output'];
};

export type Query = {
  __typename?: 'Query';
  api_keys: Array<ApiKey>;
  api_logs: GetApiLogs;
  available_inventory: Array<Inventory>;
  client: Client;
  client_integrations: Array<ClientIntegration>;
  clients: GetClients;
  countries: Array<Country>;
  inventory: GetInventory;
  inventoryReconciliationResults: Array<InventoryReconciliationResults>;
  inventory_event: InventoryEvent;
  inventory_events: GetInventoryEvents;
  inventory_events_property_values: Array<FilterValues>;
  inventory_property_values: Array<FilterValues>;
  order_co2e_range: GetOrdersCo2ERange;
  order_property_values: Array<FilterValues>;
  orders: GetOrders;
  portfolio: GetPortfolio;
  portfolio_draft: PortfolioDraft;
  portfolio_stats: PortfolioStats;
  portfolios: GetPortfolios;
  project: Project;
  project_certification: ProjectCertification;
  project_certifications: Array<ProjectCertification>;
  project_developer: ProjectDeveloper;
  project_developers: Array<ProjectDeveloper>;
  project_owner: ProjectOwner;
  project_owners: Array<ProjectOwner>;
  project_property_values: Array<FilterValues>;
  project_type: ProjectType;
  project_type_categories: Array<ProjectType>;
  project_types: Array<ProjectType>;
  projects: GetProjects;
  registries: Array<Registry>;
  registry: Registry;
  retirements_confirmed: GetRetirementsConfirmed;
  retirements_requested: Array<RetirementRequested>;
  user: User;
};

export type QueryApi_KeysArgs = {
  client_id: Scalars['String']['input'];
};

export type QueryApi_LogsArgs = {
  client_id: Scalars['String']['input'];
  descend?: InputMaybe<Scalars['Boolean']['input']>;
  exclusive_start_key?: InputMaybe<Scalars['String']['input']>;
};

export type QueryClientArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
};

export type QueryClient_IntegrationsArgs = {
  client_id: Scalars['String']['input'];
};

export type QueryClientsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryInventoryArgs = {
  page: Scalars['Int']['input'];
  project_public_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  registry_public_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  size: Scalars['Int']['input'];
  sorted_column?: InputMaybe<InventorySortedColumnEnum>;
  sorted_order?: InputMaybe<SortedOrderEnum>;
  vintages?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryInventory_EventArgs = {
  id: Scalars['String']['input'];
};

export type QueryInventory_EventsArgs = {
  event_types?: InputMaybe<Array<InventoryEventTypeEnum>>;
  page: Scalars['Int']['input'];
  project_public_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  size: Scalars['Int']['input'];
  sorted_column?: InputMaybe<InventoryEventsSortedColumnEnum>;
  sorted_order?: InputMaybe<SortedOrderEnum>;
  vintages?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryInventory_Events_Property_ValuesArgs = {
  key: InventoryEventsFilterPropertiesEnum;
  project_public_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  value: Array<Scalars['String']['input']>;
};

export type QueryInventory_Property_ValuesArgs = {
  key: InventoryFilterPropertiesEnum;
  value: Array<Scalars['String']['input']>;
};

export type QueryOrder_Property_ValuesArgs = {
  key: OrderFilterPropertiesEnum;
  value: Scalars['String']['input'];
};

export type QueryOrdersArgs = {
  client_legal_names?: InputMaybe<Array<Scalars['String']['input']>>;
  client_public_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  co2e?: InputMaybe<RangeInput>;
  invoice_numbers?: InputMaybe<Array<Scalars['String']['input']>>;
  page: Scalars['Int']['input'];
  portfolio_names?: InputMaybe<Array<Scalars['String']['input']>>;
  portfolio_public_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  public_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  size: Scalars['Int']['input'];
  sorted_column?: InputMaybe<OrderSortedColumnEnum>;
  sorted_order?: InputMaybe<SortedOrderEnum>;
  status?: InputMaybe<Array<OrderStatusEnum>>;
};

export type QueryPortfolioArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPortfolio_DraftArgs = {
  id: Scalars['String']['input'];
};

export type QueryPortfolio_StatsArgs = {
  id: Scalars['String']['input'];
};

export type QueryPortfoliosArgs = {
  order_column?: InputMaybe<PortfolioOrderColumnEnum>;
  order_direction?: InputMaybe<OrderDirectionEnum>;
  page?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryProjectArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
};

export type QueryProject_CertificationArgs = {
  id: Scalars['String']['input'];
};

export type QueryProject_DeveloperArgs = {
  public_id: Scalars['String']['input'];
};

export type QueryProject_OwnerArgs = {
  id: Scalars['String']['input'];
};

export type QueryProject_Property_ValuesArgs = {
  key: ProjectFilterPropertiesEnum;
  value: Array<Scalars['String']['input']>;
};

export type QueryProject_TypeArgs = {
  id: Scalars['String']['input'];
};

export type QueryProjectsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  project_name?: InputMaybe<Scalars['String']['input']>;
  project_public_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  project_types?: InputMaybe<Array<Scalars['ID']['input']>>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sorted_column?: InputMaybe<ProjectSortedColumnEnum>;
  sorted_order?: InputMaybe<SortedOrderEnum>;
};

export type QueryRegistryArgs = {
  public_id: Scalars['String']['input'];
};

export type QueryRetirements_ConfirmedArgs = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  page: Scalars['Int']['input'];
  project_names?: InputMaybe<Array<Scalars['String']['input']>>;
  project_public_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  registry_names?: InputMaybe<Array<Scalars['String']['input']>>;
  registry_public_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  size: Scalars['Int']['input'];
  sorted_column?: InputMaybe<RetirementSortedColumnEnum>;
  sorted_order?: InputMaybe<SortedOrderEnum>;
};

export type RangeInput = {
  max: Scalars['Float']['input'];
  min: Scalars['Float']['input'];
};

export type Registry = {
  __typename?: 'Registry';
  created_date: Scalars['DateTimeISO']['output'];
  has_automated_retirement?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  infrastructure?: Maybe<Scalars['String']['output']>;
  media?: Maybe<Array<S3Object>>;
  name: Scalars['String']['output'];
  name_short?: Maybe<Scalars['String']['output']>;
  projects_base_url?: Maybe<Scalars['String']['output']>;
  public_id: Scalars['String']['output'];
  registry_retirement_fee_per_tonne?: Maybe<Scalars['Float']['output']>;
  updated_date: Scalars['DateTimeISO']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

export type RegistryInput = {
  has_automated_retirement?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  infrastructure?: InputMaybe<Scalars['String']['input']>;
  media?: InputMaybe<Array<S3ObjectInput>>;
  name: Scalars['String']['input'];
  name_short?: InputMaybe<Scalars['String']['input']>;
  projects_base_url?: InputMaybe<Scalars['String']['input']>;
  registry_retirement_fee_per_tonne?: InputMaybe<Scalars['Float']['input']>;
  website: Scalars['String']['input'];
};

export type RemoveInventoryInput = {
  notes: Scalars['String']['input'];
  price_per_tonne?: InputMaybe<Scalars['Float']['input']>;
  price_per_tonne_in_gbp?: InputMaybe<Scalars['Float']['input']>;
  project_id: Scalars['String']['input'];
  reason: Scalars['String']['input'];
  registry?: InputMaybe<Scalars['String']['input']>;
  registry_id?: InputMaybe<Scalars['String']['input']>;
  vintage: Scalars['String']['input'];
  weight_tonnes: Scalars['Float']['input'];
};

export type RetirementConfirmed = {
  __typename?: 'RetirementConfirmed';
  created_date: Scalars['DateTimeISO']['output'];
  portfolio: Portfolio;
  project: Project;
  registry?: Maybe<Registry>;
  retirement_id: Scalars['ID']['output'];
  retirement_url: Scalars['String']['output'];
  serial_numbers: Array<Scalars['String']['output']>;
  vintage: Scalars['String']['output'];
  weight_kg: Scalars['Int']['output'];
};

export type RetirementRequested = {
  __typename?: 'RetirementRequested';
  created_date: Scalars['DateTimeISO']['output'];
  project: Project;
  registry?: Maybe<Registry>;
  retirement_id: Scalars['ID']['output'];
  vintage: Scalars['String']['output'];
  weight_kg: Scalars['Int']['output'];
};

export enum RetirementSortedColumnEnum {
  CreatedDate = 'CREATED_DATE',
  ProjectName = 'PROJECT_NAME',
  RegistryName = 'REGISTRY_NAME',
  Vintage = 'VINTAGE',
  WeightKg = 'WEIGHT_KG',
}

export enum S3FolderEnum {
  Project = 'PROJECT',
  ProjectCertification = 'PROJECT_CERTIFICATION',
  ProjectDeveloper = 'PROJECT_DEVELOPER',
  Provider = 'PROVIDER',
  Registry = 'REGISTRY',
}

export type S3Object = {
  __typename?: 'S3Object';
  bucket: Scalars['String']['output'];
  eTag?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
};

export type S3ObjectInput = {
  bucket: Scalars['String']['input'];
  eTag?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
};

export enum SortedOrderEnum {
  Ascend = 'ascend',
  Descend = 'descend',
}

export enum SustainableDevelopmentGoalsEnum {
  Ace = 'ACE',
  Ca = 'CA',
  Cws = 'CWS',
  Dweg = 'DWEG',
  Ge = 'GE',
  Ghw = 'GHW',
  Iii = 'III',
  Lbw = 'LBW',
  Ll = 'LL',
  Np = 'NP',
  Pg = 'PG',
  Pjsi = 'PJSI',
  Qe = 'QE',
  Rcp = 'RCP',
  Ri = 'RI',
  Scc = 'SCC',
  Zh = 'ZH',
}

export enum TaxIdTypeEnum {
  AeTrn = 'AE_TRN',
  AuAbn = 'AU_ABN',
  BrCnpj = 'BR_CNPJ',
  BrCpf = 'BR_CPF',
  CaBn = 'CA_BN',
  CaGstHst = 'CA_GST_HST',
  CaPstBc = 'CA_PST_BC',
  CaPstMb = 'CA_PST_MB',
  CaPstSk = 'CA_PST_SK',
  CaQst = 'CA_QST',
  ChVat = 'CH_VAT',
  ClTin = 'CL_TIN',
  EsCif = 'ES_CIF',
  EuVat = 'EU_VAT',
  GbVat = 'GB_VAT',
  HkBr = 'HK_BR',
  IdNpwp = 'ID_NPWP',
  IlVat = 'IL_VAT',
  InGst = 'IN_GST',
  JpCn = 'JP_CN',
  JpRn = 'JP_RN',
  KrBrn = 'KR_BRN',
  LiUid = 'LI_UID',
  MxRfc = 'MX_RFC',
  MyFrp = 'MY_FRP',
  MyItn = 'MY_ITN',
  MySst = 'MY_SST',
  NoVat = 'NO_VAT',
  NzGst = 'NZ_GST',
  RuInn = 'RU_INN',
  RuKpp = 'RU_KPP',
  SaVat = 'SA_VAT',
  SgGst = 'SG_GST',
  SgUen = 'SG_UEN',
  ThVat = 'TH_VAT',
  TwVat = 'TW_VAT',
  UsEin = 'US_EIN',
  ZaVat = 'ZA_VAT',
}

export type TransitionDataCollectionRequestInput = {
  activityClientUserId: Scalars['String']['input'];
  activityEvent: DataCollectionRequestEventEnum;
  activityNote?: InputMaybe<Scalars['String']['input']>;
  dataCollectionRequestPublicId: Scalars['String']['input'];
};

export type UpdateClientInput = {
  address_1?: InputMaybe<Scalars['String']['input']>;
  address_2?: InputMaybe<Scalars['String']['input']>;
  billing_email_address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  client_function?: InputMaybe<ClientFunctionEnum>;
  country: CountryInput;
  currency?: InputMaybe<CurrencyEnum>;
  legal_name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
  zip_code?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertDeveloperInput = {
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  logo?: InputMaybe<S3ObjectInput>;
  name: Scalars['String']['input'];
  website: Scalars['String']['input'];
};

export type UpsertProjectCertificationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  media?: InputMaybe<Array<S3ObjectInput>>;
  name: Scalars['String']['input'];
  registry_link: Scalars['String']['input'];
};

export type UpsertProjectInput = {
  avoidance: Scalars['Boolean']['input'];
  carbon_plan_id?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<Scalars['String']['input']>;
  coordinates?: InputMaybe<LocationInput>;
  country_id: Scalars['String']['input'];
  description: Scalars['String']['input'];
  developer_id: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  location: Scalars['String']['input'];
  media?: InputMaybe<Array<S3ObjectInput>>;
  name: Scalars['String']['input'];
  owner_id: Scalars['String']['input'];
  project_certification_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  project_type_id: Scalars['String']['input'];
  registry_id: Scalars['String']['input'];
  registry_project_id?: InputMaybe<Scalars['String']['input']>;
  registry_project_url?: InputMaybe<Scalars['String']['input']>;
  removal: Scalars['Boolean']['input'];
  sdgs?: InputMaybe<Array<SustainableDevelopmentGoalsEnum>>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  email_verified: Scalars['String']['output'];
  name: Scalars['String']['output'];
  sub: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type InventoryReconciliationResults_FromGetInventoryReconciliationResultsFragment =
  {
    __typename?: 'InventoryReconciliationResults';
    id: string;
    created_date: any;
    error_count: number;
    recon_results_summary_file_url?: string | null;
    recon_external_inventory_file_url?: string | null;
    recon_internal_inventory_file_url?: string | null;
  };

export type GetInventoryReconciliationResultsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetInventoryReconciliationResultsQuery = {
  __typename?: 'Query';
  inventoryReconciliationResults: Array<{
    __typename?: 'InventoryReconciliationResults';
    id: string;
    created_date: any;
    error_count: number;
    recon_results_summary_file_url?: string | null;
    recon_external_inventory_file_url?: string | null;
    recon_internal_inventory_file_url?: string | null;
  }>;
};

export type ApiKey_FromGetApiKeysFragment = {
  __typename?: 'ApiKey';
  id: string;
  name: string;
  api_key: string;
  created_date: any;
};

export type GetApiKeysQueryVariables = Exact<{
  client_id: Scalars['String']['input'];
}>;

export type GetApiKeysQuery = {
  __typename?: 'Query';
  api_keys: Array<{
    __typename?: 'ApiKey';
    id: string;
    name: string;
    api_key: string;
    created_date: any;
  }>;
};

export type ApiLog_FromGetApiLogsFragment = {
  __typename?: 'ApiLog';
  id: string;
  date: any;
  api_key: string;
  endpoint: string;
  response_code: number;
  request: string;
  response: string;
};

export type GetApiLogsQueryVariables = Exact<{
  descend?: InputMaybe<Scalars['Boolean']['input']>;
  exclusive_start_key?: InputMaybe<Scalars['String']['input']>;
  client_id: Scalars['String']['input'];
}>;

export type GetApiLogsQuery = {
  __typename?: 'Query';
  api_logs: {
    __typename?: 'GetApiLogs';
    last_evaluated_key?: string | null;
    items: Array<{
      __typename?: 'ApiLog';
      id: string;
      date: any;
      api_key: string;
      endpoint: string;
      response_code: number;
      request: string;
      response: string;
    }>;
  };
};

export type RollApiKeyMutationVariables = Exact<{
  client_id: Scalars['String']['input'];
  id: Scalars['String']['input'];
}>;

export type RollApiKeyMutation = {
  __typename?: 'Mutation';
  roll_api_key: { __typename?: 'ApiKey'; id: string };
};

export type Client_FromCreateClientFragment = {
  __typename?: 'Client';
  id: string;
};

export type CreateClientMutationVariables = Exact<{
  data: CreateClientInput;
}>;

export type CreateClientMutation = {
  __typename?: 'Mutation';
  create_client: { __typename?: 'Client'; id: string };
};

export type DeleteClientUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type DeleteClientUserMutation = {
  __typename?: 'Mutation';
  delete_client_user: string;
};

export type ClientSubscriptionEntitlement_FromGetClientFragment = {
  __typename?: 'ClientSubscriptionEntitlement';
  feature_id: string;
  feature_type: string;
  value: string;
  is_enabled: boolean;
};

export type ClientUser_FromGetClientFragment = {
  __typename?: 'ClientUser';
  id: string;
  first_name?: string | null;
  last_name?: string | null;
  email: string;
  account_state: AccountStateEnum;
  created_date: any;
  role: { __typename?: 'ClientRole'; name: ClientRoleEnum };
};

export type Client_FromGetClientFragment = {
  __typename?: 'Client';
  id: string;
  public_id: string;
  legal_name?: string | null;
  address_1?: string | null;
  address_2?: string | null;
  city?: string | null;
  zip_code?: string | null;
  region?: string | null;
  website?: string | null;
  phone?: string | null;
  billing_email_address?: string | null;
  currency?: CurrencyEnum | null;
  client_function?: ClientFunctionEnum | null;
  tax_id_type?: TaxIdTypeEnum | null;
  tax_id_value?: string | null;
  country?: { __typename?: 'Country'; code: string; name: string } | null;
  users: Array<{
    __typename?: 'ClientUser';
    id: string;
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    account_state: AccountStateEnum;
    created_date: any;
    role: { __typename?: 'ClientRole'; name: ClientRoleEnum };
  }>;
  domains: Array<{ __typename?: 'ClientDomain'; domain: string }>;
  entitlements: Array<{
    __typename?: 'ClientSubscriptionEntitlement';
    feature_id: string;
    feature_type: string;
    value: string;
    is_enabled: boolean;
  }>;
};

export type GetClientQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  publicId?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetClientQuery = {
  __typename?: 'Query';
  client: {
    __typename?: 'Client';
    id: string;
    public_id: string;
    legal_name?: string | null;
    address_1?: string | null;
    address_2?: string | null;
    city?: string | null;
    zip_code?: string | null;
    region?: string | null;
    website?: string | null;
    phone?: string | null;
    billing_email_address?: string | null;
    currency?: CurrencyEnum | null;
    client_function?: ClientFunctionEnum | null;
    tax_id_type?: TaxIdTypeEnum | null;
    tax_id_value?: string | null;
    country?: { __typename?: 'Country'; code: string; name: string } | null;
    users: Array<{
      __typename?: 'ClientUser';
      id: string;
      first_name?: string | null;
      last_name?: string | null;
      email: string;
      account_state: AccountStateEnum;
      created_date: any;
      role: { __typename?: 'ClientRole'; name: ClientRoleEnum };
    }>;
    domains: Array<{ __typename?: 'ClientDomain'; domain: string }>;
    entitlements: Array<{
      __typename?: 'ClientSubscriptionEntitlement';
      feature_id: string;
      feature_type: string;
      value: string;
      is_enabled: boolean;
    }>;
  };
};

export type ClientIntegration_FromFetClientIntegrationsFragment = {
  __typename?: 'ClientIntegration';
  id: string;
  public_id: string;
  name: string;
  system: ClientIntegrationSystemEnum;
  secondary_system?: ClientIntegrationSecondarySystemEnum | null;
  test: boolean;
  status: ClientIntegrationStatusEnum;
  status_update_date: any;
  type?: ClientIntegrationConfigurationTypeEnum | null;
  configuration_stringified?: string | null;
  status_update_requested_by: {
    __typename?: 'ClientUser';
    id: string;
    first_name?: string | null;
    last_name?: string | null;
    email: string;
  };
};

export type GetClientIntegrationsQueryVariables = Exact<{
  client_id: Scalars['String']['input'];
}>;

export type GetClientIntegrationsQuery = {
  __typename?: 'Query';
  client_integrations: Array<{
    __typename?: 'ClientIntegration';
    id: string;
    public_id: string;
    name: string;
    system: ClientIntegrationSystemEnum;
    secondary_system?: ClientIntegrationSecondarySystemEnum | null;
    test: boolean;
    status: ClientIntegrationStatusEnum;
    status_update_date: any;
    type?: ClientIntegrationConfigurationTypeEnum | null;
    configuration_stringified?: string | null;
    status_update_requested_by: {
      __typename?: 'ClientUser';
      id: string;
      first_name?: string | null;
      last_name?: string | null;
      email: string;
    };
  }>;
};

export type Client_FromGetClientsFragment = {
  __typename?: 'Client';
  id: string;
  public_id: string;
  legal_name?: string | null;
  address_1?: string | null;
  address_2?: string | null;
  zip_code?: string | null;
  city?: string | null;
  region?: string | null;
  website?: string | null;
  phone?: string | null;
  country?: { __typename?: 'Country'; code: string; name: string } | null;
  users: Array<{
    __typename?: 'ClientUser';
    id: string;
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    account_state: AccountStateEnum;
  }>;
};

export type GetClientsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetClientsQuery = {
  __typename?: 'Query';
  clients: {
    __typename?: 'GetClients';
    count: number;
    items: Array<{
      __typename?: 'Client';
      id: string;
      public_id: string;
      legal_name?: string | null;
      address_1?: string | null;
      address_2?: string | null;
      zip_code?: string | null;
      city?: string | null;
      region?: string | null;
      website?: string | null;
      phone?: string | null;
      country?: { __typename?: 'Country'; code: string; name: string } | null;
      users: Array<{
        __typename?: 'ClientUser';
        id: string;
        first_name?: string | null;
        last_name?: string | null;
        email: string;
        account_state: AccountStateEnum;
      }>;
    }>;
  };
};

export type Client_FromGetClientsIdNameFragment = {
  __typename?: 'Client';
  id: string;
  legal_name?: string | null;
};

export type GetClientsIdNameQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetClientsIdNameQuery = {
  __typename?: 'Query';
  clients: {
    __typename?: 'GetClients';
    count: number;
    items: Array<{
      __typename?: 'Client';
      id: string;
      legal_name?: string | null;
    }>;
  };
};

export type TransferClientOwnershipMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type TransferClientOwnershipMutation = {
  __typename?: 'Mutation';
  transfer_ownership: string;
};

export type Client_FromUpdateClientFragment = {
  __typename?: 'Client';
  id: string;
};

export type UpdateClientMutationVariables = Exact<{
  id: Scalars['String']['input'];
  data: UpdateClientInput;
}>;

export type UpdateClientMutation = {
  __typename?: 'Mutation';
  update_client: { __typename?: 'Client'; id: string };
};

export type ClientIntegration_FromUpsertClientIntegrationFragment = {
  __typename?: 'ClientIntegration';
  id: string;
};

export type UpsertClientIntegrationMutationVariables = Exact<{
  data: ClientIntegrationInput;
}>;

export type UpsertClientIntegrationMutation = {
  __typename?: 'Mutation';
  upsert_client_integration: { __typename?: 'ClientIntegration'; id: string };
};

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
  folder: S3FolderEnum;
}>;

export type UploadFileMutation = {
  __typename?: 'Mutation';
  upload_file: {
    __typename?: 'S3Object';
    location?: string | null;
    eTag?: string | null;
    bucket: string;
    key: string;
  };
};

export type Country_FromGetCountriesFragment = {
  __typename?: 'Country';
  id: string;
  name: string;
  code: string;
};

export type GetCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCountriesQuery = {
  __typename?: 'Query';
  countries: Array<{
    __typename?: 'Country';
    id: string;
    name: string;
    code: string;
  }>;
};

export type LinkImportToRequestMutationVariables = Exact<{
  data: LinkImportToRequestInput;
}>;

export type LinkImportToRequestMutation = {
  __typename?: 'Mutation';
  link_import_to_request: boolean;
};

export type TransitionDataCollectionRequestMutationVariables = Exact<{
  data: TransitionDataCollectionRequestInput;
}>;

export type TransitionDataCollectionRequestMutation = {
  __typename?: 'Mutation';
  transition_data_collection_request: boolean;
};

export type AddInventoryMutationVariables = Exact<{
  data: AddInventoryInput;
}>;

export type AddInventoryMutation = {
  __typename?: 'Mutation';
  add_inventory: boolean;
};

export type Inventory_FromGetAvailableInventoryFragment = {
  __typename?: 'Inventory';
  id: string;
  allocated_weight_kg: number;
  weight_kg: number;
  price_per_kg_in_gbp: number;
  vintage: string;
  project: {
    __typename?: 'Project';
    name: string;
    id: string;
    avoidance: boolean;
    removal: boolean;
    media?: Array<{
      __typename?: 'S3Object';
      key: string;
      bucket: string;
    }> | null;
    registry?: {
      __typename?: 'Registry';
      registry_retirement_fee_per_tonne?: number | null;
    } | null;
  };
  registry: {
    __typename?: 'Registry';
    id: string;
    name: string;
    public_id: string;
  };
};

export type GetAvailableInventoryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAvailableInventoryQuery = {
  __typename?: 'Query';
  available_inventory: Array<{
    __typename?: 'Inventory';
    id: string;
    allocated_weight_kg: number;
    weight_kg: number;
    price_per_kg_in_gbp: number;
    vintage: string;
    project: {
      __typename?: 'Project';
      name: string;
      id: string;
      avoidance: boolean;
      removal: boolean;
      media?: Array<{
        __typename?: 'S3Object';
        key: string;
        bucket: string;
      }> | null;
      registry?: {
        __typename?: 'Registry';
        registry_retirement_fee_per_tonne?: number | null;
      } | null;
    };
    registry: {
      __typename?: 'Registry';
      id: string;
      name: string;
      public_id: string;
    };
  }>;
};

export type Inventory_FromGetInventoryFragment = {
  __typename?: 'Inventory';
  id: string;
  vintage: string;
  weight_kg: number;
  allocated_weight_kg: number;
  retired_weight_kg: number;
  price_per_kg: number;
  price_per_kg_in_gbp: number;
  currency: CurrencyEnum;
  project: {
    __typename?: 'Project';
    name: string;
    id: string;
    public_id: string;
  };
  registry: {
    __typename?: 'Registry';
    id: string;
    name: string;
    public_id: string;
  };
};

export type GetInventoryQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  vintages?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  projectPublicIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  registryPublicIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  sortedColumn?: InputMaybe<InventorySortedColumnEnum>;
  sortedOrder?: InputMaybe<SortedOrderEnum>;
}>;

export type GetInventoryQuery = {
  __typename?: 'Query';
  inventory: {
    __typename?: 'GetInventory';
    total_count: number;
    items: Array<{
      __typename?: 'Inventory';
      id: string;
      vintage: string;
      weight_kg: number;
      allocated_weight_kg: number;
      retired_weight_kg: number;
      price_per_kg: number;
      price_per_kg_in_gbp: number;
      currency: CurrencyEnum;
      project: {
        __typename?: 'Project';
        name: string;
        id: string;
        public_id: string;
      };
      registry: {
        __typename?: 'Registry';
        id: string;
        name: string;
        public_id: string;
      };
    }>;
  };
};

export type GetInventoryPropertyValuesQueryVariables = Exact<{
  key: InventoryFilterPropertiesEnum;
  value: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GetInventoryPropertyValuesQuery = {
  __typename?: 'Query';
  inventory_property_values: Array<{
    __typename?: 'FilterValues';
    value: string;
    label?: string | null;
    count: number;
  }>;
};

export type RemoveInventoryMutationVariables = Exact<{
  data: RemoveInventoryInput;
}>;

export type RemoveInventoryMutation = {
  __typename?: 'Mutation';
  remove_inventory: boolean;
};

export type InventoryEvent_FromGetInventoryEventFragment = {
  __typename?: 'InventoryEvent';
  id: string;
  type: InventoryEventTypeEnum;
  source: string;
  project_id?: string | null;
  vintage?: string | null;
  registry_id?: string | null;
  portfolio_id?: string | null;
  weight_kg?: number | null;
  currency?: CurrencyEnum | null;
  price_per_kg?: number | null;
  price_per_kg_in_gbp?: number | null;
  rrp_min?: number | null;
  rrp_max?: number | null;
  allocated_weight_kg?: number | null;
  retired_weight_kg?: number | null;
  reason?: string | null;
  notes?: string | null;
  created_date: any;
  project?: { __typename?: 'Project'; name: string; public_id: string } | null;
  projects?: Array<{
    __typename?: 'ProjectsAllocatedList';
    project_id: string;
    vintage: string;
    registry: string;
    weight_kg: number;
  }> | null;
  registry?: {
    __typename?: 'Registry';
    name: string;
    public_id: string;
  } | null;
  meta_data?: Array<{
    __typename?: 'Metadata';
    key: string;
    value?: string | null;
  }> | null;
  pledge_meta_data?: Array<{
    __typename?: 'Metadata';
    key: string;
    value?: string | null;
  }> | null;
};

export type GetInventoryEventQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetInventoryEventQuery = {
  __typename?: 'Query';
  inventory_event: {
    __typename?: 'InventoryEvent';
    id: string;
    type: InventoryEventTypeEnum;
    source: string;
    project_id?: string | null;
    vintage?: string | null;
    registry_id?: string | null;
    portfolio_id?: string | null;
    weight_kg?: number | null;
    currency?: CurrencyEnum | null;
    price_per_kg?: number | null;
    price_per_kg_in_gbp?: number | null;
    rrp_min?: number | null;
    rrp_max?: number | null;
    allocated_weight_kg?: number | null;
    retired_weight_kg?: number | null;
    reason?: string | null;
    notes?: string | null;
    created_date: any;
    project?: {
      __typename?: 'Project';
      name: string;
      public_id: string;
    } | null;
    projects?: Array<{
      __typename?: 'ProjectsAllocatedList';
      project_id: string;
      vintage: string;
      registry: string;
      weight_kg: number;
    }> | null;
    registry?: {
      __typename?: 'Registry';
      name: string;
      public_id: string;
    } | null;
    meta_data?: Array<{
      __typename?: 'Metadata';
      key: string;
      value?: string | null;
    }> | null;
    pledge_meta_data?: Array<{
      __typename?: 'Metadata';
      key: string;
      value?: string | null;
    }> | null;
  };
};

export type InventoryEvent_FromGetInventoryEventsFragment = {
  __typename?: 'InventoryEvent';
  id: string;
  type: InventoryEventTypeEnum;
  source: string;
  project_id?: string | null;
  vintage?: string | null;
  registry_id?: string | null;
  portfolio_id?: string | null;
  weight_kg?: number | null;
  currency?: CurrencyEnum | null;
  price_per_kg?: number | null;
  price_per_kg_in_gbp?: number | null;
  rrp_min?: number | null;
  rrp_max?: number | null;
  allocated_weight_kg?: number | null;
  retired_weight_kg?: number | null;
  reason?: string | null;
  notes?: string | null;
  created_date: any;
  project?: { __typename?: 'Project'; name: string; public_id: string } | null;
  registry?: {
    __typename?: 'Registry';
    name: string;
    public_id: string;
  } | null;
  portfolio?: {
    __typename?: 'Portfolio';
    name: string;
    public_id: string;
  } | null;
};

export type GetInventoryEventsQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  vintages?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  projectPublicIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  eventTypes?: InputMaybe<
    Array<InventoryEventTypeEnum> | InventoryEventTypeEnum
  >;
  sortedColumn?: InputMaybe<InventoryEventsSortedColumnEnum>;
  sortedOrder?: InputMaybe<SortedOrderEnum>;
}>;

export type GetInventoryEventsQuery = {
  __typename?: 'Query';
  inventory_events: {
    __typename?: 'GetInventoryEvents';
    total_count: number;
    items: Array<{
      __typename?: 'InventoryEvent';
      id: string;
      type: InventoryEventTypeEnum;
      source: string;
      project_id?: string | null;
      vintage?: string | null;
      registry_id?: string | null;
      portfolio_id?: string | null;
      weight_kg?: number | null;
      currency?: CurrencyEnum | null;
      price_per_kg?: number | null;
      price_per_kg_in_gbp?: number | null;
      rrp_min?: number | null;
      rrp_max?: number | null;
      allocated_weight_kg?: number | null;
      retired_weight_kg?: number | null;
      reason?: string | null;
      notes?: string | null;
      created_date: any;
      project?: {
        __typename?: 'Project';
        name: string;
        public_id: string;
      } | null;
      registry?: {
        __typename?: 'Registry';
        name: string;
        public_id: string;
      } | null;
      portfolio?: {
        __typename?: 'Portfolio';
        name: string;
        public_id: string;
      } | null;
    }>;
  };
};

export type GetInventoryEventsPropertyValuesQueryVariables = Exact<{
  key: InventoryEventsFilterPropertiesEnum;
  value: Array<Scalars['String']['input']> | Scalars['String']['input'];
  projectPublicIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
}>;

export type GetInventoryEventsPropertyValuesQuery = {
  __typename?: 'Query';
  inventory_events_property_values: Array<{
    __typename?: 'FilterValues';
    value: string;
    label?: string | null;
    count: number;
  }>;
};

export type GetOrderCo2eRangeQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrderCo2eRangeQuery = {
  __typename?: 'Query';
  order_co2e_range: {
    __typename?: 'GetOrdersCo2ERange';
    min?: number | null;
    max?: number | null;
    histogram: Array<{
      __typename?: 'HistogramBucket';
      key: number;
      doc_count: number;
    }>;
  };
};

export type GetOrderPropertyValuesQueryVariables = Exact<{
  key: OrderFilterPropertiesEnum;
  value: Scalars['String']['input'];
}>;

export type GetOrderPropertyValuesQuery = {
  __typename?: 'Query';
  order_property_values: Array<{
    __typename?: 'FilterValues';
    value: string;
    count: number;
  }>;
};

export type Order_FromGetOrdersFragment = {
  __typename?: 'Order';
  created_date: any;
  public_id: string;
  status: OrderStatusEnum;
  weight_kg: number;
  amount: number;
  currency: string;
  client: {
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
  };
  portfolio: {
    __typename?: 'Portfolio';
    public_id: string;
    name: string;
    portfolio_version?: string | null;
  };
};

export type GetOrdersQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  publicIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  clientPublicIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  clientLegalNames?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  portfolioPublicIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  portfolioNames?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  invoiceNumbers?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  status?: InputMaybe<Array<OrderStatusEnum> | OrderStatusEnum>;
  co2e: RangeInput;
  sortedColumn?: InputMaybe<OrderSortedColumnEnum>;
  sortedOrder?: InputMaybe<SortedOrderEnum>;
}>;

export type GetOrdersQuery = {
  __typename?: 'Query';
  orders: {
    __typename?: 'GetOrders';
    total_count: number;
    total_co2e: number;
    pagination_limit: number;
    items: Array<{
      __typename?: 'Order';
      created_date: any;
      public_id: string;
      status: OrderStatusEnum;
      weight_kg: number;
      amount: number;
      currency: string;
      client: {
        __typename?: 'Client';
        public_id: string;
        legal_name?: string | null;
      };
      portfolio: {
        __typename?: 'Portfolio';
        public_id: string;
        name: string;
        portfolio_version?: string | null;
      };
    }>;
  };
};

export type Portfolio_FromGetPortfolioFragment = {
  __typename?: 'Portfolio';
  id: string;
  public_id: string;
  is_published: boolean;
  name: string;
  display_name: string;
  portfolio_version?: string | null;
  description: string;
  allocated_weight_kg: number;
  reserved_weight_kg: number;
  created_date: any;
  updated_date: any;
  prices: Array<{
    __typename?: 'PortfolioPrice';
    currency: string;
    price_per_kg: number;
  }>;
  allocations: Array<{
    __typename?: 'PortfolioAllocation';
    percentage: number;
    project: {
      __typename?: 'Project';
      public_id: string;
      name: string;
      description?: string | null;
      avoidance: boolean;
      removal: boolean;
      coordinates?: {
        __typename?: 'Geography';
        coordinates: Array<number>;
      } | null;
      project_type: {
        __typename?: 'ProjectType';
        name: string;
        category: string;
      };
      country: { __typename?: 'Country'; name: string; code: string };
      media?: Array<{
        __typename?: 'S3Object';
        bucket: string;
        key: string;
      }> | null;
    };
  }>;
  clients?: Array<{
    __typename?: 'Client';
    public_id: string;
    legal_name?: string | null;
  }> | null;
  distribution?: {
    __typename?: 'PortfolioDistribution';
    totalAllocated: number;
    avoidance: number;
    removal: number;
  } | null;
};

export type KeyFact_FromGetPortfolioFragment = {
  __typename?: 'PortfolioKeyFact';
  id: string;
  key_facts: Array<string>;
};

export type Project_FromGetPortfolioFragment = {
  __typename?: 'Project';
  public_id: string;
  registry?: {
    __typename?: 'Registry';
    id: string;
    registry_retirement_fee_per_tonne?: number | null;
  } | null;
};

export type GetPortfolioQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetPortfolioQuery = {
  __typename?: 'Query';
  portfolio: {
    __typename?: 'GetPortfolio';
    portfolio: {
      __typename?: 'Portfolio';
      id: string;
      public_id: string;
      is_published: boolean;
      name: string;
      display_name: string;
      portfolio_version?: string | null;
      description: string;
      allocated_weight_kg: number;
      reserved_weight_kg: number;
      created_date: any;
      updated_date: any;
      prices: Array<{
        __typename?: 'PortfolioPrice';
        currency: string;
        price_per_kg: number;
      }>;
      allocations: Array<{
        __typename?: 'PortfolioAllocation';
        percentage: number;
        project: {
          __typename?: 'Project';
          public_id: string;
          name: string;
          description?: string | null;
          avoidance: boolean;
          removal: boolean;
          coordinates?: {
            __typename?: 'Geography';
            coordinates: Array<number>;
          } | null;
          project_type: {
            __typename?: 'ProjectType';
            name: string;
            category: string;
          };
          country: { __typename?: 'Country'; name: string; code: string };
          media?: Array<{
            __typename?: 'S3Object';
            bucket: string;
            key: string;
          }> | null;
        };
      }>;
      clients?: Array<{
        __typename?: 'Client';
        public_id: string;
        legal_name?: string | null;
      }> | null;
      distribution?: {
        __typename?: 'PortfolioDistribution';
        totalAllocated: number;
        avoidance: number;
        removal: number;
      } | null;
    };
    keyFacts?: {
      __typename?: 'PortfolioKeyFact';
      id: string;
      key_facts: Array<string>;
    } | null;
    projects: Array<{
      __typename?: 'Project';
      public_id: string;
      registry?: {
        __typename?: 'Registry';
        id: string;
        registry_retirement_fee_per_tonne?: number | null;
      } | null;
    }>;
  };
};

export type Portfolio_FromGetPortfolioStatsFragment = {
  __typename?: 'PortfolioStats';
  total_orders: number;
};

export type GetPortfolioStatsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetPortfolioStatsQuery = {
  __typename?: 'Query';
  portfolio_stats: { __typename?: 'PortfolioStats'; total_orders: number };
};

export type PortfolioDataMapper_FromGetPortfoliosFragment = {
  __typename?: 'PortfolioDataMapper';
  id: string;
  public_id?: string | null;
  name?: string | null;
  display_name?: string | null;
  portfolio_version?: string | null;
  allocated_weight_kg?: number | null;
  reserved_weight_kg?: number | null;
  is_published?: boolean | null;
  is_draft?: boolean | null;
  status?: PortfolioStatusEnum | null;
  created_date: any;
  prices?: Array<{
    __typename?: 'PortfolioPrice';
    currency: string;
    price_per_kg: number;
  }> | null;
};

export type GetPortfoliosQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  orderColumn: PortfolioOrderColumnEnum;
  orderDirection: OrderDirectionEnum;
}>;

export type GetPortfoliosQuery = {
  __typename?: 'Query';
  portfolios: {
    __typename?: 'GetPortfolios';
    total_count: number;
    items: Array<{
      __typename?: 'PortfolioDataMapper';
      id: string;
      public_id?: string | null;
      name?: string | null;
      display_name?: string | null;
      portfolio_version?: string | null;
      allocated_weight_kg?: number | null;
      reserved_weight_kg?: number | null;
      is_published?: boolean | null;
      is_draft?: boolean | null;
      status?: PortfolioStatusEnum | null;
      created_date: any;
      prices?: Array<{
        __typename?: 'PortfolioPrice';
        currency: string;
        price_per_kg: number;
      }> | null;
    }>;
  };
};

export type PublishPortfolioMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type PublishPortfolioMutation = {
  __typename?: 'Mutation';
  publish_portfolio: boolean;
};

export type UnpublishPortfolioMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type UnpublishPortfolioMutation = {
  __typename?: 'Mutation';
  unpublish_portfolio: boolean;
};

export type CreatePortfolioMutationVariables = Exact<{
  data: PortfolioDraftInput;
}>;

export type CreatePortfolioMutation = {
  __typename?: 'Mutation';
  create_portfolio: string;
};

export type PortfolioDraft_FromGetPortfolioDraftFragment = {
  __typename?: 'PortfolioDraft';
  id: string;
  portfolio_draft: {
    __typename?: 'PortfolioDraftJsonType';
    acl?: Array<string> | null;
    details?: {
      __typename?: 'PortfolioDraftDetailsType';
      name?: string | null;
      description?: string | null;
      accounting_code?: string | null;
      currency?: CurrencyEnum | null;
      key_facts?: Array<string> | null;
      portfolio_version?: string | null;
    } | null;
    allocations?: Array<{
      __typename?: 'PortfolioDetailsAllocationsType';
      project_id: string;
      project_name: string;
      registry_id: string;
      registry: string;
      weight_tonnes: number;
      vintage: string;
      price_per_kg_in_gbp: number;
      avoidance: boolean;
      removal: boolean;
      registry_fee_per_tonne: number;
    }> | null;
  };
};

export type GetPortfolioDraftQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetPortfolioDraftQuery = {
  __typename?: 'Query';
  portfolio_draft: {
    __typename?: 'PortfolioDraft';
    id: string;
    portfolio_draft: {
      __typename?: 'PortfolioDraftJsonType';
      acl?: Array<string> | null;
      details?: {
        __typename?: 'PortfolioDraftDetailsType';
        name?: string | null;
        description?: string | null;
        accounting_code?: string | null;
        currency?: CurrencyEnum | null;
        key_facts?: Array<string> | null;
        portfolio_version?: string | null;
      } | null;
      allocations?: Array<{
        __typename?: 'PortfolioDetailsAllocationsType';
        project_id: string;
        project_name: string;
        registry_id: string;
        registry: string;
        weight_tonnes: number;
        vintage: string;
        price_per_kg_in_gbp: number;
        avoidance: boolean;
        removal: boolean;
        registry_fee_per_tonne: number;
      }> | null;
    };
  };
};

export type PortfolioDraft_FromUpsertPortfolioDraftFragment = {
  __typename?: 'PortfolioDraft';
  id: string;
};

export type UpsertPortfolioDraftMutationVariables = Exact<{
  data: PortfolioDraftInput;
}>;

export type UpsertPortfolioDraftMutation = {
  __typename?: 'Mutation';
  upsert_portfolio_draft: { __typename?: 'PortfolioDraft'; id: string };
};

export type Project_FromGetProjectFragment = {
  __typename?: 'Project';
  id: string;
  public_id: string;
  name: string;
  description?: string | null;
  website?: string | null;
  location?: string | null;
  removal: boolean;
  avoidance: boolean;
  sdgs?: Array<SustainableDevelopmentGoalsEnum> | null;
  registry_project_id?: string | null;
  registry_project_url?: string | null;
  contact?: string | null;
  created_date: any;
  updated_date: any;
  country: { __typename?: 'Country'; id: string; name: string; code: string };
  coordinates?: { __typename?: 'Geography'; coordinates: Array<number> } | null;
  project_type: {
    __typename?: 'ProjectType';
    id: string;
    name: string;
    category: string;
  };
  developer?: {
    __typename?: 'ProjectDeveloper';
    id: string;
    name: string;
    description?: string | null;
    website?: string | null;
    media?: Array<{
      __typename?: 'S3Object';
      location?: string | null;
      key: string;
      bucket: string;
    }> | null;
  } | null;
  owner?: { __typename?: 'ProjectOwner'; id: string; name: string } | null;
  registry?: {
    __typename?: 'Registry';
    id: string;
    name: string;
    website?: string | null;
    media?: Array<{
      __typename?: 'S3Object';
      location?: string | null;
      key: string;
      bucket: string;
    }> | null;
  } | null;
  certifications?: Array<{
    __typename?: 'ProjectCertification';
    id: string;
    name: string;
    media?: Array<{
      __typename?: 'S3Object';
      location?: string | null;
      key: string;
      bucket: string;
    }> | null;
  }> | null;
  carbonplan_assessment?: {
    __typename?: 'CarbonplanAssessment';
    data_stringify?: string | null;
  } | null;
  media?: Array<{
    __typename?: 'S3Object';
    location?: string | null;
    bucket: string;
    eTag?: string | null;
    key: string;
  }> | null;
};

export type GetProjectQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']['input']>;
  public_id?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetProjectQuery = {
  __typename?: 'Query';
  project: {
    __typename?: 'Project';
    id: string;
    public_id: string;
    name: string;
    description?: string | null;
    website?: string | null;
    location?: string | null;
    removal: boolean;
    avoidance: boolean;
    sdgs?: Array<SustainableDevelopmentGoalsEnum> | null;
    registry_project_id?: string | null;
    registry_project_url?: string | null;
    contact?: string | null;
    created_date: any;
    updated_date: any;
    country: { __typename?: 'Country'; id: string; name: string; code: string };
    coordinates?: {
      __typename?: 'Geography';
      coordinates: Array<number>;
    } | null;
    project_type: {
      __typename?: 'ProjectType';
      id: string;
      name: string;
      category: string;
    };
    developer?: {
      __typename?: 'ProjectDeveloper';
      id: string;
      name: string;
      description?: string | null;
      website?: string | null;
      media?: Array<{
        __typename?: 'S3Object';
        location?: string | null;
        key: string;
        bucket: string;
      }> | null;
    } | null;
    owner?: { __typename?: 'ProjectOwner'; id: string; name: string } | null;
    registry?: {
      __typename?: 'Registry';
      id: string;
      name: string;
      website?: string | null;
      media?: Array<{
        __typename?: 'S3Object';
        location?: string | null;
        key: string;
        bucket: string;
      }> | null;
    } | null;
    certifications?: Array<{
      __typename?: 'ProjectCertification';
      id: string;
      name: string;
      media?: Array<{
        __typename?: 'S3Object';
        location?: string | null;
        key: string;
        bucket: string;
      }> | null;
    }> | null;
    carbonplan_assessment?: {
      __typename?: 'CarbonplanAssessment';
      data_stringify?: string | null;
    } | null;
    media?: Array<{
      __typename?: 'S3Object';
      location?: string | null;
      bucket: string;
      eTag?: string | null;
      key: string;
    }> | null;
  };
};

export type GetProjectPropertyValuesQueryVariables = Exact<{
  key: ProjectFilterPropertiesEnum;
  value: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GetProjectPropertyValuesQuery = {
  __typename?: 'Query';
  project_property_values: Array<{
    __typename?: 'FilterValues';
    value: string;
    label?: string | null;
    count: number;
  }>;
};

export type Project_FromGetProjectsFragment = {
  __typename?: 'Project';
  id: string;
  name: string;
  public_id: string;
  project_type: { __typename?: 'ProjectType'; name: string; category: string };
  country: { __typename?: 'Country'; name: string };
  media?: Array<{
    __typename?: 'S3Object';
    bucket: string;
    key: string;
  }> | null;
  registry?: { __typename?: 'Registry'; id: string; name: string } | null;
};

export type GetProjectsQueryVariables = Exact<{
  projectName?: InputMaybe<Scalars['String']['input']>;
  projectPublicIds?: InputMaybe<
    Array<Scalars['String']['input']> | Scalars['String']['input']
  >;
  page?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sortedColumn?: InputMaybe<ProjectSortedColumnEnum>;
  sortedOrder?: InputMaybe<SortedOrderEnum>;
}>;

export type GetProjectsQuery = {
  __typename?: 'Query';
  projects: {
    __typename?: 'GetProjects';
    total_count: number;
    items: Array<{
      __typename?: 'Project';
      id: string;
      name: string;
      public_id: string;
      project_type: {
        __typename?: 'ProjectType';
        name: string;
        category: string;
      };
      country: { __typename?: 'Country'; name: string };
      media?: Array<{
        __typename?: 'S3Object';
        bucket: string;
        key: string;
      }> | null;
      registry?: { __typename?: 'Registry'; id: string; name: string } | null;
    }>;
  };
};

export type Project_FromUpsertProjectFragment = {
  __typename?: 'Project';
  id: string;
  public_id: string;
};

export type UpsertProjectMutationVariables = Exact<{
  data: UpsertProjectInput;
}>;

export type UpsertProjectMutation = {
  __typename?: 'Mutation';
  upsert_project: { __typename?: 'Project'; id: string; public_id: string };
};

export type ProjectCertification_FromProjectCertificationFragment = {
  __typename?: 'ProjectCertification';
  id: string;
  name: string;
  description?: string | null;
  registry_link: string;
  updated_date: any;
  media?: Array<{
    __typename?: 'S3Object';
    location?: string | null;
    bucket: string;
    eTag?: string | null;
    key: string;
  }> | null;
};

export type GetProjectCertificationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetProjectCertificationQuery = {
  __typename?: 'Query';
  project_certification: {
    __typename?: 'ProjectCertification';
    id: string;
    name: string;
    description?: string | null;
    registry_link: string;
    updated_date: any;
    media?: Array<{
      __typename?: 'S3Object';
      location?: string | null;
      bucket: string;
      eTag?: string | null;
      key: string;
    }> | null;
  };
};

export type ProjectCertification_FromGetProjectCertificationsFragment = {
  __typename?: 'ProjectCertification';
  id: string;
  name: string;
  media?: Array<{
    __typename?: 'S3Object';
    location?: string | null;
    bucket: string;
    eTag?: string | null;
    key: string;
  }> | null;
};

export type GetProjectCertificationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetProjectCertificationsQuery = {
  __typename?: 'Query';
  project_certifications: Array<{
    __typename?: 'ProjectCertification';
    id: string;
    name: string;
    media?: Array<{
      __typename?: 'S3Object';
      location?: string | null;
      bucket: string;
      eTag?: string | null;
      key: string;
    }> | null;
  }>;
};

export type ProjectCertification_FromUpsertProjectCertificationFragment = {
  __typename?: 'ProjectCertification';
  id: string;
};

export type UpsertProjectCertificationMutationVariables = Exact<{
  data: UpsertProjectCertificationInput;
}>;

export type UpsertProjectCertificationMutation = {
  __typename?: 'Mutation';
  upsert_project_certification: {
    __typename?: 'ProjectCertification';
    id: string;
  };
};

export type ProjectDeveloper_FromGetProjectDeveloperFragment = {
  __typename?: 'ProjectDeveloper';
  id: string;
  name: string;
  public_id: string;
  description?: string | null;
  website?: string | null;
  projects: Array<{
    __typename?: 'Project';
    name: string;
    public_id: string;
    project_type: {
      __typename?: 'ProjectType';
      name: string;
      category: string;
    };
    country: { __typename?: 'Country'; name: string };
  }>;
  media?: Array<{
    __typename?: 'S3Object';
    location?: string | null;
    bucket: string;
    eTag?: string | null;
    key: string;
  }> | null;
};

export type GetProjectDeveloperQueryVariables = Exact<{
  public_id: Scalars['String']['input'];
}>;

export type GetProjectDeveloperQuery = {
  __typename?: 'Query';
  project_developer: {
    __typename?: 'ProjectDeveloper';
    id: string;
    name: string;
    public_id: string;
    description?: string | null;
    website?: string | null;
    projects: Array<{
      __typename?: 'Project';
      name: string;
      public_id: string;
      project_type: {
        __typename?: 'ProjectType';
        name: string;
        category: string;
      };
      country: { __typename?: 'Country'; name: string };
    }>;
    media?: Array<{
      __typename?: 'S3Object';
      location?: string | null;
      bucket: string;
      eTag?: string | null;
      key: string;
    }> | null;
  };
};

export type ProjectDeveloper_FromGetProjectDevelopersFragment = {
  __typename?: 'ProjectDeveloper';
  name: string;
  public_id: string;
  projects: Array<{ __typename?: 'Project'; name: string; public_id: string }>;
  media?: Array<{
    __typename?: 'S3Object';
    location?: string | null;
    bucket: string;
    key: string;
  }> | null;
};

export type GetProjectDevelopersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetProjectDevelopersQuery = {
  __typename?: 'Query';
  project_developers: Array<{
    __typename?: 'ProjectDeveloper';
    name: string;
    public_id: string;
    projects: Array<{
      __typename?: 'Project';
      name: string;
      public_id: string;
    }>;
    media?: Array<{
      __typename?: 'S3Object';
      location?: string | null;
      bucket: string;
      key: string;
    }> | null;
  }>;
};

export type ProjectDeveloperNameId_FromGetProjectDevelopersFragment = {
  __typename?: 'ProjectDeveloper';
  name: string;
  id: string;
};

export type GetProjectDevelopersNameIdQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetProjectDevelopersNameIdQuery = {
  __typename?: 'Query';
  project_developers: Array<{
    __typename?: 'ProjectDeveloper';
    name: string;
    id: string;
  }>;
};

export type ProjectDeveloper_FromUpsertProjectDeveloperFragment = {
  __typename?: 'ProjectDeveloper';
  id: string;
};

export type UpsertProjectDeveloperMutationVariables = Exact<{
  data: UpsertDeveloperInput;
}>;

export type UpsertProjectDeveloperMutation = {
  __typename?: 'Mutation';
  upsert_project_developer: { __typename?: 'ProjectDeveloper'; id: string };
};

export type ProjectOwner_FromGetProjectOwnerFragment = {
  __typename?: 'ProjectOwner';
  id: string;
  name: string;
  description?: string | null;
  updated_date: any;
};

export type GetProjectOwnerQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetProjectOwnerQuery = {
  __typename?: 'Query';
  project_owner: {
    __typename?: 'ProjectOwner';
    id: string;
    name: string;
    description?: string | null;
    updated_date: any;
  };
};

export type ProjectOwner_FromGetProjectOwnersFragment = {
  __typename?: 'ProjectOwner';
  id: string;
  name: string;
  description?: string | null;
};

export type GetProjectOwnersQueryVariables = Exact<{ [key: string]: never }>;

export type GetProjectOwnersQuery = {
  __typename?: 'Query';
  project_owners: Array<{
    __typename?: 'ProjectOwner';
    id: string;
    name: string;
    description?: string | null;
  }>;
};

export type ProjectOwner_FromUpsertProjectOwnerFragment = {
  __typename?: 'ProjectOwner';
  id: string;
};

export type UpsertProjectOwnerMutationVariables = Exact<{
  data: ProjectOwnerInput;
}>;

export type UpsertProjectOwnerMutation = {
  __typename?: 'Mutation';
  upsert_project_owner: { __typename?: 'ProjectOwner'; id: string };
};

export type ProjectType_FromGetProjectTypeFragment = {
  __typename?: 'ProjectType';
  id: string;
  name: string;
  category: string;
};

export type GetProjectTypeQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type GetProjectTypeQuery = {
  __typename?: 'Query';
  project_type: {
    __typename?: 'ProjectType';
    id: string;
    name: string;
    category: string;
  };
};

export type GetProjectTypeCategoriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetProjectTypeCategoriesQuery = {
  __typename?: 'Query';
  project_type_categories: Array<{
    __typename?: 'ProjectType';
    category: string;
  }>;
};

export type ProjectType_FromGetProjectTypesFragment = {
  __typename?: 'ProjectType';
  name: string;
  category: string;
  id: string;
};

export type GetProjectTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetProjectTypesQuery = {
  __typename?: 'Query';
  project_types: Array<{
    __typename?: 'ProjectType';
    name: string;
    category: string;
    id: string;
  }>;
};

export type ProjectType_FromUpsertProjectTypeFragment = {
  __typename?: 'ProjectType';
  id: string;
};

export type UpsertProjectTypeMutationVariables = Exact<{
  data: ProjectTypeInput;
}>;

export type UpsertProjectTypeMutation = {
  __typename?: 'Mutation';
  upsert_project_type: { __typename?: 'ProjectType'; id: string };
};

export type Registry_FromGetRegistriesFragment = {
  __typename?: 'Registry';
  id: string;
  public_id: string;
  name: string;
  website?: string | null;
  registry_retirement_fee_per_tonne?: number | null;
  media?: Array<{
    __typename?: 'S3Object';
    location?: string | null;
    bucket: string;
    eTag?: string | null;
    key: string;
  }> | null;
};

export type GetRegistriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRegistriesQuery = {
  __typename?: 'Query';
  registries: Array<{
    __typename?: 'Registry';
    id: string;
    public_id: string;
    name: string;
    website?: string | null;
    registry_retirement_fee_per_tonne?: number | null;
    media?: Array<{
      __typename?: 'S3Object';
      location?: string | null;
      bucket: string;
      eTag?: string | null;
      key: string;
    }> | null;
  }>;
};

export type Registry_FromGetRegistryFragment = {
  __typename?: 'Registry';
  id: string;
  public_id: string;
  name: string;
  website?: string | null;
  infrastructure?: string | null;
  projects_base_url?: string | null;
  has_automated_retirement?: boolean | null;
  registry_retirement_fee_per_tonne?: number | null;
  updated_date: any;
  media?: Array<{
    __typename?: 'S3Object';
    location?: string | null;
    bucket: string;
    eTag?: string | null;
    key: string;
  }> | null;
};

export type GetRegistryQueryVariables = Exact<{
  public_id: Scalars['String']['input'];
}>;

export type GetRegistryQuery = {
  __typename?: 'Query';
  registry: {
    __typename?: 'Registry';
    id: string;
    public_id: string;
    name: string;
    website?: string | null;
    infrastructure?: string | null;
    projects_base_url?: string | null;
    has_automated_retirement?: boolean | null;
    registry_retirement_fee_per_tonne?: number | null;
    updated_date: any;
    media?: Array<{
      __typename?: 'S3Object';
      location?: string | null;
      bucket: string;
      eTag?: string | null;
      key: string;
    }> | null;
  };
};

export type Registry_FromUpsertRegistryFragment = {
  __typename?: 'Registry';
  id: string;
  public_id: string;
};

export type UpsertRegistryMutationVariables = Exact<{
  data: RegistryInput;
}>;

export type UpsertRegistryMutation = {
  __typename?: 'Mutation';
  upsert_registry: { __typename?: 'Registry'; id: string; public_id: string };
};

export type ConfirmRetirementMutationVariables = Exact<{
  data: ConfirmRetirementInput;
}>;

export type ConfirmRetirementMutation = {
  __typename?: 'Mutation';
  confirm_retirement: boolean;
};

export type RetirementConfirmed_FromGetRetirementsConfirmedFragment = {
  __typename?: 'RetirementConfirmed';
  vintage: string;
  retirement_id: string;
  retirement_url: string;
  weight_kg: number;
  created_date: any;
  serial_numbers: Array<string>;
  project: { __typename?: 'Project'; name: string; public_id: string };
  registry?: {
    __typename?: 'Registry';
    name: string;
    public_id: string;
  } | null;
};

export type GetRetirementsConfirmedQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  sortedColumn?: InputMaybe<RetirementSortedColumnEnum>;
  sortedOrder?: InputMaybe<SortedOrderEnum>;
}>;

export type GetRetirementsConfirmedQuery = {
  __typename?: 'Query';
  retirements_confirmed: {
    __typename?: 'GetRetirementsConfirmed';
    total_count: number;
    items: Array<{
      __typename?: 'RetirementConfirmed';
      vintage: string;
      retirement_id: string;
      retirement_url: string;
      weight_kg: number;
      created_date: any;
      serial_numbers: Array<string>;
      project: { __typename?: 'Project'; name: string; public_id: string };
      registry?: {
        __typename?: 'Registry';
        name: string;
        public_id: string;
      } | null;
    }>;
  };
};

export type RetirementRequested_FromGetRetirementsRequestedFragment = {
  __typename?: 'RetirementRequested';
  retirement_id: string;
  vintage: string;
  weight_kg: number;
  created_date: any;
  project: { __typename?: 'Project'; name: string; public_id: string };
  registry?: {
    __typename?: 'Registry';
    name: string;
    public_id: string;
  } | null;
};

export type GetRetirementsRequestedQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetRetirementsRequestedQuery = {
  __typename?: 'Query';
  retirements_requested: Array<{
    __typename?: 'RetirementRequested';
    retirement_id: string;
    vintage: string;
    weight_kg: number;
    created_date: any;
    project: { __typename?: 'Project'; name: string; public_id: string };
    registry?: {
      __typename?: 'Registry';
      name: string;
      public_id: string;
    } | null;
  }>;
};

export type MigrateClientsToChargebeeMutationVariables = Exact<{
  [key: string]: never;
}>;

export type MigrateClientsToChargebeeMutation = {
  __typename?: 'Mutation';
  migrate_clients_to_chargebee: boolean;
};

export type ReconcileInventoryMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ReconcileInventoryMutation = {
  __typename?: 'Mutation';
  reconcile_inventory: boolean;
};

export type SyncPortfoliosInChargebeeMutationVariables = Exact<{
  [key: string]: never;
}>;

export type SyncPortfoliosInChargebeeMutation = {
  __typename?: 'Mutation';
  sync_portfolios_in_chargebee: boolean;
};

export type TriggerNetlifyDeployMutationVariables = Exact<{
  [key: string]: never;
}>;

export type TriggerNetlifyDeployMutation = {
  __typename?: 'Mutation';
  trigger_netlify_deploy: boolean;
};

export type User_FromMeFragment = {
  __typename?: 'User';
  sub: string;
  username: string;
  email_verified: string;
  name: string;
  email: string;
};

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    sub: string;
    username: string;
    email_verified: string;
    name: string;
    email: string;
  };
};

export const InventoryReconciliationResults_FromGetInventoryReconciliationResultsFragmentDoc = gql`
  fragment InventoryReconciliationResults_fromGetInventoryReconciliationResults on InventoryReconciliationResults {
    id
    created_date
    error_count
    recon_results_summary_file_url
    recon_external_inventory_file_url
    recon_internal_inventory_file_url
  }
`;
export const ApiKey_FromGetApiKeysFragmentDoc = gql`
  fragment ApiKey_fromGetApiKeys on ApiKey {
    id
    name
    api_key
    created_date
  }
`;
export const ApiLog_FromGetApiLogsFragmentDoc = gql`
  fragment ApiLog_fromGetApiLogs on ApiLog {
    id
    date
    api_key
    endpoint
    response_code
    request
    response
  }
`;
export const Client_FromCreateClientFragmentDoc = gql`
  fragment Client_fromCreateClient on Client {
    id
  }
`;
export const ClientUser_FromGetClientFragmentDoc = gql`
  fragment ClientUser_fromGetClient on ClientUser {
    id
    first_name
    last_name
    email
    role {
      name
    }
    account_state
    created_date
  }
`;
export const ClientSubscriptionEntitlement_FromGetClientFragmentDoc = gql`
  fragment ClientSubscriptionEntitlement_fromGetClient on ClientSubscriptionEntitlement {
    feature_id
    feature_type
    value
    is_enabled
  }
`;
export const Client_FromGetClientFragmentDoc = gql`
  fragment Client_fromGetClient on Client {
    id
    public_id
    legal_name
    address_1
    address_2
    city
    zip_code
    region
    country {
      code
      name
    }
    website
    phone
    billing_email_address
    currency
    client_function
    tax_id_type
    tax_id_value
    users {
      ...ClientUser_fromGetClient
    }
    domains {
      domain
    }
    entitlements {
      ...ClientSubscriptionEntitlement_fromGetClient
    }
  }
  ${ClientUser_FromGetClientFragmentDoc}
  ${ClientSubscriptionEntitlement_FromGetClientFragmentDoc}
`;
export const ClientIntegration_FromFetClientIntegrationsFragmentDoc = gql`
  fragment ClientIntegration_fromFetClientIntegrations on ClientIntegration {
    id
    public_id
    name
    system
    secondary_system
    test
    status
    status_update_date
    status_update_requested_by {
      id
      first_name
      last_name
      email
    }
    type
    configuration_stringified
  }
`;
export const Client_FromGetClientsFragmentDoc = gql`
  fragment Client_fromGetClients on Client {
    id
    public_id
    legal_name
    country {
      code
      name
    }
    address_1
    address_2
    zip_code
    city
    region
    website
    phone
    users {
      id
      first_name
      last_name
      email
      account_state
    }
  }
`;
export const Client_FromGetClientsIdNameFragmentDoc = gql`
  fragment Client_fromGetClientsIdName on Client {
    id
    legal_name
  }
`;
export const Client_FromUpdateClientFragmentDoc = gql`
  fragment Client_fromUpdateClient on Client {
    id
  }
`;
export const ClientIntegration_FromUpsertClientIntegrationFragmentDoc = gql`
  fragment ClientIntegration_fromUpsertClientIntegration on ClientIntegration {
    id
  }
`;
export const Country_FromGetCountriesFragmentDoc = gql`
  fragment Country_fromGetCountries on Country {
    id
    name
    code
  }
`;
export const Inventory_FromGetAvailableInventoryFragmentDoc = gql`
  fragment Inventory_fromGetAvailableInventory on Inventory {
    id
    project {
      name
      id
      avoidance
      removal
      media {
        key
        bucket
      }
      registry {
        registry_retirement_fee_per_tonne
      }
    }
    allocated_weight_kg
    weight_kg
    price_per_kg_in_gbp
    vintage
    registry {
      id
      name
      public_id
    }
  }
`;
export const Inventory_FromGetInventoryFragmentDoc = gql`
  fragment Inventory_fromGetInventory on Inventory {
    id
    vintage
    project {
      name
      id
      public_id
    }
    registry {
      id
      name
      public_id
    }
    weight_kg
    allocated_weight_kg
    retired_weight_kg
    price_per_kg
    price_per_kg_in_gbp
    currency
  }
`;
export const InventoryEvent_FromGetInventoryEventFragmentDoc = gql`
  fragment InventoryEvent_fromGetInventoryEvent on InventoryEvent {
    id
    type
    source
    project_id
    project {
      name
      public_id
    }
    projects {
      project_id
      vintage
      registry
      weight_kg
    }
    vintage
    registry_id
    registry {
      name
      public_id
    }
    portfolio_id
    weight_kg
    currency
    price_per_kg
    price_per_kg_in_gbp
    rrp_min
    rrp_max
    allocated_weight_kg
    retired_weight_kg
    reason
    notes
    meta_data {
      key
      value
    }
    pledge_meta_data {
      key
      value
    }
    created_date
  }
`;
export const InventoryEvent_FromGetInventoryEventsFragmentDoc = gql`
  fragment InventoryEvent_fromGetInventoryEvents on InventoryEvent {
    id
    type
    source
    project_id
    project {
      name
      public_id
    }
    vintage
    registry_id
    registry {
      name
      public_id
    }
    portfolio_id
    portfolio {
      name
      public_id
    }
    weight_kg
    currency
    price_per_kg
    price_per_kg_in_gbp
    rrp_min
    rrp_max
    allocated_weight_kg
    retired_weight_kg
    reason
    notes
    created_date
  }
`;
export const Order_FromGetOrdersFragmentDoc = gql`
  fragment Order_fromGetOrders on Order {
    created_date
    client {
      public_id
      legal_name
    }
    public_id
    status
    portfolio {
      public_id
      name
      portfolio_version
    }
    weight_kg
    amount
    currency
  }
`;
export const Portfolio_FromGetPortfolioFragmentDoc = gql`
  fragment Portfolio_fromGetPortfolio on Portfolio {
    id
    public_id
    is_published
    name
    display_name
    portfolio_version
    description
    prices {
      currency
      price_per_kg
    }
    allocated_weight_kg
    reserved_weight_kg
    allocations {
      project {
        public_id
        name
        description
        avoidance
        removal
        coordinates {
          coordinates
        }
        project_type {
          name
          category
        }
        country {
          name
          code
        }
        media {
          bucket
          key
        }
      }
      percentage
    }
    clients {
      public_id
      legal_name
    }
    distribution {
      totalAllocated
      avoidance
      removal
    }
    created_date
    updated_date
  }
`;
export const KeyFact_FromGetPortfolioFragmentDoc = gql`
  fragment KeyFact_fromGetPortfolio on PortfolioKeyFact {
    id
    key_facts
  }
`;
export const Project_FromGetPortfolioFragmentDoc = gql`
  fragment Project_fromGetPortfolio on Project {
    public_id
    registry {
      id
      registry_retirement_fee_per_tonne
    }
  }
`;
export const Portfolio_FromGetPortfolioStatsFragmentDoc = gql`
  fragment Portfolio_fromGetPortfolioStats on PortfolioStats {
    total_orders
  }
`;
export const PortfolioDataMapper_FromGetPortfoliosFragmentDoc = gql`
  fragment PortfolioDataMapper_fromGetPortfolios on PortfolioDataMapper {
    id
    public_id
    name
    display_name
    portfolio_version
    prices {
      currency
      price_per_kg
    }
    allocated_weight_kg
    reserved_weight_kg
    is_published
    is_draft
    status
    created_date
  }
`;
export const PortfolioDraft_FromGetPortfolioDraftFragmentDoc = gql`
  fragment PortfolioDraft_fromGetPortfolioDraft on PortfolioDraft {
    id
    portfolio_draft {
      details {
        name
        description
        accounting_code
        currency
        key_facts
        portfolio_version
      }
      allocations {
        project_id
        project_name
        registry_id
        registry
        weight_tonnes
        vintage
        price_per_kg_in_gbp
        avoidance
        removal
        registry_fee_per_tonne
      }
      acl
    }
  }
`;
export const PortfolioDraft_FromUpsertPortfolioDraftFragmentDoc = gql`
  fragment PortfolioDraft_fromUpsertPortfolioDraft on PortfolioDraft {
    id
  }
`;
export const Project_FromGetProjectFragmentDoc = gql`
  fragment Project_fromGetProject on Project {
    id
    public_id
    name
    description
    website
    location
    removal
    avoidance
    country {
      id
      name
      code
    }
    coordinates {
      coordinates
    }
    project_type {
      id
      name
      category
    }
    developer {
      id
      name
      description
      website
      media {
        location
        key
        bucket
      }
    }
    owner {
      id
      name
    }
    registry {
      id
      name
      website
      media {
        location
        key
        bucket
      }
    }
    certifications {
      id
      name
      media {
        location
        key
        bucket
      }
    }
    carbonplan_assessment {
      data_stringify
    }
    sdgs
    media {
      location
      bucket
      eTag
      key
    }
    registry_project_id
    registry_project_url
    contact
    website
    created_date
    updated_date
  }
`;
export const Project_FromGetProjectsFragmentDoc = gql`
  fragment Project_fromGetProjects on Project {
    id
    name
    public_id
    project_type {
      name
      category
    }
    country {
      name
    }
    media {
      bucket
      key
    }
    registry {
      id
      name
    }
  }
`;
export const Project_FromUpsertProjectFragmentDoc = gql`
  fragment Project_fromUpsertProject on Project {
    id
    public_id
  }
`;
export const ProjectCertification_FromProjectCertificationFragmentDoc = gql`
  fragment ProjectCertification_fromProjectCertification on ProjectCertification {
    id
    name
    media {
      location
      bucket
      eTag
      key
    }
    description
    registry_link
    updated_date
  }
`;
export const ProjectCertification_FromGetProjectCertificationsFragmentDoc = gql`
  fragment ProjectCertification_fromGetProjectCertifications on ProjectCertification {
    id
    name
    media {
      location
      bucket
      eTag
      key
    }
  }
`;
export const ProjectCertification_FromUpsertProjectCertificationFragmentDoc = gql`
  fragment ProjectCertification_fromUpsertProjectCertification on ProjectCertification {
    id
  }
`;
export const ProjectDeveloper_FromGetProjectDeveloperFragmentDoc = gql`
  fragment ProjectDeveloper_fromGetProjectDeveloper on ProjectDeveloper {
    projects {
      name
      public_id
      project_type {
        name
        category
      }
      country {
        name
      }
    }
    media {
      location
      bucket
      eTag
      key
    }
    id
    name
    public_id
    description
    website
  }
`;
export const ProjectDeveloper_FromGetProjectDevelopersFragmentDoc = gql`
  fragment ProjectDeveloper_fromGetProjectDevelopers on ProjectDeveloper {
    projects {
      name
      public_id
    }
    media {
      location
      bucket
      key
    }
    name
    public_id
  }
`;
export const ProjectDeveloperNameId_FromGetProjectDevelopersFragmentDoc = gql`
  fragment ProjectDeveloperNameId_fromGetProjectDevelopers on ProjectDeveloper {
    name
    id
  }
`;
export const ProjectDeveloper_FromUpsertProjectDeveloperFragmentDoc = gql`
  fragment ProjectDeveloper_fromUpsertProjectDeveloper on ProjectDeveloper {
    id
  }
`;
export const ProjectOwner_FromGetProjectOwnerFragmentDoc = gql`
  fragment ProjectOwner_fromGetProjectOwner on ProjectOwner {
    id
    name
    description
    updated_date
  }
`;
export const ProjectOwner_FromGetProjectOwnersFragmentDoc = gql`
  fragment ProjectOwner_fromGetProjectOwners on ProjectOwner {
    id
    name
    description
  }
`;
export const ProjectOwner_FromUpsertProjectOwnerFragmentDoc = gql`
  fragment ProjectOwner_fromUpsertProjectOwner on ProjectOwner {
    id
  }
`;
export const ProjectType_FromGetProjectTypeFragmentDoc = gql`
  fragment ProjectType_fromGetProjectType on ProjectType {
    id
    name
    category
  }
`;
export const ProjectType_FromGetProjectTypesFragmentDoc = gql`
  fragment ProjectType_fromGetProjectTypes on ProjectType {
    name
    category
    id
  }
`;
export const ProjectType_FromUpsertProjectTypeFragmentDoc = gql`
  fragment ProjectType_fromUpsertProjectType on ProjectType {
    id
  }
`;
export const Registry_FromGetRegistriesFragmentDoc = gql`
  fragment Registry_fromGetRegistries on Registry {
    id
    public_id
    name
    website
    registry_retirement_fee_per_tonne
    media {
      location
      bucket
      eTag
      key
    }
  }
`;
export const Registry_FromGetRegistryFragmentDoc = gql`
  fragment Registry_fromGetRegistry on Registry {
    id
    public_id
    name
    website
    infrastructure
    projects_base_url
    has_automated_retirement
    registry_retirement_fee_per_tonne
    media {
      location
      bucket
      eTag
      key
    }
    updated_date
  }
`;
export const Registry_FromUpsertRegistryFragmentDoc = gql`
  fragment Registry_fromUpsertRegistry on Registry {
    id
    public_id
  }
`;
export const RetirementConfirmed_FromGetRetirementsConfirmedFragmentDoc = gql`
  fragment RetirementConfirmed_fromGetRetirementsConfirmed on RetirementConfirmed {
    project {
      name
      public_id
    }
    vintage
    registry {
      name
      public_id
    }
    retirement_id
    retirement_url
    weight_kg
    created_date
    serial_numbers
  }
`;
export const RetirementRequested_FromGetRetirementsRequestedFragmentDoc = gql`
  fragment RetirementRequested_fromGetRetirementsRequested on RetirementRequested {
    project {
      name
      public_id
    }
    registry {
      name
      public_id
    }
    retirement_id
    vintage
    weight_kg
    created_date
  }
`;
export const User_FromMeFragmentDoc = gql`
  fragment User_fromMe on User {
    sub
    username
    email_verified
    name
    email
  }
`;
export const GetInventoryReconciliationResultsDocument = gql`
  query getInventoryReconciliationResults {
    inventoryReconciliationResults {
      ...InventoryReconciliationResults_fromGetInventoryReconciliationResults
    }
  }
  ${InventoryReconciliationResults_FromGetInventoryReconciliationResultsFragmentDoc}
`;

/**
 * __useGetInventoryReconciliationResultsQuery__
 *
 * To run a query within a React component, call `useGetInventoryReconciliationResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventoryReconciliationResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventoryReconciliationResultsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInventoryReconciliationResultsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInventoryReconciliationResultsQuery,
    GetInventoryReconciliationResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInventoryReconciliationResultsQuery,
    GetInventoryReconciliationResultsQueryVariables
  >(GetInventoryReconciliationResultsDocument, options);
}
export function useGetInventoryReconciliationResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInventoryReconciliationResultsQuery,
    GetInventoryReconciliationResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInventoryReconciliationResultsQuery,
    GetInventoryReconciliationResultsQueryVariables
  >(GetInventoryReconciliationResultsDocument, options);
}
export function useGetInventoryReconciliationResultsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetInventoryReconciliationResultsQuery,
    GetInventoryReconciliationResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetInventoryReconciliationResultsQuery,
    GetInventoryReconciliationResultsQueryVariables
  >(GetInventoryReconciliationResultsDocument, options);
}
export type GetInventoryReconciliationResultsQueryHookResult = ReturnType<
  typeof useGetInventoryReconciliationResultsQuery
>;
export type GetInventoryReconciliationResultsLazyQueryHookResult = ReturnType<
  typeof useGetInventoryReconciliationResultsLazyQuery
>;
export type GetInventoryReconciliationResultsSuspenseQueryHookResult =
  ReturnType<typeof useGetInventoryReconciliationResultsSuspenseQuery>;
export type GetInventoryReconciliationResultsQueryResult = Apollo.QueryResult<
  GetInventoryReconciliationResultsQuery,
  GetInventoryReconciliationResultsQueryVariables
>;
export const GetApiKeysDocument = gql`
  query getApiKeys($client_id: String!) {
    api_keys(client_id: $client_id) {
      ...ApiKey_fromGetApiKeys
    }
  }
  ${ApiKey_FromGetApiKeysFragmentDoc}
`;

/**
 * __useGetApiKeysQuery__
 *
 * To run a query within a React component, call `useGetApiKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiKeysQuery({
 *   variables: {
 *      client_id: // value for 'client_id'
 *   },
 * });
 */
export function useGetApiKeysQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApiKeysQuery,
    GetApiKeysQueryVariables
  > &
    (
      | { variables: GetApiKeysQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetApiKeysQuery, GetApiKeysQueryVariables>(
    GetApiKeysDocument,
    options,
  );
}
export function useGetApiKeysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApiKeysQuery,
    GetApiKeysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetApiKeysQuery, GetApiKeysQueryVariables>(
    GetApiKeysDocument,
    options,
  );
}
export function useGetApiKeysSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetApiKeysQuery,
    GetApiKeysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetApiKeysQuery, GetApiKeysQueryVariables>(
    GetApiKeysDocument,
    options,
  );
}
export type GetApiKeysQueryHookResult = ReturnType<typeof useGetApiKeysQuery>;
export type GetApiKeysLazyQueryHookResult = ReturnType<
  typeof useGetApiKeysLazyQuery
>;
export type GetApiKeysSuspenseQueryHookResult = ReturnType<
  typeof useGetApiKeysSuspenseQuery
>;
export type GetApiKeysQueryResult = Apollo.QueryResult<
  GetApiKeysQuery,
  GetApiKeysQueryVariables
>;
export const GetApiLogsDocument = gql`
  query getAPILogs(
    $descend: Boolean
    $exclusive_start_key: String
    $client_id: String!
  ) {
    api_logs(
      descend: $descend
      exclusive_start_key: $exclusive_start_key
      client_id: $client_id
    ) {
      items {
        ...ApiLog_fromGetApiLogs
      }
      last_evaluated_key
    }
  }
  ${ApiLog_FromGetApiLogsFragmentDoc}
`;

/**
 * __useGetApiLogsQuery__
 *
 * To run a query within a React component, call `useGetApiLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApiLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApiLogsQuery({
 *   variables: {
 *      descend: // value for 'descend'
 *      exclusive_start_key: // value for 'exclusive_start_key'
 *      client_id: // value for 'client_id'
 *   },
 * });
 */
export function useGetApiLogsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetApiLogsQuery,
    GetApiLogsQueryVariables
  > &
    (
      | { variables: GetApiLogsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetApiLogsQuery, GetApiLogsQueryVariables>(
    GetApiLogsDocument,
    options,
  );
}
export function useGetApiLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetApiLogsQuery,
    GetApiLogsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetApiLogsQuery, GetApiLogsQueryVariables>(
    GetApiLogsDocument,
    options,
  );
}
export function useGetApiLogsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetApiLogsQuery,
    GetApiLogsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetApiLogsQuery, GetApiLogsQueryVariables>(
    GetApiLogsDocument,
    options,
  );
}
export type GetApiLogsQueryHookResult = ReturnType<typeof useGetApiLogsQuery>;
export type GetApiLogsLazyQueryHookResult = ReturnType<
  typeof useGetApiLogsLazyQuery
>;
export type GetApiLogsSuspenseQueryHookResult = ReturnType<
  typeof useGetApiLogsSuspenseQuery
>;
export type GetApiLogsQueryResult = Apollo.QueryResult<
  GetApiLogsQuery,
  GetApiLogsQueryVariables
>;
export const RollApiKeyDocument = gql`
  mutation RollApiKey($client_id: String!, $id: String!) {
    roll_api_key(id: $id, client_id: $client_id) {
      id
    }
  }
`;
export type RollApiKeyMutationFn = Apollo.MutationFunction<
  RollApiKeyMutation,
  RollApiKeyMutationVariables
>;

/**
 * __useRollApiKeyMutation__
 *
 * To run a mutation, you first call `useRollApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRollApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rollApiKeyMutation, { data, loading, error }] = useRollApiKeyMutation({
 *   variables: {
 *      client_id: // value for 'client_id'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRollApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RollApiKeyMutation,
    RollApiKeyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RollApiKeyMutation, RollApiKeyMutationVariables>(
    RollApiKeyDocument,
    options,
  );
}
export type RollApiKeyMutationHookResult = ReturnType<
  typeof useRollApiKeyMutation
>;
export type RollApiKeyMutationResult =
  Apollo.MutationResult<RollApiKeyMutation>;
export type RollApiKeyMutationOptions = Apollo.BaseMutationOptions<
  RollApiKeyMutation,
  RollApiKeyMutationVariables
>;
export const CreateClientDocument = gql`
  mutation CreateClient($data: CreateClientInput!) {
    create_client(data: $data) {
      ...Client_fromCreateClient
    }
  }
  ${Client_FromCreateClientFragmentDoc}
`;
export type CreateClientMutationFn = Apollo.MutationFunction<
  CreateClientMutation,
  CreateClientMutationVariables
>;

/**
 * __useCreateClientMutation__
 *
 * To run a mutation, you first call `useCreateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientMutation, { data, loading, error }] = useCreateClientMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClientMutation,
    CreateClientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClientMutation,
    CreateClientMutationVariables
  >(CreateClientDocument, options);
}
export type CreateClientMutationHookResult = ReturnType<
  typeof useCreateClientMutation
>;
export type CreateClientMutationResult =
  Apollo.MutationResult<CreateClientMutation>;
export type CreateClientMutationOptions = Apollo.BaseMutationOptions<
  CreateClientMutation,
  CreateClientMutationVariables
>;
export const DeleteClientUserDocument = gql`
  mutation DeleteClientUser($id: String!) {
    delete_client_user(id: $id)
  }
`;
export type DeleteClientUserMutationFn = Apollo.MutationFunction<
  DeleteClientUserMutation,
  DeleteClientUserMutationVariables
>;

/**
 * __useDeleteClientUserMutation__
 *
 * To run a mutation, you first call `useDeleteClientUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientUserMutation, { data, loading, error }] = useDeleteClientUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClientUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClientUserMutation,
    DeleteClientUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClientUserMutation,
    DeleteClientUserMutationVariables
  >(DeleteClientUserDocument, options);
}
export type DeleteClientUserMutationHookResult = ReturnType<
  typeof useDeleteClientUserMutation
>;
export type DeleteClientUserMutationResult =
  Apollo.MutationResult<DeleteClientUserMutation>;
export type DeleteClientUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteClientUserMutation,
  DeleteClientUserMutationVariables
>;
export const GetClientDocument = gql`
  query getClient($id: String, $publicId: String) {
    client(id: $id, public_id: $publicId) {
      ...Client_fromGetClient
    }
  }
  ${Client_FromGetClientFragmentDoc}
`;

/**
 * __useGetClientQuery__
 *
 * To run a query within a React component, call `useGetClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientQuery({
 *   variables: {
 *      id: // value for 'id'
 *      publicId: // value for 'publicId'
 *   },
 * });
 */
export function useGetClientQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClientQuery,
    GetClientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientQuery, GetClientQueryVariables>(
    GetClientDocument,
    options,
  );
}
export function useGetClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientQuery,
    GetClientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientQuery, GetClientQueryVariables>(
    GetClientDocument,
    options,
  );
}
export function useGetClientSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetClientQuery,
    GetClientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetClientQuery, GetClientQueryVariables>(
    GetClientDocument,
    options,
  );
}
export type GetClientQueryHookResult = ReturnType<typeof useGetClientQuery>;
export type GetClientLazyQueryHookResult = ReturnType<
  typeof useGetClientLazyQuery
>;
export type GetClientSuspenseQueryHookResult = ReturnType<
  typeof useGetClientSuspenseQuery
>;
export type GetClientQueryResult = Apollo.QueryResult<
  GetClientQuery,
  GetClientQueryVariables
>;
export const GetClientIntegrationsDocument = gql`
  query getClientIntegrations($client_id: String!) {
    client_integrations(client_id: $client_id) {
      ...ClientIntegration_fromFetClientIntegrations
    }
  }
  ${ClientIntegration_FromFetClientIntegrationsFragmentDoc}
`;

/**
 * __useGetClientIntegrationsQuery__
 *
 * To run a query within a React component, call `useGetClientIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientIntegrationsQuery({
 *   variables: {
 *      client_id: // value for 'client_id'
 *   },
 * });
 */
export function useGetClientIntegrationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientIntegrationsQuery,
    GetClientIntegrationsQueryVariables
  > &
    (
      | { variables: GetClientIntegrationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientIntegrationsQuery,
    GetClientIntegrationsQueryVariables
  >(GetClientIntegrationsDocument, options);
}
export function useGetClientIntegrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientIntegrationsQuery,
    GetClientIntegrationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientIntegrationsQuery,
    GetClientIntegrationsQueryVariables
  >(GetClientIntegrationsDocument, options);
}
export function useGetClientIntegrationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetClientIntegrationsQuery,
    GetClientIntegrationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetClientIntegrationsQuery,
    GetClientIntegrationsQueryVariables
  >(GetClientIntegrationsDocument, options);
}
export type GetClientIntegrationsQueryHookResult = ReturnType<
  typeof useGetClientIntegrationsQuery
>;
export type GetClientIntegrationsLazyQueryHookResult = ReturnType<
  typeof useGetClientIntegrationsLazyQuery
>;
export type GetClientIntegrationsSuspenseQueryHookResult = ReturnType<
  typeof useGetClientIntegrationsSuspenseQuery
>;
export type GetClientIntegrationsQueryResult = Apollo.QueryResult<
  GetClientIntegrationsQuery,
  GetClientIntegrationsQueryVariables
>;
export const GetClientsDocument = gql`
  query getClients($page: Int, $size: Int) {
    clients(page: $page, size: $size) {
      items {
        ...Client_fromGetClients
      }
      count
    }
  }
  ${Client_FromGetClientsFragmentDoc}
`;

/**
 * __useGetClientsQuery__
 *
 * To run a query within a React component, call `useGetClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useGetClientsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClientsQuery,
    GetClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientsQuery, GetClientsQueryVariables>(
    GetClientsDocument,
    options,
  );
}
export function useGetClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientsQuery,
    GetClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientsQuery, GetClientsQueryVariables>(
    GetClientsDocument,
    options,
  );
}
export function useGetClientsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetClientsQuery,
    GetClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetClientsQuery, GetClientsQueryVariables>(
    GetClientsDocument,
    options,
  );
}
export type GetClientsQueryHookResult = ReturnType<typeof useGetClientsQuery>;
export type GetClientsLazyQueryHookResult = ReturnType<
  typeof useGetClientsLazyQuery
>;
export type GetClientsSuspenseQueryHookResult = ReturnType<
  typeof useGetClientsSuspenseQuery
>;
export type GetClientsQueryResult = Apollo.QueryResult<
  GetClientsQuery,
  GetClientsQueryVariables
>;
export const GetClientsIdNameDocument = gql`
  query getClientsIdName($page: Int, $size: Int) {
    clients(page: $page, size: $size) {
      items {
        ...Client_fromGetClientsIdName
      }
      count
    }
  }
  ${Client_FromGetClientsIdNameFragmentDoc}
`;

/**
 * __useGetClientsIdNameQuery__
 *
 * To run a query within a React component, call `useGetClientsIdNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsIdNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsIdNameQuery({
 *   variables: {
 *      page: // value for 'page'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useGetClientsIdNameQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClientsIdNameQuery,
    GetClientsIdNameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientsIdNameQuery, GetClientsIdNameQueryVariables>(
    GetClientsIdNameDocument,
    options,
  );
}
export function useGetClientsIdNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientsIdNameQuery,
    GetClientsIdNameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientsIdNameQuery,
    GetClientsIdNameQueryVariables
  >(GetClientsIdNameDocument, options);
}
export function useGetClientsIdNameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetClientsIdNameQuery,
    GetClientsIdNameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetClientsIdNameQuery,
    GetClientsIdNameQueryVariables
  >(GetClientsIdNameDocument, options);
}
export type GetClientsIdNameQueryHookResult = ReturnType<
  typeof useGetClientsIdNameQuery
>;
export type GetClientsIdNameLazyQueryHookResult = ReturnType<
  typeof useGetClientsIdNameLazyQuery
>;
export type GetClientsIdNameSuspenseQueryHookResult = ReturnType<
  typeof useGetClientsIdNameSuspenseQuery
>;
export type GetClientsIdNameQueryResult = Apollo.QueryResult<
  GetClientsIdNameQuery,
  GetClientsIdNameQueryVariables
>;
export const TransferClientOwnershipDocument = gql`
  mutation TransferClientOwnership($id: String!) {
    transfer_ownership(new_owner_client_user_id: $id)
  }
`;
export type TransferClientOwnershipMutationFn = Apollo.MutationFunction<
  TransferClientOwnershipMutation,
  TransferClientOwnershipMutationVariables
>;

/**
 * __useTransferClientOwnershipMutation__
 *
 * To run a mutation, you first call `useTransferClientOwnershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferClientOwnershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferClientOwnershipMutation, { data, loading, error }] = useTransferClientOwnershipMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTransferClientOwnershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TransferClientOwnershipMutation,
    TransferClientOwnershipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TransferClientOwnershipMutation,
    TransferClientOwnershipMutationVariables
  >(TransferClientOwnershipDocument, options);
}
export type TransferClientOwnershipMutationHookResult = ReturnType<
  typeof useTransferClientOwnershipMutation
>;
export type TransferClientOwnershipMutationResult =
  Apollo.MutationResult<TransferClientOwnershipMutation>;
export type TransferClientOwnershipMutationOptions = Apollo.BaseMutationOptions<
  TransferClientOwnershipMutation,
  TransferClientOwnershipMutationVariables
>;
export const UpdateClientDocument = gql`
  mutation UpdateClient($id: String!, $data: UpdateClientInput!) {
    update_client(id: $id, data: $data) {
      ...Client_fromUpdateClient
    }
  }
  ${Client_FromUpdateClientFragmentDoc}
`;
export type UpdateClientMutationFn = Apollo.MutationFunction<
  UpdateClientMutation,
  UpdateClientMutationVariables
>;

/**
 * __useUpdateClientMutation__
 *
 * To run a mutation, you first call `useUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientMutation, { data, loading, error }] = useUpdateClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClientMutation,
    UpdateClientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClientMutation,
    UpdateClientMutationVariables
  >(UpdateClientDocument, options);
}
export type UpdateClientMutationHookResult = ReturnType<
  typeof useUpdateClientMutation
>;
export type UpdateClientMutationResult =
  Apollo.MutationResult<UpdateClientMutation>;
export type UpdateClientMutationOptions = Apollo.BaseMutationOptions<
  UpdateClientMutation,
  UpdateClientMutationVariables
>;
export const UpsertClientIntegrationDocument = gql`
  mutation UpsertClientIntegration($data: ClientIntegrationInput!) {
    upsert_client_integration(data: $data) {
      ...ClientIntegration_fromUpsertClientIntegration
    }
  }
  ${ClientIntegration_FromUpsertClientIntegrationFragmentDoc}
`;
export type UpsertClientIntegrationMutationFn = Apollo.MutationFunction<
  UpsertClientIntegrationMutation,
  UpsertClientIntegrationMutationVariables
>;

/**
 * __useUpsertClientIntegrationMutation__
 *
 * To run a mutation, you first call `useUpsertClientIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertClientIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertClientIntegrationMutation, { data, loading, error }] = useUpsertClientIntegrationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertClientIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertClientIntegrationMutation,
    UpsertClientIntegrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertClientIntegrationMutation,
    UpsertClientIntegrationMutationVariables
  >(UpsertClientIntegrationDocument, options);
}
export type UpsertClientIntegrationMutationHookResult = ReturnType<
  typeof useUpsertClientIntegrationMutation
>;
export type UpsertClientIntegrationMutationResult =
  Apollo.MutationResult<UpsertClientIntegrationMutation>;
export type UpsertClientIntegrationMutationOptions = Apollo.BaseMutationOptions<
  UpsertClientIntegrationMutation,
  UpsertClientIntegrationMutationVariables
>;
export const UploadFileDocument = gql`
  mutation UploadFile($file: Upload!, $folder: S3FolderEnum!) {
    upload_file(file: $file, folder: $folder) {
      location
      eTag
      bucket
      key
    }
  }
`;
export type UploadFileMutationFn = Apollo.MutationFunction<
  UploadFileMutation,
  UploadFileMutationVariables
>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *      folder: // value for 'folder'
 *   },
 * });
 */
export function useUploadFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadFileMutation,
    UploadFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(
    UploadFileDocument,
    options,
  );
}
export type UploadFileMutationHookResult = ReturnType<
  typeof useUploadFileMutation
>;
export type UploadFileMutationResult =
  Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<
  UploadFileMutation,
  UploadFileMutationVariables
>;
export const GetCountriesDocument = gql`
  query getCountries {
    countries {
      ...Country_fromGetCountries
    }
  }
  ${Country_FromGetCountriesFragmentDoc}
`;

/**
 * __useGetCountriesQuery__
 *
 * To run a query within a React component, call `useGetCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCountriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCountriesQuery,
    GetCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCountriesQuery, GetCountriesQueryVariables>(
    GetCountriesDocument,
    options,
  );
}
export function useGetCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCountriesQuery,
    GetCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCountriesQuery, GetCountriesQueryVariables>(
    GetCountriesDocument,
    options,
  );
}
export function useGetCountriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCountriesQuery,
    GetCountriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCountriesQuery, GetCountriesQueryVariables>(
    GetCountriesDocument,
    options,
  );
}
export type GetCountriesQueryHookResult = ReturnType<
  typeof useGetCountriesQuery
>;
export type GetCountriesLazyQueryHookResult = ReturnType<
  typeof useGetCountriesLazyQuery
>;
export type GetCountriesSuspenseQueryHookResult = ReturnType<
  typeof useGetCountriesSuspenseQuery
>;
export type GetCountriesQueryResult = Apollo.QueryResult<
  GetCountriesQuery,
  GetCountriesQueryVariables
>;
export const LinkImportToRequestDocument = gql`
  mutation linkImportToRequest($data: LinkImportToRequestInput!) {
    link_import_to_request(data: $data)
  }
`;
export type LinkImportToRequestMutationFn = Apollo.MutationFunction<
  LinkImportToRequestMutation,
  LinkImportToRequestMutationVariables
>;

/**
 * __useLinkImportToRequestMutation__
 *
 * To run a mutation, you first call `useLinkImportToRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkImportToRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkImportToRequestMutation, { data, loading, error }] = useLinkImportToRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLinkImportToRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkImportToRequestMutation,
    LinkImportToRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LinkImportToRequestMutation,
    LinkImportToRequestMutationVariables
  >(LinkImportToRequestDocument, options);
}
export type LinkImportToRequestMutationHookResult = ReturnType<
  typeof useLinkImportToRequestMutation
>;
export type LinkImportToRequestMutationResult =
  Apollo.MutationResult<LinkImportToRequestMutation>;
export type LinkImportToRequestMutationOptions = Apollo.BaseMutationOptions<
  LinkImportToRequestMutation,
  LinkImportToRequestMutationVariables
>;
export const TransitionDataCollectionRequestDocument = gql`
  mutation transitionDataCollectionRequest(
    $data: TransitionDataCollectionRequestInput!
  ) {
    transition_data_collection_request(data: $data)
  }
`;
export type TransitionDataCollectionRequestMutationFn = Apollo.MutationFunction<
  TransitionDataCollectionRequestMutation,
  TransitionDataCollectionRequestMutationVariables
>;

/**
 * __useTransitionDataCollectionRequestMutation__
 *
 * To run a mutation, you first call `useTransitionDataCollectionRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransitionDataCollectionRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transitionDataCollectionRequestMutation, { data, loading, error }] = useTransitionDataCollectionRequestMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTransitionDataCollectionRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TransitionDataCollectionRequestMutation,
    TransitionDataCollectionRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TransitionDataCollectionRequestMutation,
    TransitionDataCollectionRequestMutationVariables
  >(TransitionDataCollectionRequestDocument, options);
}
export type TransitionDataCollectionRequestMutationHookResult = ReturnType<
  typeof useTransitionDataCollectionRequestMutation
>;
export type TransitionDataCollectionRequestMutationResult =
  Apollo.MutationResult<TransitionDataCollectionRequestMutation>;
export type TransitionDataCollectionRequestMutationOptions =
  Apollo.BaseMutationOptions<
    TransitionDataCollectionRequestMutation,
    TransitionDataCollectionRequestMutationVariables
  >;
export const AddInventoryDocument = gql`
  mutation AddInventory($data: AddInventoryInput!) {
    add_inventory(data: $data)
  }
`;
export type AddInventoryMutationFn = Apollo.MutationFunction<
  AddInventoryMutation,
  AddInventoryMutationVariables
>;

/**
 * __useAddInventoryMutation__
 *
 * To run a mutation, you first call `useAddInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInventoryMutation, { data, loading, error }] = useAddInventoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddInventoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddInventoryMutation,
    AddInventoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddInventoryMutation,
    AddInventoryMutationVariables
  >(AddInventoryDocument, options);
}
export type AddInventoryMutationHookResult = ReturnType<
  typeof useAddInventoryMutation
>;
export type AddInventoryMutationResult =
  Apollo.MutationResult<AddInventoryMutation>;
export type AddInventoryMutationOptions = Apollo.BaseMutationOptions<
  AddInventoryMutation,
  AddInventoryMutationVariables
>;
export const GetAvailableInventoryDocument = gql`
  query getAvailableInventory {
    available_inventory {
      ...Inventory_fromGetAvailableInventory
    }
  }
  ${Inventory_FromGetAvailableInventoryFragmentDoc}
`;

/**
 * __useGetAvailableInventoryQuery__
 *
 * To run a query within a React component, call `useGetAvailableInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableInventoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAvailableInventoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAvailableInventoryQuery,
    GetAvailableInventoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAvailableInventoryQuery,
    GetAvailableInventoryQueryVariables
  >(GetAvailableInventoryDocument, options);
}
export function useGetAvailableInventoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAvailableInventoryQuery,
    GetAvailableInventoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAvailableInventoryQuery,
    GetAvailableInventoryQueryVariables
  >(GetAvailableInventoryDocument, options);
}
export function useGetAvailableInventorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAvailableInventoryQuery,
    GetAvailableInventoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAvailableInventoryQuery,
    GetAvailableInventoryQueryVariables
  >(GetAvailableInventoryDocument, options);
}
export type GetAvailableInventoryQueryHookResult = ReturnType<
  typeof useGetAvailableInventoryQuery
>;
export type GetAvailableInventoryLazyQueryHookResult = ReturnType<
  typeof useGetAvailableInventoryLazyQuery
>;
export type GetAvailableInventorySuspenseQueryHookResult = ReturnType<
  typeof useGetAvailableInventorySuspenseQuery
>;
export type GetAvailableInventoryQueryResult = Apollo.QueryResult<
  GetAvailableInventoryQuery,
  GetAvailableInventoryQueryVariables
>;
export const GetInventoryDocument = gql`
  query getInventory(
    $page: Int!
    $size: Int!
    $vintages: [String!]
    $projectPublicIds: [String!]
    $registryPublicIds: [String!]
    $sortedColumn: InventorySortedColumnEnum
    $sortedOrder: SortedOrderEnum
  ) {
    inventory(
      page: $page
      size: $size
      vintages: $vintages
      project_public_ids: $projectPublicIds
      registry_public_ids: $registryPublicIds
      sorted_column: $sortedColumn
      sorted_order: $sortedOrder
    ) {
      items {
        ...Inventory_fromGetInventory
      }
      total_count
    }
  }
  ${Inventory_FromGetInventoryFragmentDoc}
`;

/**
 * __useGetInventoryQuery__
 *
 * To run a query within a React component, call `useGetInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventoryQuery({
 *   variables: {
 *      page: // value for 'page'
 *      size: // value for 'size'
 *      vintages: // value for 'vintages'
 *      projectPublicIds: // value for 'projectPublicIds'
 *      registryPublicIds: // value for 'registryPublicIds'
 *      sortedColumn: // value for 'sortedColumn'
 *      sortedOrder: // value for 'sortedOrder'
 *   },
 * });
 */
export function useGetInventoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInventoryQuery,
    GetInventoryQueryVariables
  > &
    (
      | { variables: GetInventoryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInventoryQuery, GetInventoryQueryVariables>(
    GetInventoryDocument,
    options,
  );
}
export function useGetInventoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInventoryQuery,
    GetInventoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInventoryQuery, GetInventoryQueryVariables>(
    GetInventoryDocument,
    options,
  );
}
export function useGetInventorySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetInventoryQuery,
    GetInventoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetInventoryQuery, GetInventoryQueryVariables>(
    GetInventoryDocument,
    options,
  );
}
export type GetInventoryQueryHookResult = ReturnType<
  typeof useGetInventoryQuery
>;
export type GetInventoryLazyQueryHookResult = ReturnType<
  typeof useGetInventoryLazyQuery
>;
export type GetInventorySuspenseQueryHookResult = ReturnType<
  typeof useGetInventorySuspenseQuery
>;
export type GetInventoryQueryResult = Apollo.QueryResult<
  GetInventoryQuery,
  GetInventoryQueryVariables
>;
export const GetInventoryPropertyValuesDocument = gql`
  query getInventoryPropertyValues(
    $key: InventoryFilterPropertiesEnum!
    $value: [String!]!
  ) {
    inventory_property_values(key: $key, value: $value) {
      value
      label
      count
    }
  }
`;

/**
 * __useGetInventoryPropertyValuesQuery__
 *
 * To run a query within a React component, call `useGetInventoryPropertyValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventoryPropertyValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventoryPropertyValuesQuery({
 *   variables: {
 *      key: // value for 'key'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useGetInventoryPropertyValuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInventoryPropertyValuesQuery,
    GetInventoryPropertyValuesQueryVariables
  > &
    (
      | { variables: GetInventoryPropertyValuesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInventoryPropertyValuesQuery,
    GetInventoryPropertyValuesQueryVariables
  >(GetInventoryPropertyValuesDocument, options);
}
export function useGetInventoryPropertyValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInventoryPropertyValuesQuery,
    GetInventoryPropertyValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInventoryPropertyValuesQuery,
    GetInventoryPropertyValuesQueryVariables
  >(GetInventoryPropertyValuesDocument, options);
}
export function useGetInventoryPropertyValuesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetInventoryPropertyValuesQuery,
    GetInventoryPropertyValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetInventoryPropertyValuesQuery,
    GetInventoryPropertyValuesQueryVariables
  >(GetInventoryPropertyValuesDocument, options);
}
export type GetInventoryPropertyValuesQueryHookResult = ReturnType<
  typeof useGetInventoryPropertyValuesQuery
>;
export type GetInventoryPropertyValuesLazyQueryHookResult = ReturnType<
  typeof useGetInventoryPropertyValuesLazyQuery
>;
export type GetInventoryPropertyValuesSuspenseQueryHookResult = ReturnType<
  typeof useGetInventoryPropertyValuesSuspenseQuery
>;
export type GetInventoryPropertyValuesQueryResult = Apollo.QueryResult<
  GetInventoryPropertyValuesQuery,
  GetInventoryPropertyValuesQueryVariables
>;
export const RemoveInventoryDocument = gql`
  mutation RemoveInventory($data: RemoveInventoryInput!) {
    remove_inventory(data: $data)
  }
`;
export type RemoveInventoryMutationFn = Apollo.MutationFunction<
  RemoveInventoryMutation,
  RemoveInventoryMutationVariables
>;

/**
 * __useRemoveInventoryMutation__
 *
 * To run a mutation, you first call `useRemoveInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeInventoryMutation, { data, loading, error }] = useRemoveInventoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveInventoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveInventoryMutation,
    RemoveInventoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveInventoryMutation,
    RemoveInventoryMutationVariables
  >(RemoveInventoryDocument, options);
}
export type RemoveInventoryMutationHookResult = ReturnType<
  typeof useRemoveInventoryMutation
>;
export type RemoveInventoryMutationResult =
  Apollo.MutationResult<RemoveInventoryMutation>;
export type RemoveInventoryMutationOptions = Apollo.BaseMutationOptions<
  RemoveInventoryMutation,
  RemoveInventoryMutationVariables
>;
export const GetInventoryEventDocument = gql`
  query getInventoryEvent($id: String!) {
    inventory_event(id: $id) {
      ...InventoryEvent_fromGetInventoryEvent
    }
  }
  ${InventoryEvent_FromGetInventoryEventFragmentDoc}
`;

/**
 * __useGetInventoryEventQuery__
 *
 * To run a query within a React component, call `useGetInventoryEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventoryEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventoryEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInventoryEventQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInventoryEventQuery,
    GetInventoryEventQueryVariables
  > &
    (
      | { variables: GetInventoryEventQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInventoryEventQuery,
    GetInventoryEventQueryVariables
  >(GetInventoryEventDocument, options);
}
export function useGetInventoryEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInventoryEventQuery,
    GetInventoryEventQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInventoryEventQuery,
    GetInventoryEventQueryVariables
  >(GetInventoryEventDocument, options);
}
export function useGetInventoryEventSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetInventoryEventQuery,
    GetInventoryEventQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetInventoryEventQuery,
    GetInventoryEventQueryVariables
  >(GetInventoryEventDocument, options);
}
export type GetInventoryEventQueryHookResult = ReturnType<
  typeof useGetInventoryEventQuery
>;
export type GetInventoryEventLazyQueryHookResult = ReturnType<
  typeof useGetInventoryEventLazyQuery
>;
export type GetInventoryEventSuspenseQueryHookResult = ReturnType<
  typeof useGetInventoryEventSuspenseQuery
>;
export type GetInventoryEventQueryResult = Apollo.QueryResult<
  GetInventoryEventQuery,
  GetInventoryEventQueryVariables
>;
export const GetInventoryEventsDocument = gql`
  query getInventoryEvents(
    $page: Int!
    $size: Int!
    $vintages: [String!]
    $projectPublicIds: [String!]
    $eventTypes: [InventoryEventTypeEnum!]
    $sortedColumn: InventoryEventsSortedColumnEnum
    $sortedOrder: SortedOrderEnum
  ) {
    inventory_events(
      page: $page
      size: $size
      vintages: $vintages
      project_public_ids: $projectPublicIds
      event_types: $eventTypes
      sorted_column: $sortedColumn
      sorted_order: $sortedOrder
    ) {
      items {
        ...InventoryEvent_fromGetInventoryEvents
      }
      total_count
    }
  }
  ${InventoryEvent_FromGetInventoryEventsFragmentDoc}
`;

/**
 * __useGetInventoryEventsQuery__
 *
 * To run a query within a React component, call `useGetInventoryEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventoryEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventoryEventsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      size: // value for 'size'
 *      vintages: // value for 'vintages'
 *      projectPublicIds: // value for 'projectPublicIds'
 *      eventTypes: // value for 'eventTypes'
 *      sortedColumn: // value for 'sortedColumn'
 *      sortedOrder: // value for 'sortedOrder'
 *   },
 * });
 */
export function useGetInventoryEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInventoryEventsQuery,
    GetInventoryEventsQueryVariables
  > &
    (
      | { variables: GetInventoryEventsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInventoryEventsQuery,
    GetInventoryEventsQueryVariables
  >(GetInventoryEventsDocument, options);
}
export function useGetInventoryEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInventoryEventsQuery,
    GetInventoryEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInventoryEventsQuery,
    GetInventoryEventsQueryVariables
  >(GetInventoryEventsDocument, options);
}
export function useGetInventoryEventsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetInventoryEventsQuery,
    GetInventoryEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetInventoryEventsQuery,
    GetInventoryEventsQueryVariables
  >(GetInventoryEventsDocument, options);
}
export type GetInventoryEventsQueryHookResult = ReturnType<
  typeof useGetInventoryEventsQuery
>;
export type GetInventoryEventsLazyQueryHookResult = ReturnType<
  typeof useGetInventoryEventsLazyQuery
>;
export type GetInventoryEventsSuspenseQueryHookResult = ReturnType<
  typeof useGetInventoryEventsSuspenseQuery
>;
export type GetInventoryEventsQueryResult = Apollo.QueryResult<
  GetInventoryEventsQuery,
  GetInventoryEventsQueryVariables
>;
export const GetInventoryEventsPropertyValuesDocument = gql`
  query getInventoryEventsPropertyValues(
    $key: InventoryEventsFilterPropertiesEnum!
    $value: [String!]!
    $projectPublicIds: [String!]
  ) {
    inventory_events_property_values(
      key: $key
      value: $value
      project_public_ids: $projectPublicIds
    ) {
      value
      label
      count
    }
  }
`;

/**
 * __useGetInventoryEventsPropertyValuesQuery__
 *
 * To run a query within a React component, call `useGetInventoryEventsPropertyValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventoryEventsPropertyValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventoryEventsPropertyValuesQuery({
 *   variables: {
 *      key: // value for 'key'
 *      value: // value for 'value'
 *      projectPublicIds: // value for 'projectPublicIds'
 *   },
 * });
 */
export function useGetInventoryEventsPropertyValuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInventoryEventsPropertyValuesQuery,
    GetInventoryEventsPropertyValuesQueryVariables
  > &
    (
      | {
          variables: GetInventoryEventsPropertyValuesQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInventoryEventsPropertyValuesQuery,
    GetInventoryEventsPropertyValuesQueryVariables
  >(GetInventoryEventsPropertyValuesDocument, options);
}
export function useGetInventoryEventsPropertyValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInventoryEventsPropertyValuesQuery,
    GetInventoryEventsPropertyValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInventoryEventsPropertyValuesQuery,
    GetInventoryEventsPropertyValuesQueryVariables
  >(GetInventoryEventsPropertyValuesDocument, options);
}
export function useGetInventoryEventsPropertyValuesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetInventoryEventsPropertyValuesQuery,
    GetInventoryEventsPropertyValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetInventoryEventsPropertyValuesQuery,
    GetInventoryEventsPropertyValuesQueryVariables
  >(GetInventoryEventsPropertyValuesDocument, options);
}
export type GetInventoryEventsPropertyValuesQueryHookResult = ReturnType<
  typeof useGetInventoryEventsPropertyValuesQuery
>;
export type GetInventoryEventsPropertyValuesLazyQueryHookResult = ReturnType<
  typeof useGetInventoryEventsPropertyValuesLazyQuery
>;
export type GetInventoryEventsPropertyValuesSuspenseQueryHookResult =
  ReturnType<typeof useGetInventoryEventsPropertyValuesSuspenseQuery>;
export type GetInventoryEventsPropertyValuesQueryResult = Apollo.QueryResult<
  GetInventoryEventsPropertyValuesQuery,
  GetInventoryEventsPropertyValuesQueryVariables
>;
export const GetOrderCo2eRangeDocument = gql`
  query getOrderCo2eRange {
    order_co2e_range {
      min
      max
      histogram {
        key
        doc_count
      }
    }
  }
`;

/**
 * __useGetOrderCo2eRangeQuery__
 *
 * To run a query within a React component, call `useGetOrderCo2eRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderCo2eRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderCo2eRangeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrderCo2eRangeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrderCo2eRangeQuery,
    GetOrderCo2eRangeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrderCo2eRangeQuery,
    GetOrderCo2eRangeQueryVariables
  >(GetOrderCo2eRangeDocument, options);
}
export function useGetOrderCo2eRangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderCo2eRangeQuery,
    GetOrderCo2eRangeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrderCo2eRangeQuery,
    GetOrderCo2eRangeQueryVariables
  >(GetOrderCo2eRangeDocument, options);
}
export function useGetOrderCo2eRangeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrderCo2eRangeQuery,
    GetOrderCo2eRangeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetOrderCo2eRangeQuery,
    GetOrderCo2eRangeQueryVariables
  >(GetOrderCo2eRangeDocument, options);
}
export type GetOrderCo2eRangeQueryHookResult = ReturnType<
  typeof useGetOrderCo2eRangeQuery
>;
export type GetOrderCo2eRangeLazyQueryHookResult = ReturnType<
  typeof useGetOrderCo2eRangeLazyQuery
>;
export type GetOrderCo2eRangeSuspenseQueryHookResult = ReturnType<
  typeof useGetOrderCo2eRangeSuspenseQuery
>;
export type GetOrderCo2eRangeQueryResult = Apollo.QueryResult<
  GetOrderCo2eRangeQuery,
  GetOrderCo2eRangeQueryVariables
>;
export const GetOrderPropertyValuesDocument = gql`
  query getOrderPropertyValues(
    $key: OrderFilterPropertiesEnum!
    $value: String!
  ) {
    order_property_values(key: $key, value: $value) {
      value
      count
    }
  }
`;

/**
 * __useGetOrderPropertyValuesQuery__
 *
 * To run a query within a React component, call `useGetOrderPropertyValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderPropertyValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderPropertyValuesQuery({
 *   variables: {
 *      key: // value for 'key'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useGetOrderPropertyValuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrderPropertyValuesQuery,
    GetOrderPropertyValuesQueryVariables
  > &
    (
      | { variables: GetOrderPropertyValuesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOrderPropertyValuesQuery,
    GetOrderPropertyValuesQueryVariables
  >(GetOrderPropertyValuesDocument, options);
}
export function useGetOrderPropertyValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrderPropertyValuesQuery,
    GetOrderPropertyValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOrderPropertyValuesQuery,
    GetOrderPropertyValuesQueryVariables
  >(GetOrderPropertyValuesDocument, options);
}
export function useGetOrderPropertyValuesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrderPropertyValuesQuery,
    GetOrderPropertyValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetOrderPropertyValuesQuery,
    GetOrderPropertyValuesQueryVariables
  >(GetOrderPropertyValuesDocument, options);
}
export type GetOrderPropertyValuesQueryHookResult = ReturnType<
  typeof useGetOrderPropertyValuesQuery
>;
export type GetOrderPropertyValuesLazyQueryHookResult = ReturnType<
  typeof useGetOrderPropertyValuesLazyQuery
>;
export type GetOrderPropertyValuesSuspenseQueryHookResult = ReturnType<
  typeof useGetOrderPropertyValuesSuspenseQuery
>;
export type GetOrderPropertyValuesQueryResult = Apollo.QueryResult<
  GetOrderPropertyValuesQuery,
  GetOrderPropertyValuesQueryVariables
>;
export const GetOrdersDocument = gql`
  query getOrders(
    $page: Int!
    $size: Int!
    $publicIds: [String!]
    $clientPublicIds: [String!]
    $clientLegalNames: [String!]
    $portfolioPublicIds: [String!]
    $portfolioNames: [String!]
    $invoiceNumbers: [String!]
    $status: [OrderStatusEnum!]
    $co2e: RangeInput!
    $sortedColumn: OrderSortedColumnEnum
    $sortedOrder: SortedOrderEnum
  ) {
    orders(
      page: $page
      size: $size
      public_ids: $publicIds
      client_public_ids: $clientPublicIds
      client_legal_names: $clientLegalNames
      portfolio_public_ids: $portfolioPublicIds
      portfolio_names: $portfolioNames
      invoice_numbers: $invoiceNumbers
      status: $status
      co2e: $co2e
      sorted_column: $sortedColumn
      sorted_order: $sortedOrder
    ) {
      items {
        ...Order_fromGetOrders
      }
      total_count
      total_co2e
      pagination_limit
    }
  }
  ${Order_FromGetOrdersFragmentDoc}
`;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      size: // value for 'size'
 *      publicIds: // value for 'publicIds'
 *      clientPublicIds: // value for 'clientPublicIds'
 *      clientLegalNames: // value for 'clientLegalNames'
 *      portfolioPublicIds: // value for 'portfolioPublicIds'
 *      portfolioNames: // value for 'portfolioNames'
 *      invoiceNumbers: // value for 'invoiceNumbers'
 *      status: // value for 'status'
 *      co2e: // value for 'co2e'
 *      sortedColumn: // value for 'sortedColumn'
 *      sortedOrder: // value for 'sortedOrder'
 *   },
 * });
 */
export function useGetOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrdersQuery,
    GetOrdersQueryVariables
  > &
    (
      | { variables: GetOrdersQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(
    GetOrdersDocument,
    options,
  );
}
export function useGetOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrdersQuery,
    GetOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(
    GetOrdersDocument,
    options,
  );
}
export function useGetOrdersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetOrdersQuery,
    GetOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetOrdersQuery, GetOrdersQueryVariables>(
    GetOrdersDocument,
    options,
  );
}
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<
  typeof useGetOrdersLazyQuery
>;
export type GetOrdersSuspenseQueryHookResult = ReturnType<
  typeof useGetOrdersSuspenseQuery
>;
export type GetOrdersQueryResult = Apollo.QueryResult<
  GetOrdersQuery,
  GetOrdersQueryVariables
>;
export const GetPortfolioDocument = gql`
  query getPortfolio($id: String, $public_id: String) {
    portfolio(id: $id, public_id: $public_id) {
      portfolio {
        ...Portfolio_fromGetPortfolio
      }
      keyFacts {
        ...KeyFact_fromGetPortfolio
      }
      projects {
        ...Project_fromGetPortfolio
      }
    }
  }
  ${Portfolio_FromGetPortfolioFragmentDoc}
  ${KeyFact_FromGetPortfolioFragmentDoc}
  ${Project_FromGetPortfolioFragmentDoc}
`;

/**
 * __useGetPortfolioQuery__
 *
 * To run a query within a React component, call `useGetPortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortfolioQuery({
 *   variables: {
 *      id: // value for 'id'
 *      public_id: // value for 'public_id'
 *   },
 * });
 */
export function useGetPortfolioQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPortfolioQuery,
    GetPortfolioQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPortfolioQuery, GetPortfolioQueryVariables>(
    GetPortfolioDocument,
    options,
  );
}
export function useGetPortfolioLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPortfolioQuery,
    GetPortfolioQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPortfolioQuery, GetPortfolioQueryVariables>(
    GetPortfolioDocument,
    options,
  );
}
export function useGetPortfolioSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPortfolioQuery,
    GetPortfolioQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetPortfolioQuery, GetPortfolioQueryVariables>(
    GetPortfolioDocument,
    options,
  );
}
export type GetPortfolioQueryHookResult = ReturnType<
  typeof useGetPortfolioQuery
>;
export type GetPortfolioLazyQueryHookResult = ReturnType<
  typeof useGetPortfolioLazyQuery
>;
export type GetPortfolioSuspenseQueryHookResult = ReturnType<
  typeof useGetPortfolioSuspenseQuery
>;
export type GetPortfolioQueryResult = Apollo.QueryResult<
  GetPortfolioQuery,
  GetPortfolioQueryVariables
>;
export const GetPortfolioStatsDocument = gql`
  query getPortfolioStats($id: String!) {
    portfolio_stats(id: $id) {
      ...Portfolio_fromGetPortfolioStats
    }
  }
  ${Portfolio_FromGetPortfolioStatsFragmentDoc}
`;

/**
 * __useGetPortfolioStatsQuery__
 *
 * To run a query within a React component, call `useGetPortfolioStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortfolioStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortfolioStatsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPortfolioStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPortfolioStatsQuery,
    GetPortfolioStatsQueryVariables
  > &
    (
      | { variables: GetPortfolioStatsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPortfolioStatsQuery,
    GetPortfolioStatsQueryVariables
  >(GetPortfolioStatsDocument, options);
}
export function useGetPortfolioStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPortfolioStatsQuery,
    GetPortfolioStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPortfolioStatsQuery,
    GetPortfolioStatsQueryVariables
  >(GetPortfolioStatsDocument, options);
}
export function useGetPortfolioStatsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPortfolioStatsQuery,
    GetPortfolioStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPortfolioStatsQuery,
    GetPortfolioStatsQueryVariables
  >(GetPortfolioStatsDocument, options);
}
export type GetPortfolioStatsQueryHookResult = ReturnType<
  typeof useGetPortfolioStatsQuery
>;
export type GetPortfolioStatsLazyQueryHookResult = ReturnType<
  typeof useGetPortfolioStatsLazyQuery
>;
export type GetPortfolioStatsSuspenseQueryHookResult = ReturnType<
  typeof useGetPortfolioStatsSuspenseQuery
>;
export type GetPortfolioStatsQueryResult = Apollo.QueryResult<
  GetPortfolioStatsQuery,
  GetPortfolioStatsQueryVariables
>;
export const GetPortfoliosDocument = gql`
  query getPortfolios(
    $page: Int!
    $size: Int!
    $orderColumn: PortfolioOrderColumnEnum!
    $orderDirection: OrderDirectionEnum!
  ) {
    portfolios(
      page: $page
      size: $size
      order_column: $orderColumn
      order_direction: $orderDirection
    ) {
      items {
        ...PortfolioDataMapper_fromGetPortfolios
      }
      total_count
    }
  }
  ${PortfolioDataMapper_FromGetPortfoliosFragmentDoc}
`;

/**
 * __useGetPortfoliosQuery__
 *
 * To run a query within a React component, call `useGetPortfoliosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortfoliosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortfoliosQuery({
 *   variables: {
 *      page: // value for 'page'
 *      size: // value for 'size'
 *      orderColumn: // value for 'orderColumn'
 *      orderDirection: // value for 'orderDirection'
 *   },
 * });
 */
export function useGetPortfoliosQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPortfoliosQuery,
    GetPortfoliosQueryVariables
  > &
    (
      | { variables: GetPortfoliosQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPortfoliosQuery, GetPortfoliosQueryVariables>(
    GetPortfoliosDocument,
    options,
  );
}
export function useGetPortfoliosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPortfoliosQuery,
    GetPortfoliosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPortfoliosQuery, GetPortfoliosQueryVariables>(
    GetPortfoliosDocument,
    options,
  );
}
export function useGetPortfoliosSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPortfoliosQuery,
    GetPortfoliosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPortfoliosQuery,
    GetPortfoliosQueryVariables
  >(GetPortfoliosDocument, options);
}
export type GetPortfoliosQueryHookResult = ReturnType<
  typeof useGetPortfoliosQuery
>;
export type GetPortfoliosLazyQueryHookResult = ReturnType<
  typeof useGetPortfoliosLazyQuery
>;
export type GetPortfoliosSuspenseQueryHookResult = ReturnType<
  typeof useGetPortfoliosSuspenseQuery
>;
export type GetPortfoliosQueryResult = Apollo.QueryResult<
  GetPortfoliosQuery,
  GetPortfoliosQueryVariables
>;
export const PublishPortfolioDocument = gql`
  mutation publishPortfolio($id: String!) {
    publish_portfolio(id: $id)
  }
`;
export type PublishPortfolioMutationFn = Apollo.MutationFunction<
  PublishPortfolioMutation,
  PublishPortfolioMutationVariables
>;

/**
 * __usePublishPortfolioMutation__
 *
 * To run a mutation, you first call `usePublishPortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishPortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishPortfolioMutation, { data, loading, error }] = usePublishPortfolioMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishPortfolioMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishPortfolioMutation,
    PublishPortfolioMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PublishPortfolioMutation,
    PublishPortfolioMutationVariables
  >(PublishPortfolioDocument, options);
}
export type PublishPortfolioMutationHookResult = ReturnType<
  typeof usePublishPortfolioMutation
>;
export type PublishPortfolioMutationResult =
  Apollo.MutationResult<PublishPortfolioMutation>;
export type PublishPortfolioMutationOptions = Apollo.BaseMutationOptions<
  PublishPortfolioMutation,
  PublishPortfolioMutationVariables
>;
export const UnpublishPortfolioDocument = gql`
  mutation unpublishPortfolio($id: String!) {
    unpublish_portfolio(id: $id)
  }
`;
export type UnpublishPortfolioMutationFn = Apollo.MutationFunction<
  UnpublishPortfolioMutation,
  UnpublishPortfolioMutationVariables
>;

/**
 * __useUnpublishPortfolioMutation__
 *
 * To run a mutation, you first call `useUnpublishPortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishPortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishPortfolioMutation, { data, loading, error }] = useUnpublishPortfolioMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnpublishPortfolioMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnpublishPortfolioMutation,
    UnpublishPortfolioMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnpublishPortfolioMutation,
    UnpublishPortfolioMutationVariables
  >(UnpublishPortfolioDocument, options);
}
export type UnpublishPortfolioMutationHookResult = ReturnType<
  typeof useUnpublishPortfolioMutation
>;
export type UnpublishPortfolioMutationResult =
  Apollo.MutationResult<UnpublishPortfolioMutation>;
export type UnpublishPortfolioMutationOptions = Apollo.BaseMutationOptions<
  UnpublishPortfolioMutation,
  UnpublishPortfolioMutationVariables
>;
export const CreatePortfolioDocument = gql`
  mutation CreatePortfolio($data: PortfolioDraftInput!) {
    create_portfolio(data: $data)
  }
`;
export type CreatePortfolioMutationFn = Apollo.MutationFunction<
  CreatePortfolioMutation,
  CreatePortfolioMutationVariables
>;

/**
 * __useCreatePortfolioMutation__
 *
 * To run a mutation, you first call `useCreatePortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPortfolioMutation, { data, loading, error }] = useCreatePortfolioMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePortfolioMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePortfolioMutation,
    CreatePortfolioMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePortfolioMutation,
    CreatePortfolioMutationVariables
  >(CreatePortfolioDocument, options);
}
export type CreatePortfolioMutationHookResult = ReturnType<
  typeof useCreatePortfolioMutation
>;
export type CreatePortfolioMutationResult =
  Apollo.MutationResult<CreatePortfolioMutation>;
export type CreatePortfolioMutationOptions = Apollo.BaseMutationOptions<
  CreatePortfolioMutation,
  CreatePortfolioMutationVariables
>;
export const GetPortfolioDraftDocument = gql`
  query getPortfolioDraft($id: String!) {
    portfolio_draft(id: $id) {
      ...PortfolioDraft_fromGetPortfolioDraft
    }
  }
  ${PortfolioDraft_FromGetPortfolioDraftFragmentDoc}
`;

/**
 * __useGetPortfolioDraftQuery__
 *
 * To run a query within a React component, call `useGetPortfolioDraftQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortfolioDraftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortfolioDraftQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPortfolioDraftQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPortfolioDraftQuery,
    GetPortfolioDraftQueryVariables
  > &
    (
      | { variables: GetPortfolioDraftQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPortfolioDraftQuery,
    GetPortfolioDraftQueryVariables
  >(GetPortfolioDraftDocument, options);
}
export function useGetPortfolioDraftLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPortfolioDraftQuery,
    GetPortfolioDraftQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPortfolioDraftQuery,
    GetPortfolioDraftQueryVariables
  >(GetPortfolioDraftDocument, options);
}
export function useGetPortfolioDraftSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPortfolioDraftQuery,
    GetPortfolioDraftQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPortfolioDraftQuery,
    GetPortfolioDraftQueryVariables
  >(GetPortfolioDraftDocument, options);
}
export type GetPortfolioDraftQueryHookResult = ReturnType<
  typeof useGetPortfolioDraftQuery
>;
export type GetPortfolioDraftLazyQueryHookResult = ReturnType<
  typeof useGetPortfolioDraftLazyQuery
>;
export type GetPortfolioDraftSuspenseQueryHookResult = ReturnType<
  typeof useGetPortfolioDraftSuspenseQuery
>;
export type GetPortfolioDraftQueryResult = Apollo.QueryResult<
  GetPortfolioDraftQuery,
  GetPortfolioDraftQueryVariables
>;
export const UpsertPortfolioDraftDocument = gql`
  mutation UpsertPortfolioDraft($data: PortfolioDraftInput!) {
    upsert_portfolio_draft(data: $data) {
      ...PortfolioDraft_fromUpsertPortfolioDraft
    }
  }
  ${PortfolioDraft_FromUpsertPortfolioDraftFragmentDoc}
`;
export type UpsertPortfolioDraftMutationFn = Apollo.MutationFunction<
  UpsertPortfolioDraftMutation,
  UpsertPortfolioDraftMutationVariables
>;

/**
 * __useUpsertPortfolioDraftMutation__
 *
 * To run a mutation, you first call `useUpsertPortfolioDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPortfolioDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPortfolioDraftMutation, { data, loading, error }] = useUpsertPortfolioDraftMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertPortfolioDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertPortfolioDraftMutation,
    UpsertPortfolioDraftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertPortfolioDraftMutation,
    UpsertPortfolioDraftMutationVariables
  >(UpsertPortfolioDraftDocument, options);
}
export type UpsertPortfolioDraftMutationHookResult = ReturnType<
  typeof useUpsertPortfolioDraftMutation
>;
export type UpsertPortfolioDraftMutationResult =
  Apollo.MutationResult<UpsertPortfolioDraftMutation>;
export type UpsertPortfolioDraftMutationOptions = Apollo.BaseMutationOptions<
  UpsertPortfolioDraftMutation,
  UpsertPortfolioDraftMutationVariables
>;
export const GetProjectDocument = gql`
  query getProject($id: String, $public_id: String) {
    project(id: $id, public_id: $public_id) {
      ...Project_fromGetProject
    }
  }
  ${Project_FromGetProjectFragmentDoc}
`;

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *      public_id: // value for 'public_id'
 *   },
 * });
 */
export function useGetProjectQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProjectQuery,
    GetProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectQuery, GetProjectQueryVariables>(
    GetProjectDocument,
    options,
  );
}
export function useGetProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectQuery,
    GetProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(
    GetProjectDocument,
    options,
  );
}
export function useGetProjectSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectQuery,
    GetProjectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetProjectQuery, GetProjectQueryVariables>(
    GetProjectDocument,
    options,
  );
}
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<
  typeof useGetProjectLazyQuery
>;
export type GetProjectSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectSuspenseQuery
>;
export type GetProjectQueryResult = Apollo.QueryResult<
  GetProjectQuery,
  GetProjectQueryVariables
>;
export const GetProjectPropertyValuesDocument = gql`
  query getProjectPropertyValues(
    $key: ProjectFilterPropertiesEnum!
    $value: [String!]!
  ) {
    project_property_values(key: $key, value: $value) {
      value
      label
      count
    }
  }
`;

/**
 * __useGetProjectPropertyValuesQuery__
 *
 * To run a query within a React component, call `useGetProjectPropertyValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectPropertyValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectPropertyValuesQuery({
 *   variables: {
 *      key: // value for 'key'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useGetProjectPropertyValuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectPropertyValuesQuery,
    GetProjectPropertyValuesQueryVariables
  > &
    (
      | { variables: GetProjectPropertyValuesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectPropertyValuesQuery,
    GetProjectPropertyValuesQueryVariables
  >(GetProjectPropertyValuesDocument, options);
}
export function useGetProjectPropertyValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectPropertyValuesQuery,
    GetProjectPropertyValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectPropertyValuesQuery,
    GetProjectPropertyValuesQueryVariables
  >(GetProjectPropertyValuesDocument, options);
}
export function useGetProjectPropertyValuesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectPropertyValuesQuery,
    GetProjectPropertyValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectPropertyValuesQuery,
    GetProjectPropertyValuesQueryVariables
  >(GetProjectPropertyValuesDocument, options);
}
export type GetProjectPropertyValuesQueryHookResult = ReturnType<
  typeof useGetProjectPropertyValuesQuery
>;
export type GetProjectPropertyValuesLazyQueryHookResult = ReturnType<
  typeof useGetProjectPropertyValuesLazyQuery
>;
export type GetProjectPropertyValuesSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectPropertyValuesSuspenseQuery
>;
export type GetProjectPropertyValuesQueryResult = Apollo.QueryResult<
  GetProjectPropertyValuesQuery,
  GetProjectPropertyValuesQueryVariables
>;
export const GetProjectsDocument = gql`
  query getProjects(
    $projectName: String
    $projectPublicIds: [String!]
    $page: Int
    $size: Int
    $sortedColumn: ProjectSortedColumnEnum
    $sortedOrder: SortedOrderEnum
  ) {
    projects(
      project_name: $projectName
      project_public_ids: $projectPublicIds
      page: $page
      size: $size
      sorted_column: $sortedColumn
      sorted_order: $sortedOrder
    ) {
      items {
        ...Project_fromGetProjects
      }
      total_count
    }
  }
  ${Project_FromGetProjectsFragmentDoc}
`;

/**
 * __useGetProjectsQuery__
 *
 * To run a query within a React component, call `useGetProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      projectPublicIds: // value for 'projectPublicIds'
 *      page: // value for 'page'
 *      size: // value for 'size'
 *      sortedColumn: // value for 'sortedColumn'
 *      sortedOrder: // value for 'sortedOrder'
 *   },
 * });
 */
export function useGetProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProjectsQuery,
    GetProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(
    GetProjectsDocument,
    options,
  );
}
export function useGetProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectsQuery,
    GetProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(
    GetProjectsDocument,
    options,
  );
}
export function useGetProjectsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectsQuery,
    GetProjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetProjectsQuery, GetProjectsQueryVariables>(
    GetProjectsDocument,
    options,
  );
}
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsLazyQueryHookResult = ReturnType<
  typeof useGetProjectsLazyQuery
>;
export type GetProjectsSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectsSuspenseQuery
>;
export type GetProjectsQueryResult = Apollo.QueryResult<
  GetProjectsQuery,
  GetProjectsQueryVariables
>;
export const UpsertProjectDocument = gql`
  mutation UpsertProject($data: UpsertProjectInput!) {
    upsert_project(data: $data) {
      ...Project_fromUpsertProject
    }
  }
  ${Project_FromUpsertProjectFragmentDoc}
`;
export type UpsertProjectMutationFn = Apollo.MutationFunction<
  UpsertProjectMutation,
  UpsertProjectMutationVariables
>;

/**
 * __useUpsertProjectMutation__
 *
 * To run a mutation, you first call `useUpsertProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProjectMutation, { data, loading, error }] = useUpsertProjectMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertProjectMutation,
    UpsertProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertProjectMutation,
    UpsertProjectMutationVariables
  >(UpsertProjectDocument, options);
}
export type UpsertProjectMutationHookResult = ReturnType<
  typeof useUpsertProjectMutation
>;
export type UpsertProjectMutationResult =
  Apollo.MutationResult<UpsertProjectMutation>;
export type UpsertProjectMutationOptions = Apollo.BaseMutationOptions<
  UpsertProjectMutation,
  UpsertProjectMutationVariables
>;
export const GetProjectCertificationDocument = gql`
  query getProjectCertification($id: String!) {
    project_certification(id: $id) {
      ...ProjectCertification_fromProjectCertification
    }
  }
  ${ProjectCertification_FromProjectCertificationFragmentDoc}
`;

/**
 * __useGetProjectCertificationQuery__
 *
 * To run a query within a React component, call `useGetProjectCertificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectCertificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectCertificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectCertificationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectCertificationQuery,
    GetProjectCertificationQueryVariables
  > &
    (
      | { variables: GetProjectCertificationQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectCertificationQuery,
    GetProjectCertificationQueryVariables
  >(GetProjectCertificationDocument, options);
}
export function useGetProjectCertificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectCertificationQuery,
    GetProjectCertificationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectCertificationQuery,
    GetProjectCertificationQueryVariables
  >(GetProjectCertificationDocument, options);
}
export function useGetProjectCertificationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectCertificationQuery,
    GetProjectCertificationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectCertificationQuery,
    GetProjectCertificationQueryVariables
  >(GetProjectCertificationDocument, options);
}
export type GetProjectCertificationQueryHookResult = ReturnType<
  typeof useGetProjectCertificationQuery
>;
export type GetProjectCertificationLazyQueryHookResult = ReturnType<
  typeof useGetProjectCertificationLazyQuery
>;
export type GetProjectCertificationSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectCertificationSuspenseQuery
>;
export type GetProjectCertificationQueryResult = Apollo.QueryResult<
  GetProjectCertificationQuery,
  GetProjectCertificationQueryVariables
>;
export const GetProjectCertificationsDocument = gql`
  query getProjectCertifications {
    project_certifications {
      ...ProjectCertification_fromGetProjectCertifications
    }
  }
  ${ProjectCertification_FromGetProjectCertificationsFragmentDoc}
`;

/**
 * __useGetProjectCertificationsQuery__
 *
 * To run a query within a React component, call `useGetProjectCertificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectCertificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectCertificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectCertificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProjectCertificationsQuery,
    GetProjectCertificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectCertificationsQuery,
    GetProjectCertificationsQueryVariables
  >(GetProjectCertificationsDocument, options);
}
export function useGetProjectCertificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectCertificationsQuery,
    GetProjectCertificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectCertificationsQuery,
    GetProjectCertificationsQueryVariables
  >(GetProjectCertificationsDocument, options);
}
export function useGetProjectCertificationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectCertificationsQuery,
    GetProjectCertificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectCertificationsQuery,
    GetProjectCertificationsQueryVariables
  >(GetProjectCertificationsDocument, options);
}
export type GetProjectCertificationsQueryHookResult = ReturnType<
  typeof useGetProjectCertificationsQuery
>;
export type GetProjectCertificationsLazyQueryHookResult = ReturnType<
  typeof useGetProjectCertificationsLazyQuery
>;
export type GetProjectCertificationsSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectCertificationsSuspenseQuery
>;
export type GetProjectCertificationsQueryResult = Apollo.QueryResult<
  GetProjectCertificationsQuery,
  GetProjectCertificationsQueryVariables
>;
export const UpsertProjectCertificationDocument = gql`
  mutation UpsertProjectCertification($data: UpsertProjectCertificationInput!) {
    upsert_project_certification(data: $data) {
      ...ProjectCertification_fromUpsertProjectCertification
    }
  }
  ${ProjectCertification_FromUpsertProjectCertificationFragmentDoc}
`;
export type UpsertProjectCertificationMutationFn = Apollo.MutationFunction<
  UpsertProjectCertificationMutation,
  UpsertProjectCertificationMutationVariables
>;

/**
 * __useUpsertProjectCertificationMutation__
 *
 * To run a mutation, you first call `useUpsertProjectCertificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProjectCertificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProjectCertificationMutation, { data, loading, error }] = useUpsertProjectCertificationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertProjectCertificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertProjectCertificationMutation,
    UpsertProjectCertificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertProjectCertificationMutation,
    UpsertProjectCertificationMutationVariables
  >(UpsertProjectCertificationDocument, options);
}
export type UpsertProjectCertificationMutationHookResult = ReturnType<
  typeof useUpsertProjectCertificationMutation
>;
export type UpsertProjectCertificationMutationResult =
  Apollo.MutationResult<UpsertProjectCertificationMutation>;
export type UpsertProjectCertificationMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertProjectCertificationMutation,
    UpsertProjectCertificationMutationVariables
  >;
export const GetProjectDeveloperDocument = gql`
  query getProjectDeveloper($public_id: String!) {
    project_developer(public_id: $public_id) {
      ...ProjectDeveloper_fromGetProjectDeveloper
    }
  }
  ${ProjectDeveloper_FromGetProjectDeveloperFragmentDoc}
`;

/**
 * __useGetProjectDeveloperQuery__
 *
 * To run a query within a React component, call `useGetProjectDeveloperQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectDeveloperQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectDeveloperQuery({
 *   variables: {
 *      public_id: // value for 'public_id'
 *   },
 * });
 */
export function useGetProjectDeveloperQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectDeveloperQuery,
    GetProjectDeveloperQueryVariables
  > &
    (
      | { variables: GetProjectDeveloperQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectDeveloperQuery,
    GetProjectDeveloperQueryVariables
  >(GetProjectDeveloperDocument, options);
}
export function useGetProjectDeveloperLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectDeveloperQuery,
    GetProjectDeveloperQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectDeveloperQuery,
    GetProjectDeveloperQueryVariables
  >(GetProjectDeveloperDocument, options);
}
export function useGetProjectDeveloperSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectDeveloperQuery,
    GetProjectDeveloperQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectDeveloperQuery,
    GetProjectDeveloperQueryVariables
  >(GetProjectDeveloperDocument, options);
}
export type GetProjectDeveloperQueryHookResult = ReturnType<
  typeof useGetProjectDeveloperQuery
>;
export type GetProjectDeveloperLazyQueryHookResult = ReturnType<
  typeof useGetProjectDeveloperLazyQuery
>;
export type GetProjectDeveloperSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectDeveloperSuspenseQuery
>;
export type GetProjectDeveloperQueryResult = Apollo.QueryResult<
  GetProjectDeveloperQuery,
  GetProjectDeveloperQueryVariables
>;
export const GetProjectDevelopersDocument = gql`
  query getProjectDevelopers {
    project_developers {
      ...ProjectDeveloper_fromGetProjectDevelopers
    }
  }
  ${ProjectDeveloper_FromGetProjectDevelopersFragmentDoc}
`;

/**
 * __useGetProjectDevelopersQuery__
 *
 * To run a query within a React component, call `useGetProjectDevelopersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectDevelopersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectDevelopersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectDevelopersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProjectDevelopersQuery,
    GetProjectDevelopersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectDevelopersQuery,
    GetProjectDevelopersQueryVariables
  >(GetProjectDevelopersDocument, options);
}
export function useGetProjectDevelopersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectDevelopersQuery,
    GetProjectDevelopersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectDevelopersQuery,
    GetProjectDevelopersQueryVariables
  >(GetProjectDevelopersDocument, options);
}
export function useGetProjectDevelopersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectDevelopersQuery,
    GetProjectDevelopersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectDevelopersQuery,
    GetProjectDevelopersQueryVariables
  >(GetProjectDevelopersDocument, options);
}
export type GetProjectDevelopersQueryHookResult = ReturnType<
  typeof useGetProjectDevelopersQuery
>;
export type GetProjectDevelopersLazyQueryHookResult = ReturnType<
  typeof useGetProjectDevelopersLazyQuery
>;
export type GetProjectDevelopersSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectDevelopersSuspenseQuery
>;
export type GetProjectDevelopersQueryResult = Apollo.QueryResult<
  GetProjectDevelopersQuery,
  GetProjectDevelopersQueryVariables
>;
export const GetProjectDevelopersNameIdDocument = gql`
  query getProjectDevelopersNameId {
    project_developers {
      ...ProjectDeveloperNameId_fromGetProjectDevelopers
    }
  }
  ${ProjectDeveloperNameId_FromGetProjectDevelopersFragmentDoc}
`;

/**
 * __useGetProjectDevelopersNameIdQuery__
 *
 * To run a query within a React component, call `useGetProjectDevelopersNameIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectDevelopersNameIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectDevelopersNameIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectDevelopersNameIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProjectDevelopersNameIdQuery,
    GetProjectDevelopersNameIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectDevelopersNameIdQuery,
    GetProjectDevelopersNameIdQueryVariables
  >(GetProjectDevelopersNameIdDocument, options);
}
export function useGetProjectDevelopersNameIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectDevelopersNameIdQuery,
    GetProjectDevelopersNameIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectDevelopersNameIdQuery,
    GetProjectDevelopersNameIdQueryVariables
  >(GetProjectDevelopersNameIdDocument, options);
}
export function useGetProjectDevelopersNameIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectDevelopersNameIdQuery,
    GetProjectDevelopersNameIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectDevelopersNameIdQuery,
    GetProjectDevelopersNameIdQueryVariables
  >(GetProjectDevelopersNameIdDocument, options);
}
export type GetProjectDevelopersNameIdQueryHookResult = ReturnType<
  typeof useGetProjectDevelopersNameIdQuery
>;
export type GetProjectDevelopersNameIdLazyQueryHookResult = ReturnType<
  typeof useGetProjectDevelopersNameIdLazyQuery
>;
export type GetProjectDevelopersNameIdSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectDevelopersNameIdSuspenseQuery
>;
export type GetProjectDevelopersNameIdQueryResult = Apollo.QueryResult<
  GetProjectDevelopersNameIdQuery,
  GetProjectDevelopersNameIdQueryVariables
>;
export const UpsertProjectDeveloperDocument = gql`
  mutation UpsertProjectDeveloper($data: UpsertDeveloperInput!) {
    upsert_project_developer(data: $data) {
      ...ProjectDeveloper_fromUpsertProjectDeveloper
    }
  }
  ${ProjectDeveloper_FromUpsertProjectDeveloperFragmentDoc}
`;
export type UpsertProjectDeveloperMutationFn = Apollo.MutationFunction<
  UpsertProjectDeveloperMutation,
  UpsertProjectDeveloperMutationVariables
>;

/**
 * __useUpsertProjectDeveloperMutation__
 *
 * To run a mutation, you first call `useUpsertProjectDeveloperMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProjectDeveloperMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProjectDeveloperMutation, { data, loading, error }] = useUpsertProjectDeveloperMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertProjectDeveloperMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertProjectDeveloperMutation,
    UpsertProjectDeveloperMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertProjectDeveloperMutation,
    UpsertProjectDeveloperMutationVariables
  >(UpsertProjectDeveloperDocument, options);
}
export type UpsertProjectDeveloperMutationHookResult = ReturnType<
  typeof useUpsertProjectDeveloperMutation
>;
export type UpsertProjectDeveloperMutationResult =
  Apollo.MutationResult<UpsertProjectDeveloperMutation>;
export type UpsertProjectDeveloperMutationOptions = Apollo.BaseMutationOptions<
  UpsertProjectDeveloperMutation,
  UpsertProjectDeveloperMutationVariables
>;
export const GetProjectOwnerDocument = gql`
  query getProjectOwner($id: String!) {
    project_owner(id: $id) {
      ...ProjectOwner_fromGetProjectOwner
    }
  }
  ${ProjectOwner_FromGetProjectOwnerFragmentDoc}
`;

/**
 * __useGetProjectOwnerQuery__
 *
 * To run a query within a React component, call `useGetProjectOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectOwnerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectOwnerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectOwnerQuery,
    GetProjectOwnerQueryVariables
  > &
    (
      | { variables: GetProjectOwnerQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectOwnerQuery, GetProjectOwnerQueryVariables>(
    GetProjectOwnerDocument,
    options,
  );
}
export function useGetProjectOwnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectOwnerQuery,
    GetProjectOwnerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectOwnerQuery,
    GetProjectOwnerQueryVariables
  >(GetProjectOwnerDocument, options);
}
export function useGetProjectOwnerSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectOwnerQuery,
    GetProjectOwnerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectOwnerQuery,
    GetProjectOwnerQueryVariables
  >(GetProjectOwnerDocument, options);
}
export type GetProjectOwnerQueryHookResult = ReturnType<
  typeof useGetProjectOwnerQuery
>;
export type GetProjectOwnerLazyQueryHookResult = ReturnType<
  typeof useGetProjectOwnerLazyQuery
>;
export type GetProjectOwnerSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectOwnerSuspenseQuery
>;
export type GetProjectOwnerQueryResult = Apollo.QueryResult<
  GetProjectOwnerQuery,
  GetProjectOwnerQueryVariables
>;
export const GetProjectOwnersDocument = gql`
  query getProjectOwners {
    project_owners {
      ...ProjectOwner_fromGetProjectOwners
    }
  }
  ${ProjectOwner_FromGetProjectOwnersFragmentDoc}
`;

/**
 * __useGetProjectOwnersQuery__
 *
 * To run a query within a React component, call `useGetProjectOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectOwnersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectOwnersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProjectOwnersQuery,
    GetProjectOwnersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectOwnersQuery, GetProjectOwnersQueryVariables>(
    GetProjectOwnersDocument,
    options,
  );
}
export function useGetProjectOwnersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectOwnersQuery,
    GetProjectOwnersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectOwnersQuery,
    GetProjectOwnersQueryVariables
  >(GetProjectOwnersDocument, options);
}
export function useGetProjectOwnersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectOwnersQuery,
    GetProjectOwnersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectOwnersQuery,
    GetProjectOwnersQueryVariables
  >(GetProjectOwnersDocument, options);
}
export type GetProjectOwnersQueryHookResult = ReturnType<
  typeof useGetProjectOwnersQuery
>;
export type GetProjectOwnersLazyQueryHookResult = ReturnType<
  typeof useGetProjectOwnersLazyQuery
>;
export type GetProjectOwnersSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectOwnersSuspenseQuery
>;
export type GetProjectOwnersQueryResult = Apollo.QueryResult<
  GetProjectOwnersQuery,
  GetProjectOwnersQueryVariables
>;
export const UpsertProjectOwnerDocument = gql`
  mutation UpsertProjectOwner($data: ProjectOwnerInput!) {
    upsert_project_owner(data: $data) {
      ...ProjectOwner_fromUpsertProjectOwner
    }
  }
  ${ProjectOwner_FromUpsertProjectOwnerFragmentDoc}
`;
export type UpsertProjectOwnerMutationFn = Apollo.MutationFunction<
  UpsertProjectOwnerMutation,
  UpsertProjectOwnerMutationVariables
>;

/**
 * __useUpsertProjectOwnerMutation__
 *
 * To run a mutation, you first call `useUpsertProjectOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProjectOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProjectOwnerMutation, { data, loading, error }] = useUpsertProjectOwnerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertProjectOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertProjectOwnerMutation,
    UpsertProjectOwnerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertProjectOwnerMutation,
    UpsertProjectOwnerMutationVariables
  >(UpsertProjectOwnerDocument, options);
}
export type UpsertProjectOwnerMutationHookResult = ReturnType<
  typeof useUpsertProjectOwnerMutation
>;
export type UpsertProjectOwnerMutationResult =
  Apollo.MutationResult<UpsertProjectOwnerMutation>;
export type UpsertProjectOwnerMutationOptions = Apollo.BaseMutationOptions<
  UpsertProjectOwnerMutation,
  UpsertProjectOwnerMutationVariables
>;
export const GetProjectTypeDocument = gql`
  query getProjectType($id: String!) {
    project_type(id: $id) {
      ...ProjectType_fromGetProjectType
    }
  }
  ${ProjectType_FromGetProjectTypeFragmentDoc}
`;

/**
 * __useGetProjectTypeQuery__
 *
 * To run a query within a React component, call `useGetProjectTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectTypeQuery,
    GetProjectTypeQueryVariables
  > &
    (
      | { variables: GetProjectTypeQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectTypeQuery, GetProjectTypeQueryVariables>(
    GetProjectTypeDocument,
    options,
  );
}
export function useGetProjectTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectTypeQuery,
    GetProjectTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectTypeQuery, GetProjectTypeQueryVariables>(
    GetProjectTypeDocument,
    options,
  );
}
export function useGetProjectTypeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectTypeQuery,
    GetProjectTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectTypeQuery,
    GetProjectTypeQueryVariables
  >(GetProjectTypeDocument, options);
}
export type GetProjectTypeQueryHookResult = ReturnType<
  typeof useGetProjectTypeQuery
>;
export type GetProjectTypeLazyQueryHookResult = ReturnType<
  typeof useGetProjectTypeLazyQuery
>;
export type GetProjectTypeSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectTypeSuspenseQuery
>;
export type GetProjectTypeQueryResult = Apollo.QueryResult<
  GetProjectTypeQuery,
  GetProjectTypeQueryVariables
>;
export const GetProjectTypeCategoriesDocument = gql`
  query getProjectTypeCategories {
    project_type_categories {
      category
    }
  }
`;

/**
 * __useGetProjectTypeCategoriesQuery__
 *
 * To run a query within a React component, call `useGetProjectTypeCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTypeCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTypeCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectTypeCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProjectTypeCategoriesQuery,
    GetProjectTypeCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectTypeCategoriesQuery,
    GetProjectTypeCategoriesQueryVariables
  >(GetProjectTypeCategoriesDocument, options);
}
export function useGetProjectTypeCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectTypeCategoriesQuery,
    GetProjectTypeCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectTypeCategoriesQuery,
    GetProjectTypeCategoriesQueryVariables
  >(GetProjectTypeCategoriesDocument, options);
}
export function useGetProjectTypeCategoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectTypeCategoriesQuery,
    GetProjectTypeCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectTypeCategoriesQuery,
    GetProjectTypeCategoriesQueryVariables
  >(GetProjectTypeCategoriesDocument, options);
}
export type GetProjectTypeCategoriesQueryHookResult = ReturnType<
  typeof useGetProjectTypeCategoriesQuery
>;
export type GetProjectTypeCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetProjectTypeCategoriesLazyQuery
>;
export type GetProjectTypeCategoriesSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectTypeCategoriesSuspenseQuery
>;
export type GetProjectTypeCategoriesQueryResult = Apollo.QueryResult<
  GetProjectTypeCategoriesQuery,
  GetProjectTypeCategoriesQueryVariables
>;
export const GetProjectTypesDocument = gql`
  query getProjectTypes {
    project_types {
      ...ProjectType_fromGetProjectTypes
    }
  }
  ${ProjectType_FromGetProjectTypesFragmentDoc}
`;

/**
 * __useGetProjectTypesQuery__
 *
 * To run a query within a React component, call `useGetProjectTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProjectTypesQuery,
    GetProjectTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectTypesQuery, GetProjectTypesQueryVariables>(
    GetProjectTypesDocument,
    options,
  );
}
export function useGetProjectTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectTypesQuery,
    GetProjectTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectTypesQuery,
    GetProjectTypesQueryVariables
  >(GetProjectTypesDocument, options);
}
export function useGetProjectTypesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetProjectTypesQuery,
    GetProjectTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetProjectTypesQuery,
    GetProjectTypesQueryVariables
  >(GetProjectTypesDocument, options);
}
export type GetProjectTypesQueryHookResult = ReturnType<
  typeof useGetProjectTypesQuery
>;
export type GetProjectTypesLazyQueryHookResult = ReturnType<
  typeof useGetProjectTypesLazyQuery
>;
export type GetProjectTypesSuspenseQueryHookResult = ReturnType<
  typeof useGetProjectTypesSuspenseQuery
>;
export type GetProjectTypesQueryResult = Apollo.QueryResult<
  GetProjectTypesQuery,
  GetProjectTypesQueryVariables
>;
export const UpsertProjectTypeDocument = gql`
  mutation UpsertProjectType($data: ProjectTypeInput!) {
    upsert_project_type(data: $data) {
      ...ProjectType_fromUpsertProjectType
    }
  }
  ${ProjectType_FromUpsertProjectTypeFragmentDoc}
`;
export type UpsertProjectTypeMutationFn = Apollo.MutationFunction<
  UpsertProjectTypeMutation,
  UpsertProjectTypeMutationVariables
>;

/**
 * __useUpsertProjectTypeMutation__
 *
 * To run a mutation, you first call `useUpsertProjectTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProjectTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProjectTypeMutation, { data, loading, error }] = useUpsertProjectTypeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertProjectTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertProjectTypeMutation,
    UpsertProjectTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertProjectTypeMutation,
    UpsertProjectTypeMutationVariables
  >(UpsertProjectTypeDocument, options);
}
export type UpsertProjectTypeMutationHookResult = ReturnType<
  typeof useUpsertProjectTypeMutation
>;
export type UpsertProjectTypeMutationResult =
  Apollo.MutationResult<UpsertProjectTypeMutation>;
export type UpsertProjectTypeMutationOptions = Apollo.BaseMutationOptions<
  UpsertProjectTypeMutation,
  UpsertProjectTypeMutationVariables
>;
export const GetRegistriesDocument = gql`
  query getRegistries {
    registries {
      ...Registry_fromGetRegistries
    }
  }
  ${Registry_FromGetRegistriesFragmentDoc}
`;

/**
 * __useGetRegistriesQuery__
 *
 * To run a query within a React component, call `useGetRegistriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegistriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegistriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRegistriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRegistriesQuery,
    GetRegistriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRegistriesQuery, GetRegistriesQueryVariables>(
    GetRegistriesDocument,
    options,
  );
}
export function useGetRegistriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRegistriesQuery,
    GetRegistriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRegistriesQuery, GetRegistriesQueryVariables>(
    GetRegistriesDocument,
    options,
  );
}
export function useGetRegistriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetRegistriesQuery,
    GetRegistriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetRegistriesQuery,
    GetRegistriesQueryVariables
  >(GetRegistriesDocument, options);
}
export type GetRegistriesQueryHookResult = ReturnType<
  typeof useGetRegistriesQuery
>;
export type GetRegistriesLazyQueryHookResult = ReturnType<
  typeof useGetRegistriesLazyQuery
>;
export type GetRegistriesSuspenseQueryHookResult = ReturnType<
  typeof useGetRegistriesSuspenseQuery
>;
export type GetRegistriesQueryResult = Apollo.QueryResult<
  GetRegistriesQuery,
  GetRegistriesQueryVariables
>;
export const GetRegistryDocument = gql`
  query getRegistry($public_id: String!) {
    registry(public_id: $public_id) {
      ...Registry_fromGetRegistry
    }
  }
  ${Registry_FromGetRegistryFragmentDoc}
`;

/**
 * __useGetRegistryQuery__
 *
 * To run a query within a React component, call `useGetRegistryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegistryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegistryQuery({
 *   variables: {
 *      public_id: // value for 'public_id'
 *   },
 * });
 */
export function useGetRegistryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRegistryQuery,
    GetRegistryQueryVariables
  > &
    (
      | { variables: GetRegistryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRegistryQuery, GetRegistryQueryVariables>(
    GetRegistryDocument,
    options,
  );
}
export function useGetRegistryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRegistryQuery,
    GetRegistryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRegistryQuery, GetRegistryQueryVariables>(
    GetRegistryDocument,
    options,
  );
}
export function useGetRegistrySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetRegistryQuery,
    GetRegistryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetRegistryQuery, GetRegistryQueryVariables>(
    GetRegistryDocument,
    options,
  );
}
export type GetRegistryQueryHookResult = ReturnType<typeof useGetRegistryQuery>;
export type GetRegistryLazyQueryHookResult = ReturnType<
  typeof useGetRegistryLazyQuery
>;
export type GetRegistrySuspenseQueryHookResult = ReturnType<
  typeof useGetRegistrySuspenseQuery
>;
export type GetRegistryQueryResult = Apollo.QueryResult<
  GetRegistryQuery,
  GetRegistryQueryVariables
>;
export const UpsertRegistryDocument = gql`
  mutation UpsertRegistry($data: RegistryInput!) {
    upsert_registry(data: $data) {
      ...Registry_fromUpsertRegistry
    }
  }
  ${Registry_FromUpsertRegistryFragmentDoc}
`;
export type UpsertRegistryMutationFn = Apollo.MutationFunction<
  UpsertRegistryMutation,
  UpsertRegistryMutationVariables
>;

/**
 * __useUpsertRegistryMutation__
 *
 * To run a mutation, you first call `useUpsertRegistryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRegistryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRegistryMutation, { data, loading, error }] = useUpsertRegistryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertRegistryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertRegistryMutation,
    UpsertRegistryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertRegistryMutation,
    UpsertRegistryMutationVariables
  >(UpsertRegistryDocument, options);
}
export type UpsertRegistryMutationHookResult = ReturnType<
  typeof useUpsertRegistryMutation
>;
export type UpsertRegistryMutationResult =
  Apollo.MutationResult<UpsertRegistryMutation>;
export type UpsertRegistryMutationOptions = Apollo.BaseMutationOptions<
  UpsertRegistryMutation,
  UpsertRegistryMutationVariables
>;
export const ConfirmRetirementDocument = gql`
  mutation ConfirmRetirement($data: ConfirmRetirementInput!) {
    confirm_retirement(data: $data)
  }
`;
export type ConfirmRetirementMutationFn = Apollo.MutationFunction<
  ConfirmRetirementMutation,
  ConfirmRetirementMutationVariables
>;

/**
 * __useConfirmRetirementMutation__
 *
 * To run a mutation, you first call `useConfirmRetirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmRetirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmRetirementMutation, { data, loading, error }] = useConfirmRetirementMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useConfirmRetirementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmRetirementMutation,
    ConfirmRetirementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmRetirementMutation,
    ConfirmRetirementMutationVariables
  >(ConfirmRetirementDocument, options);
}
export type ConfirmRetirementMutationHookResult = ReturnType<
  typeof useConfirmRetirementMutation
>;
export type ConfirmRetirementMutationResult =
  Apollo.MutationResult<ConfirmRetirementMutation>;
export type ConfirmRetirementMutationOptions = Apollo.BaseMutationOptions<
  ConfirmRetirementMutation,
  ConfirmRetirementMutationVariables
>;
export const GetRetirementsConfirmedDocument = gql`
  query getRetirementsConfirmed(
    $page: Int!
    $size: Int!
    $sortedColumn: RetirementSortedColumnEnum
    $sortedOrder: SortedOrderEnum
  ) {
    retirements_confirmed(
      page: $page
      size: $size
      sorted_column: $sortedColumn
      sorted_order: $sortedOrder
    ) {
      items {
        ...RetirementConfirmed_fromGetRetirementsConfirmed
      }
      total_count
    }
  }
  ${RetirementConfirmed_FromGetRetirementsConfirmedFragmentDoc}
`;

/**
 * __useGetRetirementsConfirmedQuery__
 *
 * To run a query within a React component, call `useGetRetirementsConfirmedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRetirementsConfirmedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRetirementsConfirmedQuery({
 *   variables: {
 *      page: // value for 'page'
 *      size: // value for 'size'
 *      sortedColumn: // value for 'sortedColumn'
 *      sortedOrder: // value for 'sortedOrder'
 *   },
 * });
 */
export function useGetRetirementsConfirmedQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRetirementsConfirmedQuery,
    GetRetirementsConfirmedQueryVariables
  > &
    (
      | { variables: GetRetirementsConfirmedQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRetirementsConfirmedQuery,
    GetRetirementsConfirmedQueryVariables
  >(GetRetirementsConfirmedDocument, options);
}
export function useGetRetirementsConfirmedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRetirementsConfirmedQuery,
    GetRetirementsConfirmedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRetirementsConfirmedQuery,
    GetRetirementsConfirmedQueryVariables
  >(GetRetirementsConfirmedDocument, options);
}
export function useGetRetirementsConfirmedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetRetirementsConfirmedQuery,
    GetRetirementsConfirmedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetRetirementsConfirmedQuery,
    GetRetirementsConfirmedQueryVariables
  >(GetRetirementsConfirmedDocument, options);
}
export type GetRetirementsConfirmedQueryHookResult = ReturnType<
  typeof useGetRetirementsConfirmedQuery
>;
export type GetRetirementsConfirmedLazyQueryHookResult = ReturnType<
  typeof useGetRetirementsConfirmedLazyQuery
>;
export type GetRetirementsConfirmedSuspenseQueryHookResult = ReturnType<
  typeof useGetRetirementsConfirmedSuspenseQuery
>;
export type GetRetirementsConfirmedQueryResult = Apollo.QueryResult<
  GetRetirementsConfirmedQuery,
  GetRetirementsConfirmedQueryVariables
>;
export const GetRetirementsRequestedDocument = gql`
  query getRetirementsRequested {
    retirements_requested {
      ...RetirementRequested_fromGetRetirementsRequested
    }
  }
  ${RetirementRequested_FromGetRetirementsRequestedFragmentDoc}
`;

/**
 * __useGetRetirementsRequestedQuery__
 *
 * To run a query within a React component, call `useGetRetirementsRequestedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRetirementsRequestedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRetirementsRequestedQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRetirementsRequestedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRetirementsRequestedQuery,
    GetRetirementsRequestedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRetirementsRequestedQuery,
    GetRetirementsRequestedQueryVariables
  >(GetRetirementsRequestedDocument, options);
}
export function useGetRetirementsRequestedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRetirementsRequestedQuery,
    GetRetirementsRequestedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRetirementsRequestedQuery,
    GetRetirementsRequestedQueryVariables
  >(GetRetirementsRequestedDocument, options);
}
export function useGetRetirementsRequestedSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetRetirementsRequestedQuery,
    GetRetirementsRequestedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetRetirementsRequestedQuery,
    GetRetirementsRequestedQueryVariables
  >(GetRetirementsRequestedDocument, options);
}
export type GetRetirementsRequestedQueryHookResult = ReturnType<
  typeof useGetRetirementsRequestedQuery
>;
export type GetRetirementsRequestedLazyQueryHookResult = ReturnType<
  typeof useGetRetirementsRequestedLazyQuery
>;
export type GetRetirementsRequestedSuspenseQueryHookResult = ReturnType<
  typeof useGetRetirementsRequestedSuspenseQuery
>;
export type GetRetirementsRequestedQueryResult = Apollo.QueryResult<
  GetRetirementsRequestedQuery,
  GetRetirementsRequestedQueryVariables
>;
export const MigrateClientsToChargebeeDocument = gql`
  mutation migrateClientsToChargebee {
    migrate_clients_to_chargebee
  }
`;
export type MigrateClientsToChargebeeMutationFn = Apollo.MutationFunction<
  MigrateClientsToChargebeeMutation,
  MigrateClientsToChargebeeMutationVariables
>;

/**
 * __useMigrateClientsToChargebeeMutation__
 *
 * To run a mutation, you first call `useMigrateClientsToChargebeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMigrateClientsToChargebeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [migrateClientsToChargebeeMutation, { data, loading, error }] = useMigrateClientsToChargebeeMutation({
 *   variables: {
 *   },
 * });
 */
export function useMigrateClientsToChargebeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MigrateClientsToChargebeeMutation,
    MigrateClientsToChargebeeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MigrateClientsToChargebeeMutation,
    MigrateClientsToChargebeeMutationVariables
  >(MigrateClientsToChargebeeDocument, options);
}
export type MigrateClientsToChargebeeMutationHookResult = ReturnType<
  typeof useMigrateClientsToChargebeeMutation
>;
export type MigrateClientsToChargebeeMutationResult =
  Apollo.MutationResult<MigrateClientsToChargebeeMutation>;
export type MigrateClientsToChargebeeMutationOptions =
  Apollo.BaseMutationOptions<
    MigrateClientsToChargebeeMutation,
    MigrateClientsToChargebeeMutationVariables
  >;
export const ReconcileInventoryDocument = gql`
  mutation reconcileInventory {
    reconcile_inventory
  }
`;
export type ReconcileInventoryMutationFn = Apollo.MutationFunction<
  ReconcileInventoryMutation,
  ReconcileInventoryMutationVariables
>;

/**
 * __useReconcileInventoryMutation__
 *
 * To run a mutation, you first call `useReconcileInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReconcileInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reconcileInventoryMutation, { data, loading, error }] = useReconcileInventoryMutation({
 *   variables: {
 *   },
 * });
 */
export function useReconcileInventoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReconcileInventoryMutation,
    ReconcileInventoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReconcileInventoryMutation,
    ReconcileInventoryMutationVariables
  >(ReconcileInventoryDocument, options);
}
export type ReconcileInventoryMutationHookResult = ReturnType<
  typeof useReconcileInventoryMutation
>;
export type ReconcileInventoryMutationResult =
  Apollo.MutationResult<ReconcileInventoryMutation>;
export type ReconcileInventoryMutationOptions = Apollo.BaseMutationOptions<
  ReconcileInventoryMutation,
  ReconcileInventoryMutationVariables
>;
export const SyncPortfoliosInChargebeeDocument = gql`
  mutation syncPortfoliosInChargebee {
    sync_portfolios_in_chargebee
  }
`;
export type SyncPortfoliosInChargebeeMutationFn = Apollo.MutationFunction<
  SyncPortfoliosInChargebeeMutation,
  SyncPortfoliosInChargebeeMutationVariables
>;

/**
 * __useSyncPortfoliosInChargebeeMutation__
 *
 * To run a mutation, you first call `useSyncPortfoliosInChargebeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncPortfoliosInChargebeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncPortfoliosInChargebeeMutation, { data, loading, error }] = useSyncPortfoliosInChargebeeMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncPortfoliosInChargebeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyncPortfoliosInChargebeeMutation,
    SyncPortfoliosInChargebeeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyncPortfoliosInChargebeeMutation,
    SyncPortfoliosInChargebeeMutationVariables
  >(SyncPortfoliosInChargebeeDocument, options);
}
export type SyncPortfoliosInChargebeeMutationHookResult = ReturnType<
  typeof useSyncPortfoliosInChargebeeMutation
>;
export type SyncPortfoliosInChargebeeMutationResult =
  Apollo.MutationResult<SyncPortfoliosInChargebeeMutation>;
export type SyncPortfoliosInChargebeeMutationOptions =
  Apollo.BaseMutationOptions<
    SyncPortfoliosInChargebeeMutation,
    SyncPortfoliosInChargebeeMutationVariables
  >;
export const TriggerNetlifyDeployDocument = gql`
  mutation triggerNetlifyDeploy {
    trigger_netlify_deploy
  }
`;
export type TriggerNetlifyDeployMutationFn = Apollo.MutationFunction<
  TriggerNetlifyDeployMutation,
  TriggerNetlifyDeployMutationVariables
>;

/**
 * __useTriggerNetlifyDeployMutation__
 *
 * To run a mutation, you first call `useTriggerNetlifyDeployMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerNetlifyDeployMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerNetlifyDeployMutation, { data, loading, error }] = useTriggerNetlifyDeployMutation({
 *   variables: {
 *   },
 * });
 */
export function useTriggerNetlifyDeployMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TriggerNetlifyDeployMutation,
    TriggerNetlifyDeployMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TriggerNetlifyDeployMutation,
    TriggerNetlifyDeployMutationVariables
  >(TriggerNetlifyDeployDocument, options);
}
export type TriggerNetlifyDeployMutationHookResult = ReturnType<
  typeof useTriggerNetlifyDeployMutation
>;
export type TriggerNetlifyDeployMutationResult =
  Apollo.MutationResult<TriggerNetlifyDeployMutation>;
export type TriggerNetlifyDeployMutationOptions = Apollo.BaseMutationOptions<
  TriggerNetlifyDeployMutation,
  TriggerNetlifyDeployMutationVariables
>;
export const GetMeDocument = gql`
  query getMe {
    user {
      ...User_fromMe
    }
  }
  ${User_FromMeFragmentDoc}
`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options,
  );
}
export function useGetMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options,
  );
}
export function useGetMeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMeQuery,
    GetMeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    options,
  );
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeSuspenseQueryHookResult = ReturnType<
  typeof useGetMeSuspenseQuery
>;
export type GetMeQueryResult = Apollo.QueryResult<
  GetMeQuery,
  GetMeQueryVariables
>;
