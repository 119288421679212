import Big from 'big.js';

import type { PortfolioDraftInput } from '../../../services/graphql/generated';

interface PortfolioDraftSummaryFields {
  removal: number;
  avoidance: number;
  commission: number;
  allocatedTonnes: number;
  pricePerTonne: number;
  totalWeight: number;
}

export const calculatePortfolioDraftSummaryFields = (
  portfolioDraft: PortfolioDraftInput,
): PortfolioDraftSummaryFields => {
  const totalAmounts = portfolioDraft.portfolio_draft.allocations?.reduce(
    (acc, currentValue) => {
      acc.totalWeight = acc.totalWeight.plus(currentValue.weight_tonnes);
      acc.totalPrice = acc.totalPrice.plus(
        Big(currentValue.weight_tonnes)
          .times(currentValue.price_per_kg_in_gbp)
          .times(1000),
      );
      if (currentValue.avoidance && currentValue.removal) {
        acc.totalAvoidance = acc.totalAvoidance.plus(
          Big(currentValue.weight_tonnes).times(50),
        );
        acc.totalRemoval = acc.totalRemoval.plus(
          Big(currentValue.weight_tonnes).times(50),
        );
      } else if (currentValue.avoidance) {
        acc.totalAvoidance = acc.totalAvoidance.plus(
          Big(currentValue.weight_tonnes).times(100),
        );
      } else if (currentValue.removal) {
        acc.totalRemoval = acc.totalRemoval.plus(
          Big(currentValue.weight_tonnes).times(100),
        );
      }
      return acc;
    },
    {
      totalWeight: Big(0),
      totalPrice: Big(0),
      totalAvoidance: Big(0),
      totalRemoval: Big(0),
    },
  );

  if (totalAmounts?.totalWeight.gt(0)) {
    return {
      removal: Math.round(
        totalAmounts.totalRemoval.div(totalAmounts.totalWeight).toNumber(),
      ),
      avoidance: Math.round(
        totalAmounts.totalAvoidance.div(totalAmounts.totalWeight).toNumber(),
      ),
      commission: 0.15,
      allocatedTonnes: totalAmounts.totalWeight.toNumber(),
      pricePerTonne: totalAmounts.totalPrice
        .div(totalAmounts.totalWeight)
        .toNumber(),
      totalWeight: totalAmounts.totalWeight.toNumber(),
    };
  }

  return {
    removal: 0,
    avoidance: 0,
    commission: 0.15,
    allocatedTonnes: 0,
    pricePerTonne: 0,
    totalWeight: 0,
  };
};
