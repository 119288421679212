import { all, takeEvery, put, call } from 'redux-saga/effects';
import store from 'store';
import * as Sentry from '@sentry/react';

import { getUser } from '../../services/user';

import { setUserState, logout as logoutAction } from './reducers';

function* checkUser() {
  yield put(
    setUserState({
      loading: true,
    }),
  );
  const response = yield call(getUser);

  if (response) {
    yield put(
      setUserState({
        id: response.user.sub,
        name: response.user.name,
        role: 'admin',
        email: response.user.email,
        username: response.user.username,
        email_verified: response.user.email_verified,
        authorized: true,
      }),
    );

    Sentry.setUser({
      id: response.user.sub,
      username: response.user.username,
      email: response.user.email,
    });
  }

  yield put(
    setUserState({
      loading: false,
    }),
  );
}

function* logout() {
  store.remove('accessToken');

  yield put(
    setUserState({
      id: '',
      name: '',
      role: '',
      email: '',
      username: '',
      email_verified: false,
      authorized: false,
    }),
  );
}

export function* rootSaga() {
  yield all([
    takeEvery(logoutAction, logout),
    checkUser(), // run once on app load to check user auth
  ]);
}
