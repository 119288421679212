import { useState, type ImgHTMLAttributes, useCallback } from 'react';
import { twMerge } from '@pledge-earth/product-language';

export function AvatarImage({
  fallback,
  className,
  src,
}: { fallback?: string } & Pick<
  ImgHTMLAttributes<HTMLImageElement>,
  'src' | 'children' | 'className'
>) {
  const [showFallback, setShowFallback] = useState(!src);

  const onError = useCallback(() => {
    setShowFallback(true);
  }, [setShowFallback]);

  if (showFallback) {
    return (
      <span className={twMerge('AvatarImage AvatarImage--text', className)}>
        {fallback}
      </span>
    );
  }

  return (
    <img
      className={twMerge(
        'AvatarImage AvatarImage--img object-scale-down',
        className,
      )}
      onError={onError}
      alt=""
      src={src}
    />
  );
}
