import createSagaMiddleware from 'redux-saga';
import { configureStore, combineReducers } from '@reduxjs/toolkit';

import { reducer as user } from './user/reducers';
import { reducer as menu } from './menu/reducers';
import { reducer as settings } from './settings/reducers';
// eslint-disable-next-line import/no-cycle -- eslint onboarding
import { rootSaga as sagas } from './sagas';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const reducer = combineReducers({
  user,
  menu,
  settings,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // The menu state contains React components, which are non-serializable. This should probably be fixed, but for
        // now we ignore the serializable check for the menu state to silence console warnings.
        ignoredPaths: ['menu.menuData'],
        ignoredActions: ['menu/menuDataRetrieved'],
      },
    }).concat(middlewares),
});

sagaMiddleware.run(sagas);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
