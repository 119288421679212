import { get, omit } from 'lodash';
import { useCallback, useId, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Form, Spin } from '@pledge-earth/web-components';
import {
  Button,
  BannerMessage,
  TextField,
  SelectField,
  OptionListItem,
  Size,
  Dialog,
  DialogHeader,
  DialogTitle,
  OverlayCloseButton,
  DialogBody,
  DialogFooter,
} from '@pledge-earth/product-language';

import type { ProjectType } from '../../services/graphql/generated';
import {
  useGetProjectTypeCategoriesQuery,
  useUpsertProjectTypeMutation,
} from '../../services/graphql/generated';
import { EntityAdded } from '../../components/AddEntity/EntityAdded';
import { showSuccessToast } from '../../utils/toast';

export function ProjectTypeEdit({
  closeModal,
  onSaved,
  projectType = {} as ProjectType,
}: {
  closeModal: () => void;
  projectType?: ProjectType;
  onSaved?: () => void;
}) {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const formId = useId();
  const [projectTypeForm] = Form.useForm();
  const [showSuccess, setShowSuccess] = useState(false);
  const [createdProjectTypeId, setCreatedProjectTypeId] = useState<string>();

  const [upsertProjectTypeMutation, { loading, error }] =
    useUpsertProjectTypeMutation();

  const {
    data: dataProjectTypeCategories,
    loading: loadingProjectTypeCategories,
  } = useGetProjectTypeCategoriesQuery();

  const handleFormFinished = useCallback(
    async (values: any) => {
      const { name, category } = values;

      const data: any = omit(
        {
          ...projectType,
          name,
          category,
        },
        ['updated_date', '__typename'],
      );

      const result = await upsertProjectTypeMutation({
        variables: {
          data,
        },
      });

      const projectTypeId = result.data?.upsert_project_type?.id;

      if (onSaved) {
        showSuccessToast({
          description: 'Project type has been updated',
        });

        onSaved();
      } else {
        setCreatedProjectTypeId(projectTypeId);
        setShowSuccess(true);
      }
    },
    [onSaved, projectType, upsertProjectTypeMutation],
  );

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const redirectToProjectTypeDetailsPage = useCallback(() => {
    if (createdProjectTypeId) {
      navigate(`/offsetting/project-types/${createdProjectTypeId}`);
    }
    closeModal();
  }, [createdProjectTypeId, closeModal, navigate]);

  return (
    <Dialog>
      <DialogHeader>
        <DialogTitle>
          {get(projectType, 'id') ? (
            <FormattedMessage id="project-type.edit.title" />
          ) : (
            <FormattedMessage id="project-type.add.title" />
          )}
        </DialogTitle>
        <OverlayCloseButton label={formatMessage({ id: 'close' })} />
      </DialogHeader>

      <DialogBody>
        {error ? (
          <BannerMessage variant="critical">
            <FormattedMessage id="project-type.edit.error" />
          </BannerMessage>
        ) : null}
        {showSuccess ? (
          <EntityAdded
            closeModal={handleClose}
            entityAddedTitle="Project Type Added"
            actionButtonCTAText="View Project Type"
            actionButtonCTA={redirectToProjectTypeDetailsPage}
          />
        ) : null}
        <Spin spinning={loadingProjectTypeCategories}>
          {!showSuccess && (
            <Form
              id={formId}
              size="large"
              layout="vertical"
              hideRequiredMark={true}
              form={projectTypeForm}
              onFinish={handleFormFinished}
              initialValues={projectType}
              validateTrigger="onBlur"
            >
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'project-type.edit.name.required',
                    }),
                  },
                ]}
              >
                <TextField
                  label={<FormattedMessage id="project-type.edit.name" />}
                  size={Size.Loose}
                />
              </Form.Item>
              <Form.Item
                name="category"
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'project-type.edit.category.required',
                    }),
                  },
                ]}
              >
                <SelectField
                  size={Size.Loose}
                  label={<FormattedMessage id="project-type.edit.category" />}
                  placeholder={formatMessage({
                    id: 'project-type.edit.category.placeholder',
                  })}
                  items={dataProjectTypeCategories?.project_type_categories}
                  UNSTABLE_portalContainer={document.body}
                >
                  {({ category }) => (
                    <OptionListItem id={category}>{category}</OptionListItem>
                  )}
                </SelectField>
              </Form.Item>
            </Form>
          )}
        </Spin>
      </DialogBody>

      {!showSuccess && (
        <DialogFooter>
          <Button onPress={closeModal}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            isLoading={loading}
          >
            <FormattedMessage id="save" />
          </Button>
        </DialogFooter>
      )}
    </Dialog>
  );
}
