// eslint-disable-next-line no-restricted-imports -- Drawer will be replaced at the same time as layout
import { Drawer } from '@pledge-earth/web-components';
import {
  MenuCollapseIcon,
  MenuExpandIcon,
  IconButton,
  XIcon,
} from '@pledge-earth/product-language';

import { settingChanged } from '../../store/settings/reducers';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

import { MenuLeft } from './MenuLeft';
import './MenuLeft.scss';

export const Menu = () => {
  const dispatch = useAppDispatch();
  const isMobileMenuOpen = useAppSelector(
    (state) => state.settings.isMobileMenuOpen,
  );
  const isMobileView = useAppSelector((state) => state.settings.isMobileView);
  const leftMenuWidth = useAppSelector((state) => state.settings.leftMenuWidth);

  const toggleMobileMenu = () => {
    dispatch(
      settingChanged({
        setting: 'isMobileMenuOpen',
        value: !isMobileMenuOpen,
      }),
    );
  };

  function GetMenu() {
    if (isMobileView) {
      return (
        <div>
          <IconButton
            label="Toggle"
            variant="default"
            className="MenuLeft__mobile-menu--toggler"
            onPress={toggleMobileMenu}
          >
            {isMobileMenuOpen ? <MenuExpandIcon /> : <MenuCollapseIcon />}
          </IconButton>
          <Drawer
            closeIcon={<XIcon />}
            closable={false}
            visible={isMobileMenuOpen}
            placement="left"
            className="Drawer MenuLeft__mobile-menu"
            onClose={toggleMobileMenu}
            maskClosable={true}
            getContainer={undefined}
            width={leftMenuWidth}
          >
            <MenuLeft />
          </Drawer>
        </div>
      );
    }

    return <MenuLeft />;
  }

  return GetMenu();
};
