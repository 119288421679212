import { useState, useRef, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import type { S3Object, UploadFile } from '@pledge-earth/web-components';
import { Upload, message, generateCDNUrl } from '@pledge-earth/web-components';
import {
  FileArrowUpIcon,
  Heading,
  Paragraph,
  TrashIcon,
} from '@pledge-earth/product-language';

import type { S3FolderEnum } from '../../services/graphql/generated';
import { useUploadFileMutation } from '../../services/graphql/generated';

import './ImageUpload.scss';

export enum ImageUploadDisplay {
  SQUARE = 'ImageUpload__square',
}

export function ImageUpload({
  onUploadImage,
  logo,
  folder,
  title,
  display,
}: {
  onUploadImage?: any;
  logo?: S3Object;
  folder: S3FolderEnum;
  title: string;
  display: ImageUploadDisplay;
}) {
  const mapS3ObjectToUploadFileObject = useMemo(() => {
    if (logo) {
      return {
        uid: logo.key,
        name: (logo.key || '').split('/').pop() ?? 'default.png',
        status: 'done',
        url: generateCDNUrl({
          width: 32,
          height: 32,
          s3Object: logo as any,
        }),
      } as UploadFile;
    }
    return null;
  }, [logo]);

  const { formatMessage } = useIntl();
  const [imageList, setFileList] = useState<UploadFile[]>([]);
  const requiredTypes = ['image/jpeg', 'image/png'];
  // 5mb max file size
  const requiredSize = 5242880;
  const fileFormatIsTrue = useRef(true);

  const [uploadFile, { data: uploadFileSuccess }] = useUploadFileMutation();

  useEffect(() => {
    if (logo) {
      setFileList(
        mapS3ObjectToUploadFileObject ? [mapS3ObjectToUploadFileObject] : [],
      );
    } else {
      setFileList([]);
    }
  }, [logo, mapS3ObjectToUploadFileObject]);

  const onChange = ({ file, fileList }) => {
    switch (file.status) {
      case 'uploading':
        setFileList(fileList);
        break;
      case 'removed':
        setFileList([]);
        break;
      case 'error':
        setFileList([]);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- eslint onboarding
        message.error(formatMessage({ id: 'file.upload.error' }));
        break;
      case 'done':
        setFileList(fileList);
        break;
      default:
        break;
    }
  };

  const handleBeforeUpload = ({ type, size }) => {
    fileFormatIsTrue.current = true;
    const isJpgOrPng = requiredTypes.includes(type);
    if (!isJpgOrPng) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- eslint onboarding
      message.error(formatMessage({ id: 'file.upload.format.error' }));
    }
    const isAllowedSize = size < requiredSize;
    if (!isAllowedSize) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- eslint onboarding
      message.error(formatMessage({ id: 'file.upload.size.error.5mb' }));
    }
    fileFormatIsTrue.current = isAllowedSize && isJpgOrPng;
    return isAllowedSize && isJpgOrPng;
  };

  const onRemoveImage = () => {
    onUploadImage(null);
  };

  const handleUpload = async (props) => {
    try {
      if (fileFormatIsTrue.current) {
        await uploadFile({
          variables: {
            file: props.file,
            folder,
          },
        });
        props.onSuccess(null, props.file);
      }
    } catch (e) {
      props.onError(e);
    }
  };

  useEffect(() => {
    if (uploadFileSuccess) {
      onUploadImage(uploadFileSuccess.upload_file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFileSuccess]);

  const uploadInfo = (
    <div className="ImageUpload__upload-info relative min-h-px max-w-[75%] grow-0 shrink-0 basis-9/12">
      <Heading level="h5" className="ImageUpload__upload-info__title">
        {title}
      </Heading>
      <Paragraph>
        <div>
          <FormattedMessage id="upload.file.description" />
        </div>
        <div>
          <FormattedMessage id="upload.file.type.description" />
        </div>
      </Paragraph>
    </div>
  );

  return (
    <div className="ImageUpload flex min-w-0 flex-wrap">
      <div className="relative min-h-px max-w-[20.83333%] grow-0 shrink-0 basis-[20.83333%]">
        <Upload
          listType="picture-card"
          className={display}
          showUploadList={{
            showPreviewIcon: false,
            showRemoveIcon: true,
            showDownloadIcon: false,
            removeIcon: <TrashIcon className="ImageUpload__remove_file" />,
          }}
          fileList={imageList}
          maxCount={1}
          name="logo"
          onRemove={onRemoveImage}
          customRequest={handleUpload}
          onChange={(file) => onChange(file)}
          beforeUpload={handleBeforeUpload}
          accept=".jpg,.png"
        >
          {imageList.length >= 1 ? null : <FileArrowUpIcon />}
        </Upload>
      </div>
      {uploadInfo}
    </div>
  );
}
