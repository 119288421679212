import { useIntl } from 'react-intl';
import { Layout } from '@pledge-earth/web-components';

import './Footer.scss';

export function Footer() {
  const { formatMessage } = useIntl();

  return (
    <Layout.Footer className="Footer">
      <div className="flex gap-4">
        <a
          className="Footer__link"
          href="https://www.pledge.io/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Privacy Policy"
        >
          {formatMessage({ id: 'privacy_policy' })}
        </a>
        <span>&bull;</span>
        <a
          className="Footer__link"
          href="https://www.pledge.io/terms-conditions"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Terms"
        >
          {formatMessage({ id: 'terms' })}
        </a>
      </div>
    </Layout.Footer>
  );
}
