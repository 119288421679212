import { useState, useRef, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import type { UploadFile } from '@pledge-earth/web-components';
import { Upload, message, generateCDNUrl } from '@pledge-earth/web-components';
import { FileArrowUpIcon } from '@pledge-earth/product-language';

import type {
  S3FolderEnum,
  S3Object,
  Maybe,
} from '../../services/graphql/generated';
import { useUploadFileMutation } from '../../services/graphql/generated';

import './ImageUpload.scss';

const { Dragger } = Upload;

enum ImageUploadDisplay {
  ROUND = 'ImageUpload__round',
  SQUARE = 'ImageUpload__square',
}

export function DragImageUpload({
  onUploadImage,
  folder,
  display,
  initialFileList,
  onRemoveImage,
}: {
  onUploadImage?: any;
  folder: S3FolderEnum;
  display: ImageUploadDisplay;
  initialFileList?: Maybe<S3Object[]>;
  onRemoveImage?: any;
}) {
  const mapS3ObjectsToUploadFileObjects = useMemo(
    () =>
      initialFileList?.map(
        (s3ObjectImage) =>
          ({
            uid: s3ObjectImage.key,
            name: (s3ObjectImage.key || '').split('/').pop() ?? 'default.png',
            status: 'done',

            url: generateCDNUrl({
              width: 32,
              height: 32,
              s3Object: s3ObjectImage as any,
            }),
          }) as UploadFile,
      ),
    [initialFileList],
  );

  const { formatMessage } = useIntl();
  const [imageList, setFileList] = useState<UploadFile[]>([]);
  const requiredTypes = ['image/jpeg', 'image/png'];
  // 3mb max file size
  const requiredSize = 3145728;
  const fileFormatIsTrue = useRef(true);

  const [uploadFile, { data: uploadFileSuccess }] = useUploadFileMutation();

  useEffect(() => {
    if (initialFileList) {
      setFileList(mapS3ObjectsToUploadFileObjects ?? []);
    }
  }, [initialFileList, mapS3ObjectsToUploadFileObjects]);

  const onChange = ({ file, fileList }) => {
    switch (file.status) {
      case 'uploading':
      case 'removed':
        setFileList(fileList);
        break;
      case 'error':
        setFileList(fileList);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- eslint onboarding
        message.error(formatMessage({ id: 'file.upload.error' }));
        break;
      case 'done':
        setFileList(fileList);
        break;
      default:
        break;
    }
  };

  const handleBeforeUpload = ({ type, size }) => {
    fileFormatIsTrue.current = true;
    const isJpgOrPng = requiredTypes.includes(type);
    if (!isJpgOrPng) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- eslint onboarding
      message.error(formatMessage({ id: 'file.upload.format.error' }));
    }
    const isAllowedSize = size < requiredSize;
    if (!isAllowedSize) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- eslint onboarding
      message.error(formatMessage({ id: 'file.upload.size.error.3mb' }));
    }
    fileFormatIsTrue.current = isAllowedSize && isJpgOrPng;
    return isAllowedSize && isJpgOrPng;
  };

  const handleUpload = async (props) => {
    try {
      if (fileFormatIsTrue.current) {
        await uploadFile({
          variables: {
            file: props.file,
            folder,
          },
        });
        props.onSuccess(null, props.file);
      }
    } catch (e) {
      props.onError(e);
    }
  };

  useEffect(() => {
    if (uploadFileSuccess) {
      onUploadImage(uploadFileSuccess.upload_file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFileSuccess]);

  return (
    <div className="DragImageUpload flex min-w-0 flex-wrap">
      <div className="relative min-h-px max-w-[20.83333%] grow-0 shrink-0 basis-[20.83333%]">
        <Dragger
          multiple={false}
          onChange={(file) => onChange(file)}
          beforeUpload={handleBeforeUpload}
          onRemove={(file) => onRemoveImage(file)}
          fileList={imageList}
          customRequest={handleUpload}
          className={display}
          listType="picture"
          accept=".jpg,.png"
        >
          <div className="inline-flex items-center mb-5 text-icon-accent">
            <FileArrowUpIcon className="size-12" />
          </div>
          <p className="ant-upload-text">Drag file here or browse</p>
          <p className="ant-upload-hint">
            Minimum 1600px width recommended. Max 3MB each (jpeg or png)
          </p>
        </Dragger>
      </div>
    </div>
  );
}
