import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { MenuItem } from '../../services/menu';

interface MenuState {
  menuData: MenuItem[];
}

const initialState: MenuState = {
  menuData: [],
};

const { actions, reducer } = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    menuDataRetrieved(_state, action: PayloadAction<MenuState['menuData']>) {
      return { menuData: action.payload };
    },
  },
});

export const { menuDataRetrieved } = actions;

export { reducer };
