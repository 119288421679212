import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface UserState {
  id: string;
  name: string;
  email: string;
  username: string;
  role: string;
  email_verified: boolean;
  authorized: boolean;
  loading: boolean;
}

const initialState: UserState = {
  id: '',
  name: '',
  email: '',
  username: '',
  role: '',
  email_verified: false,
  authorized: false,
  loading: false,
};

const { actions, reducer } = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserState(state, action: PayloadAction<Partial<UserState>>) {
      const nextState = { ...state, ...action.payload };

      return nextState;
    },
    logout() {
      // do nothing - side effects in saga
    },
  },
});

export const { setUserState, logout } = actions;

export { reducer };
