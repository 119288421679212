import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Divider } from '@pledge-earth/web-components';
import {
  Heading,
  Text,
  TickCircleFilledIcon,
} from '@pledge-earth/product-language';

import type {
  Maybe,
  PortfolioDraftInput,
} from '../../../services/graphql/generated';
import { CurrencyEnum } from '../../../services/graphql/generated';
import { PortfolioDistribution } from '../../../components/Offsetting/PortfolioDistribution/PortfolioDistribution';
import { PortfolioSummaryBreakdown } from '../PortfolioSummaryBreakdown';

import { calculatePortfolioDraftSummaryFields } from './portfolioEditHelpers';
import './PortfolioEditSummary.scss';

export function PortfolioEditSummary({
  clientName,
  portfolio,
}: {
  clientName?: Maybe<string> | undefined;
  portfolio: PortfolioDraftInput;
}) {
  const summaryFields = calculatePortfolioDraftSummaryFields(portfolio);

  const keyFacts = useMemo(
    () =>
      portfolio.portfolio_draft.details?.key_facts?.map((keyFact) => {
        if (keyFact.length > 0) {
          return (
            <div key={keyFact} className="PortfolioEditSummary__key-fact">
              <TickCircleFilledIcon className="text-icon-accent text-icon" />
              <span className="PortfolioEditSummary__key-fact__text">
                {keyFact}
              </span>
            </div>
          );
        }
        return null;
      }),
    [portfolio],
  );

  return (
    <div className="PortfolioEditSummary">
      <Heading level="h4">
        {clientName ? `${clientName} ` : null}
        {portfolio.portfolio_draft.details?.name}{' '}
        {portfolio.portfolio_draft.details?.portfolio_version}
      </Heading>
      <Heading level="h5" className="PortfolioEditSummary__details-header">
        <FormattedMessage id="portfolio.edit.details" />
      </Heading>
      <Text className="PortfolioEditSummary__description">
        {portfolio.portfolio_draft.details?.description}
      </Text>
      {keyFacts}
      <Heading level="h5">
        <FormattedMessage id="portfolio.edit.summary" />
      </Heading>
      <div className="PortfolioEditSummary__price-summary">
        <div className="PortfolioEditSummary__price-summary__distribution">
          <PortfolioDistribution
            removal={summaryFields.removal}
            avoidance={summaryFields.avoidance}
            hideDescription={true}
          />
        </div>
        <Divider />
        <div className="PortfolioEditSummary__price-summary__breakdown">
          <PortfolioSummaryBreakdown
            currency={CurrencyEnum.Gbp}
            pricePerTonne={summaryFields.pricePerTonne}
            allocatedTonnes={summaryFields.allocatedTonnes}
            totalProjects={portfolio.portfolio_draft.allocations?.length ?? 0}
          />
        </div>
      </div>
    </div>
  );
}
