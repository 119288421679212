import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form } from '@pledge-earth/web-components';
import {
  TextField,
  Heading,
  TextAreaField,
  Size,
} from '@pledge-earth/product-language';

import type {
  Maybe,
  PortfolioDraftInput,
} from '../../../services/graphql/generated';

import './PortfolioEditDetails.scss';

interface DetailsInitialState {
  name: string | null;
  portfolio_version: string | null;
  description: string | null;
  key_facts: (string | null)[];
}

export function PortfolioEditDetails({
  formId,
  updateState,
  clientName,
  portfolio,
}: {
  formId: string;
  updateState: (newPortfolioState: PortfolioDraftInput) => void;
  clientName?: Maybe<string> | undefined;
  portfolio: PortfolioDraftInput;
}) {
  const { formatMessage } = useIntl();

  const [portfolioDetailsForm] = Form.useForm();
  const initialPortfolioState: DetailsInitialState = {
    name: portfolio.portfolio_draft.details?.name ?? null,
    portfolio_version:
      portfolio.portfolio_draft.details?.portfolio_version ?? null,
    description: portfolio.portfolio_draft.details?.description ?? null,
    key_facts: portfolio.portfolio_draft.details?.key_facts ?? ['', '', '', ''],
  };

  const updatePortfolioVersion = useCallback(
    (value: string) => {
      const updatedPortfolioState = {
        id: portfolio.id,
        portfolio_draft: {
          ...portfolio.portfolio_draft,
          details: {
            ...portfolio.portfolio_draft.details,
            portfolio_version: value,
          },
        },
      };
      updateState(updatedPortfolioState);
    },
    [portfolio, updateState],
  );

  const updatePortfolioName = useCallback(
    (value: string) => {
      const updatedPortfolioState = {
        id: portfolio.id,
        portfolio_draft: {
          ...portfolio.portfolio_draft,
          details: {
            ...portfolio.portfolio_draft.details,
            name: value,
          },
        },
      };
      updateState(updatedPortfolioState);
    },
    [portfolio, updateState],
  );

  const updatePortfolioDescription = useCallback(
    (value: string) => {
      const updatedPortfolioState = {
        id: portfolio.id,
        portfolio_draft: {
          ...portfolio.portfolio_draft,
          details: {
            ...portfolio.portfolio_draft.details,
            description: value,
          },
        },
      };
      updateState(updatedPortfolioState);
    },
    [portfolio, updateState],
  );

  const updatePortfolioKeyFacts = useCallback(
    (value: string, index: number) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention -- eslint onboarding
      const key_facts = portfolio.portfolio_draft?.details?.key_facts ?? [];
      if (key_facts.length >= 4) {
        key_facts[index] = value;
      } else if (index === 0) {
        key_facts.unshift(value);
      } else {
        key_facts.push(value);
      }

      const updatedPortfolioState = {
        id: portfolio.id,
        portfolio_draft: {
          ...portfolio.portfolio_draft,
          details: {
            ...portfolio.portfolio_draft.details,
            key_facts,
          },
        },
      };
      updateState(updatedPortfolioState);
    },
    [portfolio, updateState],
  );

  return (
    <div className="PortfolioEditDetails">
      <Form
        id={formId}
        size="large"
        layout="vertical"
        hideRequiredMark={true}
        form={portfolioDetailsForm}
        initialValues={initialPortfolioState}
        validateTrigger="onBlur"
        className="PortfolioEditDetails__form"
      >
        <Heading level="h5" className="PortfolioEditDetails__form__name-title">
          Name
        </Heading>
        <Heading level="h4" className="PortfolioEditDetails__form__name">
          {clientName ? `${clientName} ` : null}
          {portfolio.portfolio_draft.details?.name}{' '}
          {portfolio.portfolio_draft.details?.portfolio_version}
        </Heading>
        <div className="PortfolioEditDetails__form__theme-version">
          <Form.Item
            name="name"
            className="PortfolioEditDetails__form__theme-version__form-item"
          >
            <TextField
              label={<FormattedMessage id="portfolio.edit.name" />}
              size={Size.Loose}
              onChange={updatePortfolioName}
              placeholder={formatMessage({
                id: 'portfolio.edit.name.placeholder',
              })}
            />
          </Form.Item>
          <Form.Item
            name="portfolio_version"
            className="PortfolioEditDetails__form__theme-version__form-item"
          >
            <TextField
              label={<FormattedMessage id="portfolio.edit.portfolio_version" />}
              size={Size.Loose}
              onChange={updatePortfolioVersion}
              placeholder={formatMessage({
                id: 'portfolio.edit.portfolio_version.placeholder',
              })}
            />
          </Form.Item>
        </div>

        <Heading level="h5">Details</Heading>
        <Form.Item name="key_fact_ids">
          <TextField
            label={<FormattedMessage id="portfolio.edit.key_fact_ids" />}
            size={Size.Loose}
            onChange={(event) => updatePortfolioKeyFacts(event, 0)}
            defaultValue={initialPortfolioState.key_facts[0] ?? ''}
            placeholder={formatMessage({
              id: 'portfolio.edit.key_fact_ids.placeholder',
            })}
            className="PortfolioEditDetails__form__key-facts-select"
          />
          <TextField
            size={Size.Loose}
            onChange={(event) => updatePortfolioKeyFacts(event, 1)}
            defaultValue={initialPortfolioState.key_facts[1] ?? ''}
            placeholder={formatMessage({
              id: 'portfolio.edit.key_fact_ids.placeholder',
            })}
            className="PortfolioEditDetails__form__key-facts-select"
          />
          <TextField
            size={Size.Loose}
            onChange={(event) => updatePortfolioKeyFacts(event, 2)}
            defaultValue={initialPortfolioState.key_facts[2] ?? ''}
            placeholder={formatMessage({
              id: 'portfolio.edit.key_fact_ids.placeholder',
            })}
            className="PortfolioEditDetails__form__key-facts-select"
          />
          <TextField
            size={Size.Loose}
            onChange={(event) => updatePortfolioKeyFacts(event, 3)}
            defaultValue={initialPortfolioState.key_facts[3] ?? ''}
            placeholder={formatMessage({
              id: 'portfolio.edit.key_fact_ids.placeholder',
            })}
          />
        </Form.Item>
        <Form.Item name="description">
          <TextAreaField
            label={<FormattedMessage id="portfolio.edit.description" />}
            rows={4}
            onChange={updatePortfolioDescription}
            placeholder={formatMessage({
              id: 'portfolio.edit.description.placeholder',
            })}
          />
        </Form.Item>
      </Form>
    </div>
  );
}
