import { FormattedMessage } from 'react-intl';
import { Heading, Tag } from '@pledge-earth/product-language';

import { SustainableDevelopmentGoalsEnum } from '../../../../services/graphql/generated';

import './sdgs.scss';

interface Props {
  sdgs: SustainableDevelopmentGoalsEnum[];
}

export function mapSdgsToReadableSdgs(
  sdgs: SustainableDevelopmentGoalsEnum,
): string {
  switch (sdgs) {
    case SustainableDevelopmentGoalsEnum.Ace:
      return 'Goal 7 - Affordable and Clean Energy';
    case SustainableDevelopmentGoalsEnum.Ca:
      return 'Goal 13 - Climate Action';
    case SustainableDevelopmentGoalsEnum.Cws:
      return 'Goal 6 - Clean Water and Sanitation';
    case SustainableDevelopmentGoalsEnum.Dweg:
      return 'Goal 8 - Decent Work and Economic Growth';
    case SustainableDevelopmentGoalsEnum.Ge:
      return 'Goal 5 - Gender Equality';
    case SustainableDevelopmentGoalsEnum.Ghw:
      return 'Goal 3 - Good Health and Well Being';
    case SustainableDevelopmentGoalsEnum.Iii:
      return 'Goal 9 - Industry Innovation and Infrastructure';
    case SustainableDevelopmentGoalsEnum.Lbw:
      return 'Goal 14 - Life Below Water';
    case SustainableDevelopmentGoalsEnum.Ll:
      return 'Goal 15 - Life on Land';
    case SustainableDevelopmentGoalsEnum.Np:
      return 'Goal 1 - No Poverty';
    case SustainableDevelopmentGoalsEnum.Pg:
      return 'Goal 17 - Partnerships for the Goals';
    case SustainableDevelopmentGoalsEnum.Pjsi:
      return 'Goal 16 - Peace Justice and Strong Institutions';
    case SustainableDevelopmentGoalsEnum.Qe:
      return 'Goal 4 - Quality Education';
    case SustainableDevelopmentGoalsEnum.Rcp:
      return 'Goal 12 - Responsible Consumption and Production';
    case SustainableDevelopmentGoalsEnum.Ri:
      return 'Goal 10 - Reducing Inequality';
    case SustainableDevelopmentGoalsEnum.Scc:
      return 'Goal 11 - Sustainable Cities and Communities';
    case SustainableDevelopmentGoalsEnum.Zh:
      return 'Goal 2 - Zero Hunger';
    default:
      return sdgs;
  }
}

export function SdgsSection(props: Props): JSX.Element {
  return (
    <div className="SdgsSection">
      <Heading level="h5">
        <FormattedMessage id="project-details.sdgs.title" />
      </Heading>
      {props.sdgs?.map((sdgs) => (
        <Tag className="SdgsSection__tag" key={sdgs}>
          {mapSdgsToReadableSdgs(sdgs)}
        </Tag>
      ))}
    </div>
  );
}
