import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Divider } from '@pledge-earth/web-components';
import { Text, Heading, Strong } from '@pledge-earth/product-language';

import { formatPrice } from '../../utils/format';

import './PortfolioSummaryBreakdown.scss';

export function PortfolioSummaryBreakdown({
  currency,
  totalProjects,
  pricePerTonne,
  allocatedTonnes,
}: {
  currency: string;
  totalProjects: number;
  pricePerTonne: number;
  allocatedTonnes: number;
}) {
  return (
    <div className="PortfolioSummaryBreakDown">
      <div>
        <Text variant="subdued">
          <FormattedMessage id="portfolio.no-projects" />
        </Text>
        <Strong>{totalProjects || 0}</Strong>
      </div>
      <div>
        <Text variant="subdued">
          <FormattedMessage id="portfolio.allocated-offsets" />
        </Text>
        <Strong>
          <FormattedNumber maximumFractionDigits={3} value={allocatedTonnes} />
          {' /t'}
        </Strong>
      </div>
      <Divider />
      <div className="PortfolioSummaryBreakDown__total">
        <Heading level="h5">
          <FormattedMessage id="portfolio.price-per-tonne" />
        </Heading>
        <div>
          <Heading level="h3">
            <Text variant="subdued">{currency} </Text>
            <Text>{formatPrice(currency, pricePerTonne)}</Text>
          </Heading>
        </div>
      </div>
    </div>
  );
}
