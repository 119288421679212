import type { PropsWithChildren } from 'react';
import { Layout, Card } from '@pledge-earth/web-components';

import { Footer } from '../../components/Footer';

import './Auth.scss';

const { Content } = Layout;

export function AuthLayout({ children }: PropsWithChildren<unknown>) {
  return (
    <Layout className="Auth">
      <Content>
        <Card className="Auth__card">{children}</Card>
      </Content>
      <Footer />

      <div className="Auth__pattern Auth__pattern--left">
        <img
          alt=""
          src="/resources/images/patterns/pattern-grey-thin-large.png"
        />
      </div>

      <div className="Auth__pattern Auth__pattern--right">
        <img alt="" src="/resources/images/patterns/pattern-purple-tick.png" />
        <img
          alt=""
          src="/resources/images/patterns/pattern-purple-medium.png"
        />
        <img alt="" src="/resources/images/patterns/pattern-grey-thin.png" />
      </div>
    </Layout>
  );
}
