import { twMerge } from '@pledge-earth/product-language';

interface Props {
  color?: string;
  solid?: boolean;
  className?: string;
}

export function SideBarSVG({ color = '#6900D7', solid, className }: Props) {
  const fillColor = solid ? color : '#F4E3FF';

  return (
    <svg
      className={twMerge('SideBarSVG absolute', className)}
      width="2"
      height="40"
      viewBox="0 0 2 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0.999999C0 0.447714 0.447715 0 1 0C1.55228 0 2 0.447715 2 1V39C2 39.5523 1.55228 40 1 40C0.447715 40 0 39.5523 0 39V0.999999Z"
        fill={fillColor}
      />
      <mask
        id="mask0_595_3052"
        // eslint-disable-next-line
        // @ts-ignore
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="2"
        height="40"
      >
        <path
          d="M0.5 0.999999C0.5 0.723857 0.723857 0.5 1 0.5C1.27614 0.5 1.5 0.723858 1.5 1V39C1.5 39.2761 1.27614 39.5 1 39.5C0.723858 39.5 0.5 39.2761 0.5 39V0.999999Z"
          fill={color}
          stroke={color}
        />
      </mask>
      <g mask="url(#mask0_595_3052)">
        <path d="M-22.9303 64L24.9238 16" stroke={color} strokeWidth="1.5" />
        <path d="M-22.9303 59L24.9238 11" stroke={color} strokeWidth="1.5" />
        <path d="M-22.9303 54L24.9238 6" stroke={color} strokeWidth="1.5" />
        <path d="M-22.9303 24L24.9238 -24" stroke={color} strokeWidth="1.5" />
        <path d="M-22.9303 29L24.9238 -19" stroke={color} strokeWidth="1.5" />
        <path d="M-22.9303 34L24.9238 -14" stroke={color} strokeWidth="1.5" />
        <path d="M-22.9303 39L24.9238 -9" stroke={color} strokeWidth="1.5" />
        <path d="M-22.9303 44L24.9238 -4" stroke={color} strokeWidth="1.5" />
        <path d="M-22.9303 49L24.9238 1" stroke={color} strokeWidth="1.5" />
        <path d="M-22.9303 19L24.9238 -29" stroke={color} strokeWidth="1.5" />
      </g>
    </svg>
  );
}
