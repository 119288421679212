import type { ReactNode } from 'react';
import { toastQueue } from '@pledge-earth/product-language';

export function showSuccessToast({
  description,
  timeout = 2500,
}: {
  description: ReactNode;
  timeout?: number;
}) {
  return toastQueue.add(
    {
      description,
      variant: 'success',
    },
    {
      timeout,
    },
  );
}

export function showErrorToast({
  description,
  timeout = 2500,
}: {
  description: ReactNode;
  timeout?: number;
}) {
  return toastQueue.add(
    {
      description,
      variant: 'error',
    },
    {
      timeout,
    },
  );
}
